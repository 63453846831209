import React, { useState, useEffect } from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import { useFormik } from 'formik';

import {handleChange, handleBlur} from '../helpers/handleForm';

import validationSchema from '../validationSchema';
import { convertToCurrency } from '../helpers/stringConvert';

const MedicaoFrenteItem = ({ frenteMedicao, atualizarMedicoes, buscarMedicao }) => {
    
    const [ validationMessage, setValidationMessage ] = useState(validationSchema.frente_medicao);
    
    const formik = useFormik({
        initialValues : frenteMedicao,
        
        onSubmit : async (values, { resetForm }) => {
            await atualizarMedicoes(values, setValidationMessage);
            await buscarMedicao();
        }
    
    });

    useEffect( () => {
        formik.setValues(frenteMedicao);
    },[frenteMedicao])

    let readOnly = null;

    if(frenteMedicao['UnidadeMedida'] === 'GB')
        readOnly = true;
    else
        readOnly = false;
   
    return(
        <Segment color="green">
            <Header size="medium">
                Frente {frenteMedicao['NúmeroFrente']} &nbsp;|&nbsp; R$ {convertToCurrency(frenteMedicao['ValorFrente'], '.')}
                <Header.Subheader>{frenteMedicao['Frente']}</Header.Subheader>
            </Header>
            <Form widths="equal">
                <Form.Group>
                    <Form.Input name="Quantidade" value={convertToCurrency(formik.values['Quantidade'])} label={`Quantidade (${frenteMedicao['UnidadeMedida']})`} error={validationMessage.Quantidade} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} readOnly={readOnly} />
                    <Form.Input name="ValorP0" value={convertToCurrency(formik.values['ValorP0'])} label={`Valor (${frenteMedicao['PercFísico'] ? frenteMedicao['PercFísico'] : 0}%)`} error={validationMessage.ValorP0} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                </Form.Group>
            </Form>
        </Segment>
    )
}

export default MedicaoFrenteItem