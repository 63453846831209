import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { base, rao } from './styles/styles';

import { convertToCurrency } from '../helpers/stringConvert';

const tableColsWidth = {
    col1 : { width : '68px'},
    col2 : { width : '41px'},
}

const styles = StyleSheet.create({
    ...base,
    ...rao,
    ...tableColsWidth
});


export default ({cronograma, anos = []}) => {

    if(anos.length === 0){
        const anoAtual = new Date().getFullYear;
        anos.push(anoAtual);
    }
    
    const meses = {
        1 : 'Jan',
        2 : 'Fev',
        3 : 'Mar',
        4 : 'Abr',
        5 : 'Mai',
        6 : 'Jun',
        7 : 'Jul',
        8 : 'Ago',
        9 : 'Set',
        10 : 'Out',
        11 : 'Nov',
        12 : 'Dez',
    }
    
    const mesesHeader = [];

    mesesHeader.push(
        <View key="titmes" style={[styles.tableHeaderCell, styles.col1]}>
            <Text>MÊS</Text>
        </View>
    );
    
    Object.entries(meses).forEach( item => {
        mesesHeader.push( 
            <View key={item[0]} style={[styles.tableHeaderCell, styles.col2]}>
                <Text>{item[1]}</Text>
            </View>
        );
    });
    
    const cronogramasContent = anos.map( ano => {

        const medicoes = [];
        const previstoAcumulado = [];
        const realizadoAcumulado = [];

        medicoes.push(
            <View key="titmedicao" style={[styles.tableRowCellDestaque, styles.col1]}>
                <Text><strong>Nº da Medição</strong></Text>
            </View>
        );

        previstoAcumulado.push(
            <View key="titprevisto" style={[styles.tableRowCell, styles.col1]}>
                <Text>Previsto Físico Acumulado</Text>
            </View>
        );

        realizadoAcumulado.push(
            <View key="titrealizado" style={[styles.tableRowCell, styles.col1]}>
                <Text>Realizado Físico Acumulado</Text>
            </View>
        );

        for(let i = 1; i <= 12; i++){

            let mesAno = `${String(i).length === 1 ? "0"+String(i) : String(i)}/${ano}`
            let dadosMedicao = cronograma.find( item => item['MesAno'] === mesAno);

            if(dadosMedicao !== undefined){

                let numeroMedicao = dadosMedicao['NumeroMedição'];
                
                if(numeroMedicao === 0){
                    numeroMedicao = '';

                }else if(numeroMedicao % 1 !== 0){
                    numeroMedicao = 'SM';
                }

                
                medicoes.push(
                    <View key={i} style={[styles.tableRowCellDestaque, styles.col2]}>
                        <Text>{numeroMedicao}</Text>
                    </View>
                );
                
                
                previstoAcumulado.push(
                    <View key={i} style={[styles.tableRowCell, styles.col2]}>
                        <Text>{convertToCurrency(dadosMedicao['FísicoPrevisto'], '.', 0)}%</Text>
                    </View>
                );

                
                realizadoAcumulado.push(
                    <View key={i} style={[styles.tableRowCell, styles.col2]}>
                        <Text>{convertToCurrency(dadosMedicao['FísicoRealizado'], '.', 0)}%</Text>
                    </View>
                );

            }else{

                medicoes.push(
                    <View key={i} style={[styles.tableRowCell, styles.col2]}>
                        <Text></Text>
                    </View>
                );
                
                
                previstoAcumulado.push(
                    <View key={i} style={[styles.tableRowCell, styles.col2]}>
                        <Text></Text>
                    </View>
                );

                
                realizadoAcumulado.push(
                    <View key={i} style={[styles.tableRowCell, styles.col2]}>
                        <Text></Text>
                    </View>
                );

            }
        }

        return(
            <>
                <View key="anoRow" style={styles.tituloAno}>
                    <Text>{ano}</Text>
                </View>
                <View key="mesesRow" style={styles.tableRow}>                    
                    {mesesHeader}    
                </View>
                <View key="medicoesRow" style={styles.tableRow}>
                    {medicoes}    
                </View>
                <View key="previstoRow" style={styles.tableRow}>
                    {previstoAcumulado}    
                </View>
                <View key="realizadoRow" style={styles.tableRow}>
                    {realizadoAcumulado}    
                </View>
                
            </>
        );
    });


    return(
        <View>
            <View style={[styles.titulo3, { fontSize : '10px'}]}>
                <Text>CRONOGRAMA FÍSICO</Text>
            </View>
            {cronogramasContent}
        </View>
    );

}