import { useContext, useEffect } from 'react';
import { Context } from '../context/optionsContext';

const useOptions = ( list = [], conditionValues = {} ) => {
    
    const { state, setOptions } = useContext(Context);

    useEffect(() => {

        const update = async (list, conditionValue) => {
            await setOptions(list, conditionValue);
        }

        let conditionValue = null;

        list.forEach( (item) => {

            if(conditionValues[item])
                conditionValue = conditionValues[item];
            else
                conditionValue = null;
            update(item, conditionValue);

        });

    }, []);

    return state;
}

export default useOptions;

