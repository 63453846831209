import React, { useContext } from 'react';
import { Form, Button, Header, Divider } from 'semantic-ui-react';
import { useFormik } from 'formik';

import CustomDropdown from '../UI/CustomDropdown';

import { SITUACAO_CONTRATO } from '../../helpers/dropdownNames';

import useOptions from '../../hooks/useOptions';

const ContratosFilterForm = ({ listar, setPopupState }) => {
    
    const options = useOptions([SITUACAO_CONTRATO]);
    
    const formik = useFormik({
        initialValues : {
            'E_PPI|like' : '',
            'E_OB_EMPR|like' : '',
            E_OB_SIT : '',
            'OBJETO_OF|like' : '',
            'E_OB_NUMAS|like' : '',
            'E_OB_AS_inicial|maiorigual' : '',
            'E_OB_AS_final|menorigual' : '',
            'E_OB_TERM_inicial|maiorigual' : '',
            'E_OB_TERM_final|menorigual' : '',
            'TERMINO_AT_inicial|maiorigual' : '',
            'TERMINO_AT_final|menorigual' : ''
        },
        onSubmit : (values, { resetForm }) => {
            listar(values);
            setPopupState(false);
        }
    });

    const limparFiltro = () => {
        listar();
        setPopupState(false);
    }

    const cancelar = () => {
        setPopupState(false);
    }
    
    return (
            <Form widths='equal'>
                <Header>
                    <Header.Subheader>Preencha apenas os campos que deseja filtrar e clique em "Filtrar"</Header.Subheader>
                </Header>
                <Divider />
                <Form.Group>
                    <Form.Input
                        id="E_PPI|like"
                        content={formik.values['E_PPI|like']}
                        label="Código do Contrato"
                        onChange={formik.handleChange}
                    />
                    <Form.Input
                        id="E_OB_EMPR|like"
                        content={formik.values['E_OB_EMPR|like']}
                        label="Empreiteira"
                        onChange={formik.handleChange}
                    />
                    <CustomDropdown
                        initialOptions={options[SITUACAO_CONTRATO]}
                        name="E_OB_SIT"
                        label="Situação"
                        formik={formik}
                        search={true}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Input
                        name="OBJETO_OF|like"
                        label="Objeto"
                        content={formik.values['OBJETO_OF.like']}
                        onChange={formik.handleChange}
                    />
                    <Form.Input
                        name="E_OB_NUMAS|like"
                        label="A.S."
                        content={formik.values['E_OB_NUMAS']}
                        onChange={formik.handleChange}
                    />
                </Form.Group>

                <Header as="h5">Nº Data</Header>
                <Form.Group>
                    <Form.Field
                        name="E_OB_AS_inicial|maiorigual"
                        content={formik.values.E_OB_AS_inicial}
                        control="input"
                        type="date"
                        onChange={formik.handleChange}
                    />
                    <span style={{paddingTop : '7px'}}>Até</span>
                    <Form.Field
                        name="E_OB_AS_final|menorigual"
                        content={formik.values.E_OB_AS_final}
                        control="input"
                        type="date"
                        onChange={formik.handleChange}
                    />
                </Form.Group>
                
                <Header as="h5">Término</Header>
                <Form.Group>                    
                    <Form.Field
                        name="E_OB_TERM_inicial|maiorigual"
                        content={formik.values.E_OB_TERM_inicial}
                        control="input"
                        type="date"
                        onChange={formik.handleChange}
                    />
                    <span style={{paddingTop : '7px'}}>Até</span>
                    <Form.Field
                        name="E_OB_TERM_final|menorigual"
                        content={formik.values.E_OB_TERM_final}
                        control="input"
                        type="date"
                        onChange={formik.handleChange}
                    />
                </Form.Group>
                
                <Header as="h5">Previsto</Header>
                <Form.Group>                    
                    <Form.Field
                        name="TERMINO_AT_inicial|maiorigual"
                        content={formik.values.TERMINO_AT_inicial}
                        control="input"
                        type="date"
                        onChange={formik.handleChange}
                    />
                    <span style={{paddingTop : '7px'}}>Até</span>
                    <Form.Field
                        name="TERMINO_AT_final|menorigual"
                        content={formik.values.TERMINO_AT_final}
                        control="input"
                        type="date"
                        onChange={formik.handleChange}
                    />
                </Form.Group>
                
                <Button onClick={formik.handleSubmit} primary>Filtrar</Button>
                <Button onClick={limparFiltro} negative>Limpar Filtro</Button>
                <Button onClick={cancelar} cancelar>Cancelar</Button>
            </Form>
        
    );
}

export default ContratosFilterForm;