import React, { useContext } from 'react';
import { Segment, Header, Icon, List } from 'semantic-ui-react';

import { Context as authContext } from '../../context/authContext';

const ResumoUsuario = () => {

    const auth = useContext(authContext);
    
    return (
        <Segment textAlign="center">
            <Header icon>                
                <Icon name='user' size='large' />
                Usuário Teste
            </Header>
            {/*<List bulleted horizontal link>
                <List.Item as='a'>Editar</List.Item>
                <List.Item as='a' onClick={() => auth.logout()}>Sair</List.Item>
            </List>*/}
        </Segment>
    );
}

export default ResumoUsuario;