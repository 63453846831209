import React, { useContext, useEffect, useState } from 'react';
import { List, Form, Segment, Label, Button, Divider } from 'semantic-ui-react';
import { useFormik } from 'formik';

import validationSchema from '../validationSchema';

import CustomDropdown from './UI/CustomDropdown';
import IconButton from './UI/IconButton';

import { Context as responsaveisTecnicosContext } from '../context/responsaveisTecnicosContext';
import { Context as unidadeOrigemContext } from '../context/unidadeOrigemContext';

import {
    RESPONSAVEL_TITULO
} from '../helpers/dropdownNames';

import useOptions from '../hooks/useOptions';

const ResponsaveisTecnicos = ({ contratoId }) => {

    const { state, novo, buscarContrato, remover } = useContext(responsaveisTecnicosContext);
    const unidadeOrigem = useContext(unidadeOrigemContext);

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.ResponsavelTecnico);

    const options = useOptions([
        RESPONSAVEL_TITULO
    ]);

    const formik = useFormik({
        initialValues : {
            'ResponsávelTécnico' : '',
            Resp_Titulo : '',
            CREA : '',
        },

        onSubmit : (values, { resetForm }) => {
            values.UnidadeOrigemExportar = unidadeOrigem.state.UnidadeOrigemExportar;
            values.E_PPI = contratoId;
            novo(values, setValidationMessage);
            resetForm({});
        }

    });

    const handleBlur = (e) => {
        
    }

    const handleChange = (e, { name, value }) => {
        setValidationMessage({ ...validationMessage, [name] : null});
        formik.setFieldValue(name, value);
    }

    useEffect( () => {
        buscarContrato(contratoId);
    },[]);

    const responsaveisList = state
        .filter( item => item.E_PPI === contratoId)
        .map( (item) => {
            return(
                <List.Item key={item.E_PPI + item['ResponsávelTécnico']}>
                    <IconButton name="trash" onClick={() => remover(item.E_PPI, item['ResponsávelTécnico'])} />
                    <List.Content>
                        <List.Header>{`${item.Resp_Titulo} ${item['ResponsávelTécnico']}`}</List.Header>
                        <List.Description>{`CREA: ${item.CREA} | Unidade: ${item.UnidadeOrigemExportar}`}</List.Description>
                    </List.Content>
                </List.Item>
        );
    });

    return( 
    <Segment basic>

        <Label attached="top" size="big" color="teal">Responsáveis Técnicos</Label>
        <Divider basic></Divider>

        <List divided relaxed>
            {responsaveisList}
        </List>

        <Form widths="equal">
            <Form.Group>
                <CustomDropdown
                        initialOptions={options[RESPONSAVEL_TITULO]}
                        name="Resp_Titulo"
                        label="Título"
                        defaultValue=''
                        formik={formik}
                        error={validationMessage.Resp_Titulo}
                        onBlur={handleBlur}
                        onChange={handleChange}
                />
                <Form.Input
                    name="ResponsávelTécnico"
                    value={formik.values['ResponsávelTécnico']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nome"
                    error={validationMessage['ResponsávelTécnico']}
                />
                <Form.Input name="CREA" value={formik.values.CREA} onChange={handleChange} label="CREA" />
            </Form.Group>
            <Button onClick={formik.handleSubmit} primary>Salvar</Button>
        </Form>

    </Segment>
    );

}

export default ResponsaveisTecnicos;