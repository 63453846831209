import React, { useState } from 'react';
import {
    useTable,
    useFilters,
    useSortBy
  } from 'react-table';
import { Table } from 'semantic-ui-react';

// Create a default prop getter
const defaultPropGetter = () => ({})

const SemanticGrid = ({ 
    columns,
    data,
    quant = null,
    getCellProps = defaultPropGetter
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data });

    return(
        <div style={{ overflowY : 'auto'}}>
            <Table celled sortable selectable {...getTableProps()}>
                {headerGroups.map(headerGroup => (
                    <Table.Header {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                        //console.log(column);
                        return <Table.HeaderCell width={column.width} {...column.getHeaderProps()}>{column.render('Header')}</Table.HeaderCell>
                    })}
                    </Table.Header>
                ))}            
                <Table.Body {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    if(quant && index + 1 >= quant)
                        return null;
                    else{
                        prepareRow(row)
                        return (
                        <Table.Row onClick={()=>{}} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <Table.Cell width={cell.column.width} {...cell.getCellProps([getCellProps(cell)])}>{cell.render('Cell')}</Table.Cell>
                            })}
                        </Table.Row>
                        )
                    }
                })}
                </Table.Body>
            </Table>
        </div>
    );
}

export default SemanticGrid;