import { convertToCurrency, convertToMesAno } from '../helpers/stringConvert';

export const getFrentesMedicao = (NumeroMedicao, fechamento, contrato={}, frentes = []) => {

    const frentesFinal = [];
    
    frentes.forEach( item => {
        
        let medicao = item.medicoes.filter( itemMedicao => itemMedicao['NumeroMedição'] === Number(NumeroMedicao) );
        
        const frenteObj = {
            'E_PPI' : contrato['E_PPI'],
            'NumeroMedição' : NumeroMedicao,
            'NúmeroFrente' : item['NúmeroFrente'],
            'Frente' : item['Frente'],
            'UnidadeMedida' : item['UnidadeMedida'],
            'QuantFrente' : item['Quantidade'],
            'Peso': item['Peso'],
            'ValorFrente' : item['Valor'],
            'MesAno' : convertToMesAno(fechamento),
            'Classificação': contrato['Classificação'],
            'E_OB_VALOR': contrato['E_OB_VALOR'],
        }

        if(medicao.length > 0){
            frenteObj['Quantidade'] = medicao[0]['Quantidade'];
            frenteObj['PercFísico'] = convertToCurrency(medicao[0]['PercFísico']);
            frenteObj['ValorP0'] = convertToCurrency(medicao[0]['ValorP0']);
        }else{
            frenteObj['Quantidade'] = '0';
            frenteObj['PercFísico'] = '0.00';
            frenteObj['ValorP0'] = '0.00';

        }
        
        frentesFinal.push(frenteObj);

    });
    
    return frentesFinal;

}