import React from 'react';
import { Segment, Grid, Icon, Label, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import useOptions from '../hooks/useOptions';

import { SITUACAO_CONTRATO } from '../helpers/dropdownNames';
import getOptionLabel from '../helpers/getOptionLabel';
import utcToDate from '../helpers/utcToDate';


const ContratosItem = ({ dados }) => {
    
    const options = useOptions([SITUACAO_CONTRATO]);
    
    return (
        <Segment color='teal'>
            <Grid celled>
                <Grid.Row>
                    <Grid.Column width={3}><Header size='small'>{dados.E_PPI}</Header></Grid.Column>
                    <Grid.Column width={10}><Header size='small'>{dados.E_OB_EMPR}</Header></Grid.Column>
                    <Grid.Column width={3}><Header size='small'>{getOptionLabel(options, SITUACAO_CONTRATO, dados.E_OB_SIT)}</Header></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>{dados.OBJETO_OF}</Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={3}><Header size='tiny'>A.S.</Header>{dados.E_OB_NUMAS}</Grid.Column>
                    <Grid.Column width={3}><Header size='tiny'>Data Término</Header>{utcToDate(dados.E_OB_TERM,'br')}</Grid.Column>
                    <Grid.Column width={3}><Header size='tiny'>Nº Data</Header>{utcToDate(dados.E_OB_AS,'br')} </Grid.Column>
                    <Grid.Column width={3}><Header size='tiny'>Previsto</Header>{utcToDate(dados.TERMINO_AT,'br')} </Grid.Column>
                    <Grid.Column width={4}>
                        <div style={{display : 'flex', justifyContent : 'center', alignItems: 'center', margin:'0px 10px'}} >
                            <Icon name='trash alternate outline' size='big' />
                            <Link to={"/contrato/"+encodeURIComponent(dados.E_PPI)}>
                                <Icon name='edit outline' size='big' style={{marginLeft:5}} />
                            </Link>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default ContratosItem;