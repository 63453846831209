import React, { useContext, useEffect, useState } from 'react';
import { Header, Label, Segment, Tab, Loader, Grid, Container } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';

import ContratoDados from '../componentes/ContratoDados';
import ContratoMedicoes from '../componentes/ContratoMedicoes';
import ContratoFrentes from '../componentes/ContratoFrentes';
import ContratoCronograma from '../componentes/ContratoCronograma';
import ContratoCartas from '../componentes/ContratoCartas';

import { Context } from '../context/contratosContext';

import { utcToDate, convertToCurrency } from '../helpers/stringConvert';


const Contratos = () => {

    let { id } = useParams();
    id = decodeURIComponent(id);
    
    const { state, buscar, atualizar } = useContext(Context);

    const [ contrato, setContrato ] = useState({});

    const initialInfoBar = [
        {
            key : 'prazo_inicial',
            label : 'Prazo Inicial',
            value : '0'
        },
        {
            key : 'dias_aditados',
            label : 'Dias Aditados',
            value : '0'
        },
        {
            key : 'prazo_atual',
            label : 'Prazo Atual',
            value : '0'
        },
        {
            key : 'valor_original',
            label : 'Valor Original',
            value : 'R$ 0,00'
        },
        {
            key : 'total_aditado',
            label : 'Total Aditado',
            value : 'R$ 0,00'
        },
        {
            key : 'valor_atual',
            label : 'Valor Atual',
            value : 'R$ 0,00'
        }
    ];

    const [ infoBar, setInfoBar ] = useState(initialInfoBar);

    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        const update = async () => {
            await buscar(id);
            setLoading(false);
        }
        
        if(id)
            update();
        else
            setContrato(undefined);
        
    },[]);
    
    useEffect( () => {
            setContrato(state.find( (item) => item ? item.E_PPI === id : item) );
        

    },[state, id]);

    useEffect( () => {

        if(contrato){

            const infoBar = [
                {
                    key : 'prazo_inicial',
                    label : 'Prazo Original',
                    value : contrato['E_OB_PRAZO'] + ' dia(s)'
                },
                {
                    key : 'dias_aditados',
                    label : 'Prazo Atual',
                    value : contrato['PRAZO_AT']+ ' dia(s)'
                },
                {
                    key : 'valor_original',
                    label : 'Valor Original',
                    value : 'R$ ' + convertToCurrency(contrato['E_OB_VALOR'],'.')
                },
                {
                    key : 'total_aditado',
                    label : 'Valor Aditado',
                    value : 'R$ ' + convertToCurrency(contrato['totalValorAditamento'],'.')
                },
                {
                    key : 'prazo_atual',
                    label : 'Término Atual',
                    value : utcToDate(contrato['TERMINO_AT'],'br'),
                    bold : true
                },
                {
                    key : 'valor_atual',
                    label : 'Valor Atual',
                    value : 'R$ ' + convertToCurrency(contrato['VALOR_AT'],'.'),
                    bold : true
                }
            ];

            setInfoBar(infoBar);
        }
    }, [contrato]);

    const inforBarElements = infoBar.map( item => {
        return(
            <Grid.Column key={item.key} textAlign='center' style={{padding:0}}>
                <Container>
                    <Header as='h4' style={item.bold ? {fontSize : '18px', marginBottom : '0'} : {marginBottom : '0'}}>{item.label}</Header>
                    <Container>{item.bold ? <strong style={{fontSize : '16px'}}>{item.value}</strong> : item.value}</Container>
                </Container>
            </Grid.Column>
        )
    })

    
    if(loading){
        return (
            <div className='route-content'>
                <Loader active={true} content="Aguarde..." />
            </div>
        );

    }else if(contrato === undefined){
        return(
            <div className='route-content'>
                <Header textAlign="center" content="Contrato não encontrado" />
            </div>
        );

    }else{

        const panes = [
            {
                menuItem : 'Dados',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><ContratoDados dados={contrato} atualizar={atualizar} /></Tab.Pane>,
            },
            {
                menuItem : 'Cronograma',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><ContratoCronograma E_PPI={contrato.E_PPI} /></Tab.Pane>,
            },
            {
                menuItem : 'Medições',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><ContratoMedicoes E_PPI={contrato.E_PPI} /></Tab.Pane>,
            },
            {
                menuItem : 'Frentes',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><ContratoFrentes E_PPI={contrato.E_PPI} /></Tab.Pane>,
            },
            {
                menuItem : 'Comissão',
                render : () => <Tab.Pane>'COMISSÃO'</Tab.Pane>,
            },
            {
                menuItem : 'Cartas',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><ContratoCartas E_PPI={contrato.E_PPI} /></Tab.Pane>,
            }
        ];

        return (
            <div className='route-content'>
                <Segment basic style={{padding : 0, margin : '5px'}}>
                    <Header size='large' color='teal' floated="left" style={{marginTop : 7, marginBottom : 0}}>
                        Contrato {contrato.E_PPI}
                        {/*<Header.Subheader>{contrato.OBJETO_OF}</Header.Subheader>*/}
                    </Header>
                </Segment>
                <Segment basic style={{padding : 0, marginHorizontal : '5px'}}>
                    <Grid>
                        <Grid.Row columns={6}>
                            {inforBarElements}
                        </Grid.Row>
                    </Grid>
                </Segment>
                
                
                <Tab className='extend' panes={panes} />
            </div>
        );
    }
}

export default Contratos;