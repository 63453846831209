import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import validationSchema from '../validationSchema';
import { AccordionTitle } from 'semantic-ui-react';

const reducer = (state, action) => {

    let newState = [];

    switch(action.type){

        case 'listar':
            return action.payload;

        case 'buscar':            
            newState = updateState(state, action.payload);
            return newState;

        case 'buscarContrato': 
            return action.payload;

        case 'novo':
            return [...state, action.payload]

        case 'remover':
            return state.filter( item => !(item.E_PPI === action.payload.E_PPI && item['ResponsávelTécnico'] === action.payload['ResponsávelTécnico']));


        default:
            return state;

    }
}

const actions = {

    listar : dispatch => async (query = null) => {

        const resposta = await sisobrasServer.get('/responsaveis-tecnicos', {params : query});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'listar',
            payload : resposta.data.dados
        });
    },

    buscar : dispatch => async (id = null) => {

        if(!id){
            console.error("Erro ao buscar Responsável Técnico -> Id do Responsável Técnico não informado");
            return false;
        } 

        const resposta = await sisobrasServer.get('/responsaveis-tecnicos/'+encodeURIComponent(id));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    },

    buscarContrato : dispatch => async (id = null) => {

        if(!id){
            console.error("Erro ao buscar responsáveis técnicos do contrato -> Id do contrato não informado");
            return false;
        } 

        const resposta = await sisobrasServer.get('/responsaveis-tecnicos/contrato/'+encodeURIComponent(id));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscarContrato',
            payload : resposta.data.dados
        });
    },


    novo : dispatch => async ( dados = null, setValidationMessage = null) => {

        if(!dados){
            console.error("Erro ao cadastrar novo responsável Técnico -> Dados não enviados");
            return false;
        } 

        const resposta = await sisobrasServer.post('/responsaveis-tecnicos', dados);

        if(resposta.data.errosValidacao){

            const validationMessage = {};
            const errosValidacao = resposta.data.errosValidacao;

            Object.keys(validationSchema.ResponsavelTecnico).forEach( field => {
                validationMessage[field] = errosValidacao.reduce( (acc, curr) => field === curr.param ? curr.msg : acc, null);
            });
            
            setValidationMessage(validationMessage);

        }

        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'novo',
            payload : resposta.data.dados[0]
        });

    },

    remover : dispatch => async (E_PPI, ResponsavelTecnico) => {

        if(!E_PPI || !ResponsavelTecnico){
            console.error("Erro ao remover Responsável Técnico -> E_PPI ou nome não informado");
            return false;
        } 

        const data = { E_PPI, ResponsavelTecnico};

        const resposta = await sisobrasServer.delete('/responsaveis-tecnicos/', {data});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'remover',
            payload : resposta.data.dados
        });
    }

}

const defaultState = [];

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)