import React, {useState, useContext, useEffect} from 'react';
import { Form, Segment} from 'semantic-ui-react';
import { useFormik } from 'formik';

import { Context as contratosContext } from '../context/contratosContext';

import { convertToCurrency } from '../helpers/stringConvert';
import { calculaPesoFrente } from '../helpers/calculatedFields';

import validationSchema from '../validationSchema';

import {handleChange, handleBlur} from '../helpers/handleForm';

const FrenteDados = ({ dados, atualizar }) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.frente);
    const [ contrato, setContrato ] = useState({});

    const contratos = useContext(contratosContext);

    const E_PPI = dados.E_PPI;

    useEffect( () => {
        const buscarContrato = async () => {
            await contratos.buscar(E_PPI);
            setContrato(contratos.state.find( (item) => item.E_PPI === E_PPI));
        }
        buscarContrato();
    }, [E_PPI]);
            
    useEffect( () => {
            setContrato(contratos.state.find( (item) => item.E_PPI === E_PPI));
    }, [E_PPI, contratos]);
        

    const formik = useFormik({

        initialValues : dados,

        onSubmit : values => {
            values['E_PPI'] = dados['E_PPI'];
            values['NúmeroFrente'] = dados['NúmeroFrente'];
            atualizar(values, setValidationMessage);
        }

    });

    const calculaPeso = (e, {name,value}) => {
        formik.setFieldValue('Peso', String(calculaPesoFrente(value, contrato.E_OB_VALOR).toFixed(2)).replace(',','.'));
        formik.setFieldValue('PesoVerifica', String(calculaPesoFrente(value, contrato.E_OB_VALOR).toFixed(5)).replace(',','.'));
        handleChange(name,value,formik,validationMessage, setValidationMessage);
    }
    
    return(
        
        <Segment basic>
            <Form widths='equal'>
                <Form.TextArea name="Frente" value={formik.values['Frente']} label="Frente"  onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />                
                <Form.Group>
                    <Form.Input name="UnidadeMedida" value={formik.values['UnidadeMedida']} label="Unidade de Medida" error={validationMessage['UnidadeMedida']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                    <Form.Input name="Quantidade" value={formik.values['Quantidade']} label="Quantidade" error={validationMessage['Quantidade']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                </Form.Group>              
                <Form.Group>
                    <Form.Input name="Valor" value={convertToCurrency(formik.values['Valor'])} label="Valor" error={validationMessage['Valor']} onChange={calculaPeso} onBlur={(e) => handleBlur(formik)} />
                    <Form.Input name="Peso" value={convertToCurrency(formik.values['Peso'])} label="Peso" readOnly />
                    <Form.Input name="PesoVerifica" value={formik.values['PesoVerifica']} label="Peso S/ Arred." readOnly />
                </Form.Group>
                
            </Form>
        </Segment>
    );
}

export default FrenteDados;