import React, { useState, useContext } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import history from '../../history';

import validationSchema from '../../validationSchema';

import { utcToDate } from '../../helpers/stringConvert';

import CustomDropdown from '../UI/CustomDropdown';

import { Context as unidadeOrigemContext } from '../../context/unidadeOrigemContext';

import {
    TIPO_SOLICITACAO,
    SOLICITACAO_DEFINICAO,
    NUMERO_CI
} from '../../helpers/dropdownNames';
import useOptions from '../../hooks/useOptions';

const FormCartaSolicitacao = ({ onSave, onCancel, E_PPI, NumeroCartaContratada }) => {

    const unidadeOrigem = useContext(unidadeOrigemContext);

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.carta_solicitacao);

    const options = useOptions([
        TIPO_SOLICITACAO,
        SOLICITACAO_DEFINICAO,
        NUMERO_CI        
    ],
    {
        [NUMERO_CI] : {
            'E_PPI' : E_PPI,
            'NúmeroCartaContratada' : NumeroCartaContratada
        }
    });
    
    const formik = useFormik({
        initialValues : {
            'TipoSolicitação' : '',
            'Definição' : '',
            'NúmeroCI' : '',
            'Car_RecursoDeferido' : 0,
            'Cancelado' : 0,
            'Car_Recurso' : 0
            
        },
    
        onSubmit : (values, { resetForm }) => {
            values.UnidadeOrigemExportar = unidadeOrigem.state.UnidadeOrigemExportar;
            values.E_PPI = E_PPI;
            values['NúmeroCartaContratada'] = NumeroCartaContratada;
            onSave(values, setValidationMessage, onCancel);
        }
    
    });
    
    const handleChange = (e, { name, value }) => {
        setValidationMessage({ ...validationMessage, [name] : null});
        formik.setFieldValue(name, value);
    }

    return(
        <Segment basic>
            <Form widths="equal">
                <Form.Group>
                    <CustomDropdown
                            initialOptions={options[TIPO_SOLICITACAO]}
                            name="TipoSolicitação"
                            label="Tipo de Solicitação"
                            value={formik.values['TipoSolicitação']}
                            onChange={handleChange}
                            search={true}
                            error={validationMessage['TipoSolicitação']}
                    />
                    <CustomDropdown
                            initialOptions={options[SOLICITACAO_DEFINICAO]}
                            name="Definição"
                            label="Definição"
                            value={formik.values['Definição']}
                            onChange={handleChange}
                            search={true}
                            error={validationMessage['Definição']}
                    />
                    <CustomDropdown
                            initialOptions={options[NUMERO_CI]}
                            name="NúmeroCI"
                            label="CI"
                            value={formik.values['NúmeroCI']}
                            onChange={handleChange}
                            search={true}
                            error={validationMessage['NúmeroCI']}
                    />
                </Form.Group>
                <Button primary onClick={ formik.handleSubmit }>Salvar</Button>
                <Button secondary onClick={ onCancel }>Cancelar</Button>
            </Form>
        </Segment>
    );

}

export default FormCartaSolicitacao;