import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import deleteState from '../helpers/deleteState';
import validate from '../helpers/validate';

const defaultState = [];

const reducer = (state, action) => {

    let newState = [];

    switch(action.type){

        case 'listar':
            return action.payload;
        
        case 'listarPorContrato':
            newState = [ ...state ];
            action.payload.forEach( item => {
                console.log(newState);
                newState = updateState(newState, item, ['E_PPI','NumeroMedição']);
            });
            return newState;

        case 'buscar':            
            newState = updateState(state, action.payload);
            return newState;

        case 'atualizar':
            newState = updateState(state, action.payload);
            return newState;

        case 'reset':
            return defaultState;

        default:
            return state;

    }
}

const actions = {

    listar : dispatch => async (query = null) => {

        const resposta = await sisobrasServer.get('/medicoes', {params : query});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'listar',
            payload : resposta.data.dados
        });
    },

    
    listarPorContrato : dispatch => async (E_PPI, setLoading, query = null) => {

        const resposta = await sisobrasServer.get('/medicoes/' + encodeURIComponent(E_PPI), {params : query});
        
        if(!errorHandler(resposta.data)) return false;

        if(setLoading)
            setLoading(false);
        
        dispatch({
            type : 'listarPorContrato',
            payload : resposta.data.dados
        });
    },


    buscar : dispatch => async (E_PPI, NumeroMedicao) => {

        if(!E_PPI){
            console.error("Buscar medição - Código do contrato não informado");
            return false;
        } 
        if(!NumeroMedicao){
            console.error("Buscar medição - Número da medição não informado");
            return false;
        }

        const resposta = await sisobrasServer.get('/medicoes/' + encodeURIComponent(E_PPI) + '/' + NumeroMedicao);
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    },


    atualizar : dispatch => async (dados = null, setValidationMessage) => {

        if(!dados.E_PPI){
            console.error("Atualizar Medição - ID do contrato não informado");
            return false;
        } 
        if(!dados['NumeroMedição']){
            console.error("Atualizar Medição - Número da Medição não informado");
            return false;
        } 

        const resposta = await sisobrasServer.put(`/medicoes/${encodeURIComponent(dados.E_PPI)}/${dados['NumeroMedição']}`, dados);

        validate('medicao',resposta.data.errosValidacao,setValidationMessage);

        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizar',
            payload : dados
        });
    },

    reset : dispatch => async() => {

        dispatch({
            type : 'reset'
        })

    }

}

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)