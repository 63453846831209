import React, {useState} from 'react';
import { Form, Segment} from 'semantic-ui-react';
import { useFormik } from 'formik';

import { utcToDate } from '../helpers/stringConvert';

import CustomDropdown from '../componentes/UI/CustomDropdown';

import validationSchema from '../validationSchema';

import {handleChange, handleBlur,  handleSelectChange} from '../helpers/handleForm';

import{
    DESTINO_CARTA
} from '../helpers/dropdownNames';
import useOptions from '../hooks/useOptions';

const CartaDados = ({ dados, atualizar }) => {

    const options = useOptions([
        DESTINO_CARTA
    ]);

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.carta_atualizar);

    
    const formik = useFormik({

        initialValues : {
            'DataCarta' : dados['DataCarta'],
            'TipoSolicitação' : dados['TipoSolicitação'],
            'Informações' : dados['Informações'],
            'DataEncaminhamentoCarta' : dados['DataEncaminhamentoCarta'],
            'DestinoCarta' : dados['DestinoCarta'],
            'Justificativa' : dados['Justificativa']
        },

        onSubmit : values => {
            atualizar(values, setValidationMessage);
        }

    });
    
    return(
        
        <Segment basic>
            <Form widths='equal'>
                <Form.Group>
                    <Form.Input name="DataCarta" value={utcToDate(formik.values.DataCarta)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Data" type="date" error={validationMessage['DataCarta']} />
                    <Form.Input name="DataEncaminhamentoCarta" value={utcToDate(formik.values.DataEncaminhamentoCarta)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Data Encaminhamento" type="date" />
                    <CustomDropdown
                        initialOptions={options[DESTINO_CARTA]}
                        name="DestinoCarta"
                        label="Destino"
                        value={formik.values['DestinoCarta']}
                        onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                        search={true}
                        canAdd={true}
                    />
                </Form.Group>              
                <Form.TextArea name="Informações" value={formik.values['Informações']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Informações" />                
                <Form.TextArea name="Justificativa" value={formik.values['Justificativa']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Observações" />                
            </Form>
        </Segment>
    );
}

export default CartaDados;