import React, { useState } from 'react';
import { Label, Form, Segment,  Divider } from 'semantic-ui-react';
import { useFormik } from 'formik';

import ResponsaveisTecnicos from './ResponsaveisTecnicos';

import CustomDropdown from './UI/CustomDropdown';

import validationSchema from '../validationSchema';

import {handleChange, handleBlur, handleCheckedChange, handleSelectChange} from '../helpers/handleForm';
import { utcToDate } from '../helpers/stringConvert';
import {
    SITUACAO_CONTRATO,
    AGUA_ESGOTO,
    MUNICIPIOS,
    TIPO_CONTRATO,
    MAT_OBRAS_SERVICO,
    FUNC,
    FISCAL_ADM,
    DT_MEDICAO,
    TIPO_FAC,
    RESPONSAVEL_TITULO,
    RECURSO,
    TIPO_RESCISAO,
    TIPO_CLASSIFICACAO
} from '../helpers/dropdownNames';

import useOptions from '../hooks/useOptions';
let counter = 0;
const ContratoDados = ({ dados, atualizar }) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.contrato);

    const options = useOptions([
        SITUACAO_CONTRATO,
        AGUA_ESGOTO,
        MUNICIPIOS,
        TIPO_CONTRATO,
        MAT_OBRAS_SERVICO,
        FUNC,
        FISCAL_ADM,
        DT_MEDICAO,
        TIPO_FAC,
        RESPONSAVEL_TITULO,
        RECURSO,
        TIPO_RESCISAO,
        TIPO_CLASSIFICACAO
    ]);

    const formik = useFormik({

        initialValues : dados,

        onSubmit : values => {
            //console.log(dados.DataE_OB_SIT);
            atualizar(dados.E_PPI, values, setValidationMessage);
        }

    });    
    
    //console.log(formik.values);
    counter++;
    console.log(counter);
    //const [ loading, setLoading ] = useState(true);

    const formPrincipal = 
    <Form widths='equal' autoComplete="off">
    <Segment basic>
        <Label attached='top' size='big' color='teal'>Resumo da Obra</Label>
        <Divider basic></Divider>
        <Form.TextArea name="ResumoObra" label="Resumo da Obra" value={formik.values.ResumoObra}  onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
        <Form.Input name="UnidProjeto" label="Unidade Projeto" value={formik.values.UnidProjeto}  onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
        <Form.Group>
            <Form.TextArea name="Projetista" label="Projetista" value={formik.values.Projetista} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} width={8} />
            <Form.TextArea name="ResumoObraQt" label="Quantidades" value={formik.values.ResumoObraQt} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} width={8} />            
        </Form.Group>
    </Segment>

    <Segment basic>
        <Label attached='top' size="big" color="teal">Informações Gerais</Label>
        <Divider basic></Divider>
        <Form.Group>
            <CustomDropdown
                initialOptions={options[SITUACAO_CONTRATO]}
                name="E_OB_SIT"
                label="Situação"
                value={formik.values.E_OB_SIT}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                onBlur={(e) => handleBlur(formik)}
                search={true}
            />
            <Form.Input name="DataE_OB_SIT" value={utcToDate(formik.values.DataE_OB_SIT)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Data da Ocorrência" type="date" />
            <CustomDropdown
                initialOptions={options[TIPO_RESCISAO]}
                name="TipoRescisao"
                label="Tipo de Rescisão"
                value={formik.values.TipoRescisao}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                onBlur={(e) => handleBlur(formik)}
                search={true}
            />
        </Form.Group>
        <Form.TextArea name="OBJETO_OF" value={formik.values.OBJETO_OF} error={validationMessage['OBJETO_OF']} onChange={(e, {name,value}) => handleChange(name,value,formik, validationMessage, setValidationMessage)} onBlur={(e) => handleBlur(formik)} label="Objeto" />
        <Form.Group>
            <Form.TextArea name="ObjetoRAO" value={formik.values.ObjetoRAO} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Objeto RAO" width={8} />
            <Form.TextArea name="ObjetoL" value={formik.values.ObjetoL} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Objeto L" width={8} />
        </Form.Group>
        <Form.Group>
            <CustomDropdown
                initialOptions={options[AGUA_ESGOTO]}
                name="E_TIPOSIST"
                label="Água / Esgoto"
                value={formik.values.E_TIPOSIST}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
            />
            <CustomDropdown
                initialOptions={options[MUNICIPIOS]}
                name="E_MUNIC"
                label="Município"
                value={formik.values.E_MUNIC}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
                canAdd={true}
            />
            <CustomDropdown
                initialOptions={options[TIPO_CONTRATO]}
                name="TipoContrato"
                label="Tipo de Contrato"
                value={formik.values.TipoContrato}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
            />
        </Form.Group>
        <Form.Group>
            <Form.Input name="E_OB_L_DTE" value={utcToDate(formik.values.E_OB_L_DTE)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Data da Assinatura" type="date" />
            <Form.Input name="E_OBS_DOSS" value={formik.values.E_OBS_DOSS} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Dossiê" />
            <CustomDropdown
                initialOptions={options[TIPO_CLASSIFICACAO]}
                name="Classificação"
                label="Classificação"
                value={formik.values['Classificação']}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
                error={validationMessage['Classificação']}
            />
        </Form.Group>
        <Form.TextArea name="Obs" value={formik.values.Obs} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Observações" />
        <Form.Group>
            <CustomDropdown
                initialOptions={options[FUNC]}
                name="ADM"
                label="ADM"
                value={formik.values.ADM}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
                canAdd={true}
            />
            <CustomDropdown
                initialOptions={options[FISCAL_ADM]}
                name="FISCAL_ADM"
                label="Fiscal"
                value={formik.values.FISCAL_ADM}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
                canAdd={true}
            />
        </Form.Group>
        <Form.Group widths={"equal"}>
            <CustomDropdown
                initialOptions={options[DT_MEDICAO]}
                name="DT_MEDICAO"
                label="Dia de Medição"
                value={formik.values.DT_MEDICAO}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik, validationMessage, setValidationMessage)}
                error={validationMessage['DT_MEDICAO']}
                search={true}
                canAdd={true}
            />
            <Form.Input name="E_OB_PRAZO" value={formik.values.E_OB_PRAZO} error={validationMessage['E_OB_PRAZO']} onChange={(e, {name,value}) => handleChange(name,value,formik, validationMessage, setValidationMessage)} onBlur={(e) => handleBlur(formik)} label="Prazo" />
        </Form.Group>
        <Form.Group>
        <Form.Input name="E_OB_NUMAS" value={formik.values.E_OB_NUMAS} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Nº da AS"/>
            <Form.Input name="E_OB_AS" value={utcToDate(formik.values.E_OB_AS)} error={validationMessage['E_OB_AS']} onChange={(e, {name,value}) => handleChange(name,value,formik, validationMessage, setValidationMessage)} onBlur={(e) => handleBlur(formik)} label="Data da AS" type="date" />
            <CustomDropdown
                initialOptions={options[RECURSO]}
                name="Recurso"
                label="Recurso"
                value={formik.values.Recurso}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
                canAdd={true}
            />
        </Form.Group>
        <Form.Group widths={4}>
            <Form.Input name="E_OB_IO" value={formik.values.E_OB_IO} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="I0" />            
            <Form.Input name="E_OB_VALOR" value={formik.values.E_OB_VALOR} error={validationMessage['E_OB_VALOR']} onChange={(e, {name,value}) => handleChange(name,value,formik, validationMessage, setValidationMessage)} onBlur={(e) => handleBlur(formik)} label="Valor Inicial" />
            <Form.Input name="E_EXE_NDOC" value={formik.values.E_EXE_NDOC} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Nº do Projeto" />
        </Form.Group>
    </Segment>



    <Segment basic>
        <Label attached='top' size="big" color="teal">Empreeiteira</Label>
        <Divider basic></Divider>
            {/*<CustomDropdown
                initialOptions={options[RAZ]}
                name="E_OB_EMPR"
                label="Empreiteira"
                value={formik.values.E_OB_EMPR}
                onChange={(e, {name,value}) => handleChange(name,value,formik)}
                search={true}
                canAdd={true}
            />*/}
            <Form.Input
                name="E_OB_EMPR"
                label="Empreiteira"
                value={formik.values.E_OB_EMPR}
                onChange={(e, {name,value}) => handleChange(name,value,formik)}
                onBlur={(e) => handleBlur(formik)} 
                />
        <Form.Group>
            <Form.Input  name="END" value={formik.values.END} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Endereço" />
            <Form.Input name="MUN" value={formik.values.MUN} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Cidade" />
            <Form.Input name="EST" value={formik.values.EST} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Estado" width={3} />
        </Form.Group>
        <Form.Group>
            <Form.Input name="TELEFONE" value={formik.values.TELEFONE} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Telefone" />
            <CustomDropdown
                initialOptions={options[MAT_OBRAS_SERVICO]}
                name="TipoObrasMaterial"
                label="Material/Obras/Serviço"
                value={formik.values.TipoObrasMaterial}
                onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                search={true}
            />
        </Form.Group>
    </Segment>
    <Segment basic>
        <Label attached='top' size="big" color="teal">Cronograma</Label>
        <Divider basic></Divider>
        <Form.Group>
            <Form.Input name="DT_CRONOG" value={utcToDate(formik.values.DT_CRONOG)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Entrega" type="date" />
            <Form.Input name="DT_CRO_RET" value={utcToDate(formik.values.DT_CRO_RET)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Retorno" type="date" />
        </Form.Group>
    </Segment>
    <Segment basic>
        <Label attached='top' size="big" color="teal">ART</Label>
        <Divider basic></Divider>
        <Form.Group>
            <Form.Input name="DT_ATR" value={utcToDate(formik.values.DT_ATR)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Entrega" type="date" />
            <Form.Input name="DT_ATR_RET" value={utcToDate(formik.values.DT_ATR_RET)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Retorno" type="date" />
        </Form.Group>
    </Segment>
    <Segment basic>
        <Label attached='top' size="big" color="teal">CEI</Label>
        <Divider basic></Divider>
        <Form.Group>
            <Form.Input name="N_CEI" value={formik.values.N_CEI} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Nº da CEI" />
            <Form.Input name="DT_CEI" value={utcToDate(formik.values.DT_CEI)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Entrega" type="date" />
            <Form.Input name="DT_CEI_RET" value={utcToDate(formik.values.DT_CEI_RET)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Retorno" type="date" />
        </Form.Group>

    </Segment>
    
    <Segment basic>
        <Label attached="top" size="big" color="teal">Cláusula Contratual FAC</Label>
        <Divider basic></Divider>
        <Form.Group>
            <Form.Checkbox name="ClausulaContratualFac" checked={formik.values.ClausulaContratualFac} onChange={(e, {name,checked}) => handleCheckedChange(name,checked,formik)} label="Cláusula Contratual FAC" />
            <CustomDropdown
                    initialOptions={options[TIPO_FAC]}
                    name="E_OB_tipFac"
                    label="Tipo FAC"
                    value={formik.values.E_OB_tipFac}
                    onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                    search={true}
            />
        </Form.Group>
        <Form.Group>
            <Form.TextArea name="Benefícios" value={formik.values['Benefícios']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Benefícios" />
            <Form.TextArea name="ObsRao" value={formik.values.ObsRao} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Obs. RAO" />
        </Form.Group>
    </Segment>
    
</Form>;

/*
//GERENCIADORAS------------------------------

const formikGerenciadoras = useFormik({
    initialValues : dados,
    onSubmit : values => {
        alert(JSON.stringify(values, null, 2));
    }
});

const gerenciadoras = useContext(gerenciadorasContext);

useEffect( () => {
    gerenciadoras.buscarContrato(dados.E_PPI);
    console.log(gerenciadoras.state);
},[dados.E_PPI, gerenciadoras]);

const gerenciadorasList = gerenciadoras.state.map( (item) => {
    return(
        <List.Item key={item.Ger_E_PPI}>
            <List.Icon name="trash" size="large" verticalAlign="middle" />
            <List.Content>
                <List.Header>{item.Ger_NomeGerenciadora}</List.Header>
            </List.Content>
        </List.Item>
    );
});

const gerenciadorasArea = 
<Segment basic>
    <Label attached="top" size="big" color="teal">Gerenciadoras</Label>
    <Divider basic></Divider>
    <List divided relaxed>
        {gerenciadorasList}
    </List>
    <Form>
        <Form.Group widths="equal">
            <Form.Input name="ObjetoRAO" value={formik.values.ObjetoRAO} onChange={(e, {name,value}) => handleChange(name,value,formik)} label="Nome" />
            <Form.Input name="ObjetoRAO" value={formik.values.ObjetoRAO} onChange={(e, {name,value}) => handleChange(name,value,formik)} label="Contrato" />
            <Form.Input name="ObjetoRAO" value={formik.values.ObjetoRAO} onChange={(e, {name,value}) => handleChange(name,value,formik)} label="Término Atualizado" type="date"/>
        </Form.Group>
        <Button primary>Salvar</Button>                    
    </Form>
</Segment>;
*/

    return(
        <div className='scroll-list' style={{top : 0}}>
            {formPrincipal}
            {/*gerenciadorasArea*/}
            <ResponsaveisTecnicos contratoId={dados.E_PPI} />
        </div>
    );
}

export default ContratoDados;