import React, { useState, useContext, useEffect } from 'react';
import { Header, Tab, Loader, Statistic, Segment, Menu, Dropdown, Label } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';

import MedicaoDados from '../componentes/MedicaoDados';
import MedicaoFrentes from '../componentes/MedicaoFrentes';

import { Context } from '../context/medicoesContext';
import { convertToCurrency, utcToDate } from '../helpers/stringConvert';

const MedicaoDetalhes = () => {

    let { E_PPI, NumeroMedicao } = useParams();
    E_PPI = decodeURIComponent(E_PPI);

    const { state, buscar, atualizar } = useContext(Context);

    const [ medicao, setMedicao ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        const update = async () => {
            await buscar(E_PPI, NumeroMedicao);
            setLoading(false);
        }
        update();
    },[]);
    
    useEffect( () => {
        setMedicao(state.find( (item) => item.E_PPI === E_PPI && item['NumeroMedição'] === Number(NumeroMedicao)));

    },[state, E_PPI, NumeroMedicao]);

    const infoBar = [
        {
            key : 'fechamento',
            label : 'Fechameno',
            value : medicao ? utcToDate(medicao['Fechamento'],'br') : '-'
        },
        {
            key : 'valorp0',
            label : 'Valor P0',
            value : medicao ? `R$ ${convertToCurrency(medicao['ValorP0'], true)}` : '-'
        }
    ];

    if(loading){
        return (
            <div className='route-content'>
                <Loader active={true} content="Aguarde..." />
            </div>
        );

    }else if(medicao === undefined){
        return(
            <div className='route-content'>
                <Header textAlign="center" content="Medição não encontrada" />
            </div>
        );

    }else{

        const panes = [
            {
                menuItem : 'Dados',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><MedicaoDados dados={medicao} atualizar={atualizar} /></Tab.Pane>,
            },
            {
                menuItem : 'Frentes',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><MedicaoFrentes E_PPI={E_PPI} NumeroMedicao={NumeroMedicao} Fechamento={medicao['Fechamento']} buscarMedicao={async () => await buscar(E_PPI, NumeroMedicao)} /></Tab.Pane>,
            }
        ];

        const handleClick = (url) => {
            window.open(url,'blank');
        }

        return (
            <div className='route-content'>
                <div style={{display:'flex', flexDirection : 'row', justifyContent : 'space-between'}}>
                <Header size='large' color='green'> 
                    Medição {medicao['NumeroMedição']}
                    {/*<Label size="large">Fechamento: {utcToDate(medicao['Fechamento'],'br')}</Label>
                    <Label size="large"> Valor P0: R$ {convertToCurrency(medicao['ValorP0'], true)}</Label>*/}
                    <Header.Subheader><Link to={`/contrato/${encodeURIComponent(E_PPI)}`}> Contrato {E_PPI}</Link></Header.Subheader>    
                </Header>
                
                <Statistic size='mini' label='Fechamento' value={utcToDate(medicao['Fechamento'],'br')} style={{margin : 0}} />
                <Statistic size='mini' label='ValorP0' value={`R$ ${convertToCurrency(medicao['ValorP0'], true)}`} style={{margin : 0}}  />
                
                <Menu>
                    <Dropdown item text='Relatórios'>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleClick(`/relatorio/rao?E_PPI=${encodeURIComponent(E_PPI)}&NumeroMedicao=${encodeURIComponent(NumeroMedicao)}`)}>RAO Individual</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
                </div>
                
                {/*<Segment>
                    <Statistic.Group className='info-bar' size='mini' items={infoBar} />                    
                </Segment>*/}
                <Tab className='extend' panes={panes} />
            </div>
        );
    }
}

export default MedicaoDetalhes;