import React from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';

import CustomDropdown from '../UI/CustomDropdown';

const FormMaterialNovo = ({ onSave, onCancel }) => {

    const formik = useFormik({
        initialValues : {
            material : {},
            diametro : '',
            unidade : '',
            quantPrevista : '',
            quantExecutada : ''
        },
        onSubmit : values => {
            alert(JSON.stringify(values, null, 2));
        }
    })
    
    const materialOptions = {
        opcao1 : '1 Opção',
        opcao2 : '2 Opção',
        opcao3 : '3 Opção'
    };

    return(
        <Segment basic>
            <Form widths='equal'>
                <CustomDropdown
                    initialOptions={materialOptions}
                    name="material"
                    label="Material"
                    formik={formik}
                    convert={false}
                />
                <Form.Group>
                    <Form.Input id="diametro" label="Diâmetro" onChange={formik.handleChange} content={formik.values.diametro} />
                    <Form.Input id="unidade" label="Unidade" onChange={formik.handleChange} content={formik.values.unidade} />
                    <Form.Input id="quantPrevista" label="Quant. Prevista" onChange={formik.handleChange} content={formik.values.quantPrevista} />
                    <Form.Input id="quantExecutada" label="Quant.Executada" onChange={formik.handleChange} content={formik.values.quantExecutada} />
                </Form.Group>
                <Button primary onClick={ formik.handleSubmit }>Salvar</Button>
                <Button secondary onClick={ onCancel }>Cancelar</Button>
            </Form>
        </Segment>
    )
}

export default FormMaterialNovo;