import React, { useState } from 'react';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';

const SearchBar = () => {

    const source = _.times(5, () => ({
        codigo : 1,
        descricao : 'testando'
    }));

    const [ results, setResults ] = useState([]);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    const handleSearchChange = (e, { value }) => {

        setIsLoading(true);
        setSearchTerm(value);

        setTimeout(() => {

            setIsLoading(false);

            if(value.length < 1){
                setResults([]);
                setSearchTerm('');
                return;
            }

            const regex = new RegExp(_.escapeRegExp(value,'i'));
            const isMatch = (result) => regex.test(result.descricao);

            setResults(_.filter(source, isMatch));
        },300);
    }

    return(
        <Search style={{float : 'right'}}
            loading={isLoading}
            noResultsMessage='Nenhum Contrato Encontrado'
            onResultSelect={(e, { result }) => setSearchTerm(result.descricao)}
            onSearchChange={_.debounce(handleSearchChange,500,{
                leading : true
            })}
            results={results}
            value={searchTerm}
        />
    );
}

export default SearchBar;