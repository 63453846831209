import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { base, rao } from './styles/styles';

import { convertToCurrency } from '../helpers/stringConvert';

const tableColsWidth = {
    col1 : { width : '64px'},
    col2 : { width : '41px'},
}

const styles = StyleSheet.create({
    ...base,
    ...rao,
    ...tableColsWidth
});


export default ({cronograma, medicoes, anos = []}) => {

    if(anos.length === 0){
        const anoAtual = new Date().getFullYear;
        anos.push(anoAtual);
    }

    anos.unshift(Number(anos[0]) - 1);
    
    const meses = {
        1 : 'Jan',
        2 : 'Fev',
        3 : 'Mar',
        4 : 'Abr',
        5 : 'Mai',
        6 : 'Jun',
        7 : 'Jul',
        8 : 'Ago',
        9 : 'Set',
        10 : 'Out',
        11 : 'Nov',
        12 : 'Dez',
    }

    let acumMedidoValor = 0;
    let acumMedidoRValor = 0;
    
    const cronogramasContent = anos.map( ano => {

        const mesesHeader = [];
        const medidoP0 = [];
        const medidoP0R = [];
        const acumMedido = [];
        const acumMedidoR = [];        
        let medidoP0Valor = null;
        let medidoP0RValor = null;

        mesesHeader.push(
            <View style={[styles.tableHeaderCell, styles.col1]}>
                <Text><strong>{ano}</strong></Text>
            </View>
        );
        
        Object.entries(meses).forEach( item => {
            mesesHeader.push( 
                <View key={item[0]} style={[styles.tableHeaderCell, styles.col2]}>
                    <Text>{item[1]}</Text>
                </View>
            );
        });

        medidoP0.push(
            <View style={[styles.tableRowCell, styles.col1]}>
                <Text><strong>Medido P0</strong></Text>
            </View>
        );

        medidoP0R.push(
            <View style={[styles.tableRowCell, styles.col1]}>
                <Text><strong>Medido P0+R</strong></Text>
            </View>
        );

        acumMedido.push(
            <View style={[styles.tableRowCell, styles.col1]}>
                <Text><strong>Acum. Medido</strong></Text>
            </View>
        );

        acumMedidoR.push(
            <View style={[styles.tableRowCell, styles.col1]}>
                <Text><strong>Acum. P0+R</strong></Text>
            </View>
        );

        for(let i = 1; i <= 12; i++){

            let medicao = null;
            let numeroMedicao = null;
            let mesAno = `${String(i).length === 1 ? "0"+String(i) : String(i)}/${ano}`;
            const cronogramaAtual = cronograma.find( item => item['MesAno'] === mesAno);
            
            if(cronogramaAtual !== undefined)
                medicao = medicoes.find( item => item['NumeroMedição'] === cronogramaAtual['NumeroMedição']);
            else
                medicao = undefined;

            if(medicao !== undefined){
                
                if(numeroMedicao === 0 || numeroMedicao % 1 !== 0){
                    medidoP0Valor = 0;
                    medidoP0RValor = 0;
                }else{
                    medidoP0Valor = medicao['ValorP0'];
                    medidoP0RValor = medicao['ValorP0'] + medicao['Reajuste'];
                }

            }else{
                medidoP0Valor = 0;
                medidoP0RValor = 0;
            }

            acumMedidoValor += medidoP0Valor;
            acumMedidoRValor += medidoP0RValor;

                
            medidoP0.push(
                <View key={i} style={[styles.tableRowCell, styles.col2, styles.cronEconomicoCell]}>
                    <Text>{convertToCurrency(medidoP0Valor, '.')}</Text>
                </View>
            );
            
            
            medidoP0R.push(
                <View key={i} style={[styles.tableRowCell, styles.col2, styles.cronEconomicoCell]}>
                    <Text>{convertToCurrency(medidoP0RValor, '.')}</Text>
                </View>
            );

            
            acumMedido.push(
                <View key={i} style={[styles.tableRowCell, styles.col2, styles.cronEconomicoCell]}>
                    <Text>{convertToCurrency(acumMedidoValor, '.')}</Text>
                </View>
            );

            acumMedidoR.push(
                <View key={i} style={[styles.tableRowCell, styles.col2, styles.cronEconomicoCell]}>
                    <Text>{convertToCurrency(acumMedidoRValor, '.')}</Text>
                </View>
            );

        }

        return(
            <>
                <View style={styles.tableHeader}>                    
                    {mesesHeader}    
                </View>
                <View style={styles.tableRow}>
                    {medidoP0}    
                </View>
                <View style={styles.tableRow}>
                    {medidoP0R}    
                </View>
                <View style={styles.tableRow}>
                    {acumMedido}    
                </View>
                <View style={styles.tableRow}>
                    {acumMedidoR}    
                </View>
                
            </>
        );
    });


    return(
        <View>
            <View style={[styles.titulo3, { fontSize : '10px'}]}>
                <Text>CRONOGRAMA ECONÔMICO</Text>
            </View>
            {cronogramasContent}
        </View>
    );

}