import { useContext, useEffect } from 'react';
import { Context } from '../context/contratosContext';

const useObras = (setLoading) => {
    
    const { state, listar } = useContext(Context);

    useEffect(() => {
        const update = async () => {
            setLoading(true);
            await listar();
            setLoading(false);
        }
        update();
        
    },[]);
    
    return { state, listar };
}

export default useObras;