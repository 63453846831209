import React, { useContext, useEffect } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';

import CartaSolicitacoesItem from './CartaSolicitacaoItem';
import FormCartaSolicitacao from './ModalForms/FormCartaSolicitacao';

import { Context as cartasSolicitacoesContext } from '../context/cartasSolicitacoesContext';
import { Context as contratosContext } from '../context/contratosContext';
import { Context as modalFormContext } from '../context/modalFormContext';
import { Context as confirmContext } from '../context/confirmContext';

const CartaSolicitacoes = ({ E_PPI, NumeroCartaContratada }) => {

    const { state, novo, atualizar, listarPorCarta, remover } = useContext(cartasSolicitacoesContext);

    const modalForm = useContext(modalFormContext);
    const modalConfirm = useContext(confirmContext);

    let soliciacoesList = null;
    
    useEffect( () => {
        listarPorCarta(E_PPI, NumeroCartaContratada);
    },[]);

    // PEDE CONFIRMAÇÃO ANTES DE REMOVER
    const confirmDelete = (E_PPI, NumeroCartaContratada, TipoSolicitacao) => {
        
        modalConfirm.setProp({
            onConfirm : () => remover(E_PPI, NumeroCartaContratada, TipoSolicitacao),
            content : `Tem certeza que deseja remover a solicitação?`
        });
        modalConfirm.open();

    }

    // MODAL COM FORMUÁRIO DE NOVO REGISTRO
    const modalAdicionar = () => {
        modalForm.open();
        modalForm.setHeader('Adicionar Solicitação');
        modalForm.setForm(<FormCartaSolicitacao onCancel={modalForm.close} onSave={novo} E_PPI={E_PPI} NumeroCartaContratada={NumeroCartaContratada} />);
    }

    if(state.length > 0){
        soliciacoesList = state
        .filter( item => item.E_PPI === E_PPI && item['NúmeroCartaContratada'] === NumeroCartaContratada)
        .map( (item) => {            
            return(
                <CartaSolicitacoesItem
                    key={item['TipoSolicitação']}
                    item={{...item}}
                    remover={confirmDelete}
                    atualizar={atualizar}
                />
            );
        });
        soliciacoesList = <Segment.Group>{soliciacoesList}</Segment.Group>;
    }else{
        soliciacoesList = <Header>Nenhuma Solicitação Cadastrada</Header>
    }


    return( 
    <Segment basic>
        <Segment padded basic>
            <Header floated="left" size="medium" color="green">
                Solicitações
            </Header>
            <Button floated="right" onClick={modalAdicionar} color="green" size="small">Adicionar Solicitação</Button>
        </Segment>
        {soliciacoesList}  

    </Segment>
    );

}

export default CartaSolicitacoes;