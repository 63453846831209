import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import deleteState from '../helpers/deleteState';
import validate from '../helpers/validate';
 
const defaultState = [];

const reducer = (state, action) => {

    switch(action.type){
        
        case 'listarPorContrato':
            console.log(action.payload);
            return action.payload;

        case 'buscar':
            return updateState(state, action.payload, ['E_PPI', 'NúmeroCartaContratada']);

        case 'atualizar':
            return updateState(state, action.payload, ['E_PPI', 'NúmeroCartaContratada']);
                    
        case 'novo':
            return [action.payload, ...state];

        case 'remover':            
            return deleteState(state, action.payload, ['E_PPI', 'NúmeroCartaContratada']);
            
        case 'reset':
            return defaultState;

        default:
            return state;

    }
}

const actions = {
    
    listarPorContrato : dispatch => async (E_PPI, setLoading, query = null) => {

        const resposta = await sisobrasServer.get('/cartas/contrato/' + encodeURIComponent(E_PPI), {params : query});
        
        if(!errorHandler(resposta.data)) return false;

        setLoading(false);
        
        dispatch({
            type : 'listarPorContrato',
            payload : resposta.data.dados
        });
    },

    buscar : dispatch => async (E_PPI, NumeroCartaContratada) => {

        if(!E_PPI){
            console.error("Buscar Carta - Código do contrato não informado");
            return false;
        } 
        if(!NumeroCartaContratada){
            console.error("Buscar Carta - Número da carta não informado");
            return false;
        }

        const resposta = await sisobrasServer.get('/cartas/' + encodeURIComponent(E_PPI) + '/' + encodeURIComponent(NumeroCartaContratada));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    },

    atualizar : dispatch => async (E_PPI, NumeroCartaContratada, dados = null, setValidationMessage) => {
        
        if(!E_PPI){
            console.error("Atualizar Carta - Contrato da carta não informado");
            return false;
        } 

        if(!NumeroCartaContratada){
            console.error("Atualizar Carta - Código da carta não informado");
            return false;
        } 

        const resposta = await sisobrasServer.put(`/cartas/${E_PPI}/${NumeroCartaContratada}`, dados);

        validate('carta_atualizar',resposta.data.errosValidacao,setValidationMessage);
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizar',
            payload : resposta.data.dados[0]
        });
    },


    novo : dispatch => async ( dados = null, setValidationMessage = null, afterUpdate = null) => {

        if(!dados){
            console.error("Erro ao cadastrar carta -> Dados incompletos");
            return false;
        } 

        const resposta = await sisobrasServer.post('/cartas', dados);
        
        validate('carta_novo',resposta.data.errosValidacao,setValidationMessage);

        if(!errorHandler(resposta.data)) return false;

        // FECHA A MODAL DO FORMULÁRIO CASO EXISTA E NÃO TENHA OCORRIDO ERROS DE VALIDAÇÃO
        if(afterUpdate && resposta.data.errosValidacao === undefined)
            afterUpdate();
        
        dispatch({
            type : 'novo',
            payload : resposta.data.dados[0]
        });

    },


    remover : dispatch => async (E_PPI, NumeroCartaContratada) => {
         
        if(!E_PPI){
            console.error("Erro ao remover carta - Contrato da carta não informado");
            return false;
        } 

        if(!NumeroCartaContratada){
            console.error("Erro ao remover carta - Número da carta não informado");
            return false;
        } 

        const data = {
            E_PPI,
            NumeroCartaContratada
        };

        const resposta = await sisobrasServer.delete('/cartas/', { data });
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'remover',
            payload : { E_PPI, 'NúmeroCartaContratada' : NumeroCartaContratada}
        });
    },

    reset : dispatch => async() => {

        dispatch({
            type : 'reset'
        })

    }

}

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)