import React from 'react';
import { Header } from 'semantic-ui-react';

import RelatorioRao from '../componentes/RelatoriosRao';

const Contratos = () => {

    return (
        <div className='contratos'>
            <div style={{width:'100%'}}>
                <Header size='large' color='blue' floated='left'> Relatórios </Header>
            </div>
            <div className='scroll-list' style={{top : 50}}>
                <RelatorioRao />
            </div>
        </div>
    );
}

export default Contratos;