import React from 'react';
import { Icon } from 'semantic-ui-react';

const IconButton = ({ name = 'question', size = 'large', onClick = null, verticalAlign = 'middle' }) => {
    
    return(
        <Icon
            name={name}
            size={size}
            onClick={onClick}
            verticalAlign={verticalAlign}
            style={{ cursor : 'pointer'}}
        />
    );
}

export default IconButton;