import React, { useState, useEffect } from 'react';
import { Form, Header, Segment, FormGroup } from 'semantic-ui-react';
import { useFormik } from 'formik';

import {handleChange, handleBlur, handleSelectChange} from '../helpers/handleForm';
import { utcToDate } from '../helpers/stringConvert';
import { addDays } from '../helpers/dateFunctions';

import validationSchema from '../validationSchema';

import IconButton from './UI/IconButton';
import CustomDropdown from './UI/CustomDropdown';

import {
    TIPO_SOLICITACAO,
    SOLICITACAO_DEFINICAO,
    NUMERO_CI
} from '../helpers/dropdownNames';

import useOptions from '../hooks/useOptions';


const CartaSolicitacaoItem = ({ item, remover, atualizar }) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.carta_solicitacao);

    let camposExtras = null;

    const options = useOptions([
        TIPO_SOLICITACAO,
        SOLICITACAO_DEFINICAO,
        NUMERO_CI        
    ],
    {
        [NUMERO_CI] : {
            'E_PPI' : item.E_PPI,
            'NúmeroCartaContratada' : item['NúmeroCartaContratada']
        }
    });
    
    const formik = useFormik({
        initialValues : item,
    
        onSubmit : (values, { resetForm }) => {
            atualizar(values, setValidationMessage);
        }
    
    });

    const DIAS_ADIT = formik.values['DIAS_ADIT'];
    const INI_ADITA = formik.values['INI_ADITA'];
    
    useEffect( () => {
        let dataFinal = null;
        dataFinal = addDays(INI_ADITA, DIAS_ADIT); // CALCULA A DATA FINAL E ATUALIZA O STATE (FORMIK)
        formik.setFieldValue('FIN_ADITA', dataFinal);

    },[DIAS_ADIT, INI_ADITA]);
   

    // EXIBE CAMPOS PARA ADIATAMENTO DE PRAZO OU ADITAMENTO DE VALOR
    switch(item['TipoSolicitação']){

        case 'Aditamento de Prazo':
        case 'Suspensão':
            camposExtras = <Form.Group>
                            <Form.Input name="DIAS_ADIT" value={formik.values['DIAS_ADIT']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Dias Aditados" />
                            <Form.Input name="INI_ADITA" value={utcToDate(formik.values['INI_ADITA'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Início" type="date"/>
                            <Form.Input name="FIN_ADITA" value={utcToDate(formik.values['FIN_ADITA'])} label="Fim" type="date" readOnly/>
                        </Form.Group>
        break;

        case 'Valor':
            camposExtras = <Form.Group>
                            <Form.Input name="ValorAditamento" value={formik.values['ValorAditamento']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Valor" />
                        </Form.Group>
        break;

        default:
            camposExtras = null;
        break;
    }

    return(
        <Segment color="green">
            <Header size="medium">
                <IconButton size="tiny" name="trash" onClick={() => remover(item['E_PPI'], item['NúmeroCartaContratada'], item['TipoSolicitação'])} />
                {item['TipoSolicitação']}
            </Header>
            <Form widths="equal">
                <Form.Group>
                    <CustomDropdown
                            initialOptions={options[SOLICITACAO_DEFINICAO]}
                            name="Definição"
                            label="Definição"
                            value={formik.values['Definição']}
                            onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                            search={true}
                            error={validationMessage['Definição']}
                    />
                    <CustomDropdown
                            initialOptions={options[NUMERO_CI]}
                            name="NúmeroCI"
                            label="CI"
                            value={formik.values['NúmeroCI']}
                            onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                            search={true}
                            error={validationMessage['NúmeroCI']}
                    />
                </Form.Group>
                {camposExtras}
            </Form>
        </Segment>
    )
}

export default CartaSolicitacaoItem