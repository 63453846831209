export const base = {
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 15,
        fontSize : '10px',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    titulo : {
        textAlign : 'left',
        fontWeight : 'bold',
        fontSize : '12px'
    },
    titulo2 : {
        textAlign : 'left',
        fontWeight : 'bold',
        fontSize: '10px'
    },
    label : {
        fontWeight : 'bold'
    },
    value : {
        fontWeight : 'light'
    },
    bloco : {
        marginVertical : '2px',
        border : '1px solid black',
        padding : '3px;'
    },
    blocoMenor : {
        marginVertical : '2px',
        border : '1px solid black',
        fontSize : '7px',
        padding : '3px;'
    },
    dados : {
        padding : '5px',
        flexDirection : 'column',
        justifyContent : 'space-between'
    },
    dado : {
        paddingVertical : '2px',
        paddingHorizontal : '5px',
        flexDirection : 'row',
        justifyContent : 'space-between'
    },
    table : {
        flexDirection : 'column'
    },
    tableHeader : {
        flexDirection : 'row',
        borderTop: '1px solid #333',
        borderBottom: '1px solid #333',
        borderRight: '1px solid #333'
        
    },
    tableCellDupla : {
        flexDirection : 'column',
        alignItems : 'flex-stretch',
        justifyContent : 'space-around'

    },
    tableHeaderCell : {
        borderLeft: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        padding: '3px;',
        backgroundColor : '#CCC'
    },
    tableHeaderCellDuplo1 : {
        borderLeft: '1px solid #333',
        borderBottom: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        flex:1,
        alignItems : 'center',
        padding: '3px;',
        backgroundColor : '#CCC',
    },
    tableHeaderCellDuplo2 : {
        borderLeft: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        flex:1,
        alignItems : 'center',
        padding: '3px;',
        backgroundColor : '#CCC',
    },
    tableRow : {
        flexDirection : 'row',
        borderBottom: '1px solid #333',
        borderRight: '1px solid #333'
    },
    tableRowCell : {
        borderLeft: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        padding: '3px;',
        backgroundColor : '#FFF',
    },
    tableRowCellDuplo1 : {
        borderLeft: '1px solid #333',
        borderBottom: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        flex : 1,
        padding: '3px;',
        backgroundColor : '#FFF',
    },
    tableRowCellDuplo2 : {
        borderLeft: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        flex : 1,
        padding: '3px;',
        backgroundColor : '#FFF',
    },      
    tableRowCellDestaque : {
        borderLeft: '1px solid #333',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        padding: '3px;',
        backgroundColor : '#EEE',
    },
    rodape : {
        flexDirection : 'row',
        justifyContent : 'space-between',
        paddingTop:'10px'
    },
    rodapePaginas : {
        width : '80px',
        textAlign : 'right'
    },
    tituloAno : {
        textAlign : 'center',
        fontSize : '14px',
        borderBottom : '2px solid dashed',
        paddingBottom : '3px'
    }

};

export const rao = {
    cabecalho : {
        width : '100%',
        padding : '5px',
        flexDirection : 'row',
        borderBottom : '2px solid black'

    },
    logo : {
        width : '35px',
        height : '35px'
    },
    textoTitulo : {
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center',
        flexGrow : 1
    },
    area01 : {
        flexDirection : 'row'
    },
    labelWidth01 : {
        width : '65px'
    },
    labelWidth02 : {
        width : '108px'
    },
    titulo3 : {
        flexDirection : 'row',
        justifyContent : 'center',
        fontWeight:'bold',
        backgroundColor:'#CCC',
        padding:'5px',
        margin:'3px'
    },
    cronEconomicoCell : {
        fontSize : '6px'
    }
    
    
}

