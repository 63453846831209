import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import SearchBar from './componentes/UI/SearchBar';
import IconButton from './componentes/UI/IconButton';
import history from './history';

import Contratos from './screens/Contratos';
import ContratoDetalhes from './screens/ContratoDetalhes';
import Medicoes from './screens/Medicoes';
import MedicaoDetalhes from './screens/MedicaoDetalhes';
import FrenteDetalhes from './screens/FrenteDetalhes';
import CartaDetalhes from './screens/CartaDetalhes';
import Relatorios from './screens/Relatorios';

import { Context as unidadeOrigemContext } from './context/unidadeOrigemContext';

const MainContent = () => {

    const unidadeOrigem = useContext(unidadeOrigemContext);
    
    useEffect( () => {
        const update = async () => await unidadeOrigem.buscar();
        update();
    }, []);

    return (
            <div id='main-content'>
                <div>

                    <IconButton name='arrow left' size='large' onClick={history.goBack} />
                    
                    <SearchBar />
                
                </div>

                <Divider />

                <div id='route-area'>
                    <Switch>
                        <Route path="/" exact> <Contratos /> </Route>
                        <Route path="/contratos"> <Contratos /> </Route>
                        <Route path="/contrato" exact> <ContratoDetalhes /> </Route>
                        <Route path="/contrato/:id"> <ContratoDetalhes /> </Route>
                        <Route path="/medicoes" exact> <Medicoes /> </Route>
                        <Route path="/medicoes/:E_PPI/:NumeroMedicao"> <MedicaoDetalhes /> </Route>
                        <Route path="/frente/:E_PPI/:NumeroFrente"> <FrenteDetalhes /> </Route>
                        <Route path="/carta/:E_PPI/:NumeroCartaContratada"> <CartaDetalhes /> </Route>
                        <Route path="/relatorios" exact> <Relatorios /> </Route>
                    </Switch>
                </div>
            </div>
    );
}

export default MainContent;