export default {

    contrato : {
        'E_PPI' : null,
        'OBJETO_OF' : null,
        'E_OB_AS' : null,
        'E_OB_TERM' : null,
        'DT_MEDICAO' : null,
        'Classificação' : null,
        'E_OB_PRAZO' : null,
        'E_OB_VALOR' : null
    },

    medicao : {

    },
    
    frente : {
        'NúmeroFrente' : null,
        'Quantidade' : null,
        'UnidadeMedida' : null,
        'Valor' : null
    },

    frente_medicao : {
        'Quantidade' : null,
        'ValorP0' : null
    },

    ResponsavelTecnico : {
        Resp_Titulo : null,
        "ResponsávelTécnico" : null,
    },

    cronograma : {
        'NumeroMedição' : null,
        MesAno : null,
        'FísicoPrevisto' : null
    },

    previsto_acumulado : {
        'NumeroMedição' : null
    },

    carta_novo : {
        'NúmeroCartaContratada' : null,
        'DataCarta' : null
    },

    carta_atualizar : {
        'DataCarta' : null
    },

    carta_solicitacao : {
        'TipoSolicitação' : null,
        'Definição' : null,
        'NúmeroCI' : null
    },

    carta_ci : {
        'NúmeroCI' : null

    }

};