import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

import { convertToKey } from '../../helpers/stringConvert';
//import convertToDropdownOptions from '../../helpers/convertToDropdownOptions';

const CustomDropdown = ({ 
    initialOptions = null, 
    name = null, 
    label = null, 
    defaultValue = null, 
    value = null,
    search = false, 
    canAdd = false, 
     disabled = false, 
     placeholder = null, 
     style = null, 
     error = null, 
     onBlur = null,
     onChange = null,
     blankOption = true
    }) => {

    const [ options, setOptions ] = useState([]);

    useEffect(() =>{
        
        let initialOptionsArray = [];

        if(blankOption)
            initialOptionsArray.push({
                key : 'blankOption',
                text : '',
                value : ''
            });
        
        if(!initialOptions){
            initialOptionsArray = [
                {
                    key : 0,
                    text : 'Cadastre uma opção',
                    value : ''
                }
            ];
            setOptions(initialOptionsArray);
        }else if(!Array.isArray(initialOptions) && typeof(initialOptions) === 'object'){
            for(const key in initialOptions)
                initialOptionsArray.push({
                    key,
                    text : initialOptions[key],
                    value : key
                });
            
            setOptions(initialOptionsArray);
        } else{
            setOptions(initialOptions);
        }
    },[initialOptions]);
    
    return(
        <Form.Dropdown selection 
            search = {search}
            allowAdditions = {canAdd}
            additionLabel="Adicionar "
            options={options}
            name={name}
            label={label}
            onChange={onChange}
            onAddItem={(e, { value }) => setOptions([...options.filter( item => item.text !== 'Cadastre uma opção'), {key : convertToKey(value), text : value, value : value}])}
            defaultValue={defaultValue} 
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            style={style}
            error={error}
            onBlur={onBlur}
        />
    );
}

export default CustomDropdown;