import createDataContext from './createDataContext';


const modalFormReducer = (state, action) => {

    switch(action.type){

        case 'open':
            return({
                ...state,
                open : true
            });
        
        case 'close':
            return({
                ...state,
                open : false
            });

        case 'setHeader':
            return({
                ...state,
                header : action.payload
            });

        case 'setForm':
            return({
                ...state,
                form : action.payload
            })
    
        default:
            return state;
    }
}

const actions = {

    open : dispatch => () => {
        dispatch({
            type : 'open'
        });
    },

    close : dispatch => () => {
        dispatch({
            type : 'close'
        });
    },

    setHeader : dispatch => (header) => {
        dispatch({
            type : 'setHeader',
            payload : header
        });
    },

    setForm : dispatch => (form) => {
        dispatch({
            type : 'setForm',
            payload : form
        });
    }
};

const defaultValues = {
    open : false,
    header : 'Formulário',
    formComponent : null
}

export const { Provider, Context } = createDataContext(
    modalFormReducer,
    actions,
    defaultValues
);  