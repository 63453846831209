import React from 'react';
import {Container} from 'semantic-ui-react'
import ResumoUsuario from './ResumoUsuario';
import MenuLateral from './MenuLateral';

const BarraLateral = () => {

    return (
        <Container>
            <ResumoUsuario />
            <MenuLateral />
        </Container>
    );
}

export default BarraLateral;