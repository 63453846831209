import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Icon, Button, Form } from 'semantic-ui-react';
import SemanticGrid from './UI/SemanticGrid';

import '../styles.css';

import FormCronograma from './ModalForms/FormCronograma';

import { Context as cronogramasContext } from '../context/cronogramasContext';
import { Context as modalFormContext } from '../context/modalFormContext';
import { Context as confirmContext } from '../context/confirmContext';

import Loading from './UI/Loading';
import { convertToCurrency } from '../helpers/stringConvert';
 
const ContratoCronograma = ({ E_PPI }) => {

    const { state, listarPorContrato, atualizar, novo, remover, reset } = useContext(cronogramasContext);
    const [ loading, setLoading ] = useState(true);

    //const [ inputFocus, setInputFocus ] = useState(null);

    const [ validationMessage, setValidationMessage ] = useState({});

    useEffect( () => {
        reset();
    }, []);

    useEffect( () => {
        listarPorContrato(E_PPI);
    },[E_PPI]);

    useEffect( () => {
        if(state.length === 0)
            setLoading(true);
        else
            setLoading(false);
    }, [state.length]);


    const modalForm = useContext(modalFormContext);
    const modalConfirm = useContext(confirmContext);
    
    const handleChange = (values) => {
        const id = values.id;
        values.E_PPI = E_PPI;
        
        if(values.hasOwnProperty('NumeroMedição')){
            if((values['NumeroMedição'] === '' || values['NumeroMedição'] === '0') && values['NumeroMediçãoAnterior'] !== 0)
                confirmChange(values, setValidationMessage);
            else
                atualizar(values, setValidationMessage);    

        }else{
            atualizar(values, setValidationMessage);
        }
        setValidationMessage({ ...validationMessage, ['FísicoPrevisto'+id] : null, ['NumeroMedição'+id] : null});
//        setInputFocus('FísicoPrevisto'+id);
    }

    //const rowClick = cellInfo => { console.log(cellInfo); history.push(`/medicoes/${encodeURIComponent(E_PPI)}/${cellInfo.row.values['NumeroMedição']}`)};

    
    const columns = [
        {
            Header: 'Mês/Ano',
            accessor: 'MesAno',
            width: '2'
        },
        {
            Header: 'Nº',
            accessor: 'NumeroMedição',
            width: '2',
            Cell : ({ row }) => <Form.Input name="NumeroMedição" id={`NumeroMedição${row.original.id}`} defaultValue={row.values['NumeroMedição']} error={validationMessage[`NumeroMedição${row.original.id}`]} onBlur={(e) => handleChange({id : row.original.id, MesAno : row.original.MesAno, [e.target.name] : e.target.value, 'NumeroMediçãoAnterior' : row.values['NumeroMedição']})} style={{width:'70px'}} />
            
        },
        {
            Header: 'Prev. Acumulado (%)',
            accessor: 'FísicoPrevisto',
            width: '3',
            Cell : ({ row }) => <Form.Input name="FísicoPrevisto" id={`FísicoPrevisto${row.original.id}`} defaultValue={row.values['FísicoPrevisto']} error={validationMessage[`FísicoPrevisto${row.original.id}`]} onBlur={(e) => handleChange({id : row.original.id, MesAno : row.original.MesAno, [e.target.name] : e.target.value})} style={{width:'70px'}} />
        },
        {
            Header: 'Realiz. Mensal (%)',
            accessor: 'PercFísicoMedição',
            width: '3',
            Cell : ({ row }) => convertToCurrency(row.values['PercFísicoMedição'], '.', 0)
        },
        {
            Header: 'Realiz. Acumulado (%)',
            accessor: 'FísicoRealizado',
            width: '3',
            Cell : ({ row }) => convertToCurrency(row.values['FísicoRealizado'], '.', 0)
        },
        {
            Header: '',
            accessor: 'remove',
            Cell : ({row}) => <Button basic icon onClick={() => confirmDelete({id : row.original.id, E_PPI, 'NumeroMedição' : row.values['NumeroMedição']})}><Icon name="trash" /></Button>,
            width: '1'
        }
    ];
 
    /*const filter = {
        name: 'users_filter',
        items: [
            {
                name: 'first_name',
                type: 'text',
                label: 'First Name'
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Last Name'
            },
            {
                name: 'email',
                type: 'text',
                label: 'Email'
            },
            {
                name: 'country',
                type: 'text',
                label: 'Country'
            }
        ]
    };*/ 

    // MODAL COM FORMUÁRIO DE NOVO REGISTRO
    const modalAdicionar = () => {

        //RETORNA O PRÓXIMO NUMERO DE CADA TIPO
        const numberCollection = {
            'padrao' : [],
            '777' : [],
            '999' : []            
        }

        const lastNumbers = {
            'padrao' : 0,
            '777' : 0,
            '999' : 0
        }

        state.forEach( registro => {

            const getNextNumber = (array) => {

                for(let i = 0; i < array.length; i++){
                    if(array[i+1] !== array[i]+1)
                        return array[i];
                }
            }

            const values = String(registro['NumeroMedição']).split('.');
            
            if(values[0] === '777' || values[0] === '999')                 
                numberCollection[values[0]].push(Number(values[1]));
            else if(values[0] !== '888')
                numberCollection['padrao'].push(Number(values[0]));

            Object.entries(numberCollection).forEach( item => {
                numberCollection[item[0]] = item[1].sort( (a,b) => a - b) // ORDENA OS NUMEROS EM ORDEM CRESCENTE
            });

            Object.entries(numberCollection).forEach( item => {
                lastNumbers[item[0]] = getNextNumber(item[1]);
            });

            
        });

        modalForm.open();
        modalForm.setHeader('Adicionar Medição');
        modalForm.setForm(<FormCronograma onCancel={modalForm.close} onSave={novo} E_PPI={E_PPI} lastNumbers={lastNumbers} />);

    }

    const confirmDelete = (values) => {
        
        modalConfirm.setProp({
            onConfirm : () => remover(values),
            content : `Tem certeza que deseja remover a medição ${values['NumeroMedição']}?`
        });
        modalConfirm.open();

    }


    const confirmChange = (values, setValidationMessage) => {
        modalConfirm.setProp({
            onConfirm : () => atualizar(values, setValidationMessage),
            content : `Ao alterar o número da medição para zero todos os valores serão perdidos. Deseja continuar?`
        });
        modalConfirm.open();
    }



    const rowClick = () => {};

    return(
        <div className="table-area">
            <Loading active={loading} />
            {/*<div className="table-options-area">
                <Button onClick={() => modalAdicionar()} icon><Icon name="plus" /> Adicionar Medição</Button>
            </div>*/}

            <SemanticGrid
                data={state}
                columns={columns}
                getCellProps={cellInfo => ({
                    onClick : () => cellInfo.column.Header !== '' ? rowClick(cellInfo) : () => {}
                })}
            />

        </div>

    );

}

export default ContratoCronograma;