import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import BarraLateral from './componentes/BarraLateral/BarraLateral';
import MainContent from './MainContent';

import ModalForm from './componentes/UI/ModalForm';
import Confirm from './componentes/UI/Confirm';


import { Provider as ModalFormProvider } from './context/modalFormContext';
import { Provider as ConfirmProvider } from './context/confirmContext';

import './styles.css';
import 'semantic-ui-css/semantic.min.css';

const App = () =>{

    return (
        <ModalFormProvider>
        <ConfirmProvider>        

            <Grid id="app" columns={2} divided container style={{paddingTop : '10px'}}>
                <Grid.Row stretched>
                    <Grid.Column width={3}>
                        <BarraLateral />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <MainContent />
                    </Grid.Column>                        
                </Grid.Row>

                <ModalForm />
                <Confirm />

            </Grid>
        
        </ConfirmProvider>          
        </ModalFormProvider>
    );

}

export default App;