export default (state, record, idField = 'E_PPI') => {

    let newState = [ ...state ];
    let index = -1;

    
    if(Array.isArray(idField)){
        // PARA BUSCAR REGISTROS QUE USAM MAIS DE UMA CHAVE PRIMARIA
        index = newState.findIndex( item => {        
            let check = true;
            idField.forEach( idField => {
                //console.log(`${item[idField]} !== ${record[idField]}`)
                if(item[idField] !== record[idField])
                    check = false;
            });
            return check;
        });

    } else {
        // PARA BUSCAR REGISTROS COM 1 CHAVE PRIMARIA
        index = newState.findIndex( item => item[idField] === record[idField]);

    }

    if(index < 0){
        newState.push(record); // caso não encontre, adiciona o registro no state
    }else{
        newState[index] = record; // caso encontre, substitui pelo novo registro
    }

    return newState;

}