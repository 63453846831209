import React, { useContext, useEffect, useState } from 'react';
import history from '../history';
import { Icon, Button, Header, Popup, Loader } from 'semantic-ui-react';
import SemanticGrid from '../componentes/UI/SemanticGrid';

import MedicoesFilterForm from '../componentes/FilterForms/MedicoesFilterForm';

import { Context } from '../context/medicoesContext';

import { utcToDate, convertToCurrency }  from '../helpers/stringConvert';
import loadOnScroll from '../helpers/loadOnScroll';

const columns = [
    {
        Header: 'Contrato',
        accessor: 'E_PPI',
        width: '2'
    },
    {
        Header: 'Fechamento',
        accessor: 'Fechamento',
        width: '2',
        Cell : ({ row }) => <span>{utcToDate(row.values.Fechamento, 'br')}</span>
    },
    {
        Header: 'Entrega',
        accessor: 'DataEntrega',
        width: '2',
        Cell : ({ row }) => <span>{utcToDate(row.values.DataEntrega, 'br')}</span>
    },
    {
        Header: 'Número',
        accessor: 'NumeroMedição',
        width: '2'
    },
    {
        Header: 'Inicio',
        accessor: 'PeríodoInicial',
        width: '3',
        Cell : ({ row }) => <span>{utcToDate(row.values['PeríodoInicial'], 'br')}</span>
    },
    {
        Header: 'Final',
        accessor: 'PeríodoFinal',
        width: '3',
        Cell : ({ row }) => <span>{utcToDate(row.values['PeríodoFinal'], 'br')}</span>
    },
    {
        Header: 'ValorP0',
        accessor: 'ValorP0',
        width: '3',
        Cell : ({ row }) => <span>{convertToCurrency(row.values['ValorP0'], true)}</span>
    },
    {
        Header: '',
        accessor: 'remove',
        Cell : ({row}) => <Button basic icon onClick={() => console.log(row)}><Icon name="trash" /></Button>,
        width: '1'
    }
];

//import OrderSelector from '../componentes/UI/OrderSelector';

//import clearFieldName from '../helpers/clearFieldName';

const Contratos = () => {

    const { state, listar, reset } = useContext(Context);
    const [ loading, setLoading ] = useState(true);
    let content = null;

    useEffect( () => {
        const initiate = async () => {
            setLoading(true);
            reset();
            await listar();
            setLoading(false);
        }
        initiate();
    },[]);

    const rowClick = cellInfo => { console.log(cellInfo); history.push(`/medicoes/${encodeURIComponent(cellInfo.row.values.E_PPI)}/${cellInfo.row.values['NumeroMedição']}`)};
    
    const [ filterPopupState, setFilterPopupState ] = useState(false);

    const defaultQuant = 200;

    // CONTROLE DO CARREGAMENTO DE REGISTROS CONFORME O SCROLL
    const [ quantidade, setQuantidade ] = useState(defaultQuant);
    const onScroll = (event) => {
       loadOnScroll(defaultQuant, event, quantidade, setQuantidade, state.length);
    }

    if(loading){
        content = 
            <div className='route-content'>
                <Loader active={true} content="Aguarde..." />
            </div>;

    }else if(state.length <= 0){
        content = 
            <div className='route-content'>
                <Header textAlign="center" content="Nenhuma Medição encontrada" />
            </div>;

    }else{

        content = 
            <div className="scroll-list" onScroll={onScroll} style={{top:'50px'}}>
                <SemanticGrid
                data={state}
                columns={columns}
                getCellProps={cellInfo => ({
                    onClick : () => cellInfo.column.Header !== '' ? rowClick(cellInfo) : () => {}
                })}
                quant={quantidade}
                />
            </div>;
            
    }

    return (
            
        <div className='table-area'>
            <div className="table-options-area">
                <Header size='large' color='green' floated='left'> Medições </Header>
                
                {/*<Button basic icon size='small'>
                    <Icon name='plus' /> Nova Medição
                </Button>*/}
                
                <Popup
                    trigger={ <Button floated="right" content='Filtros' icon='filter' size='small' onClick={() => setFilterPopupState(!filterPopupState)} /> }
                    on="click"
                    position="bottom right"
                    content={<MedicoesFilterForm setPopupState={setFilterPopupState} setLoading={setLoading} listar={listar} />}
                    open={filterPopupState}
                />
                
            </div>

            {content}
            
        </div>
    );

}

export default Contratos;