import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Confirm } from 'semantic-ui-react';

import { Context } from '../../context/confirmContext';

export default () => {

    const { state, close } = useContext(Context);

    const cancelAndClose = () => {
        state.onCancel();
        close();
    }

    const confirmAndClose = () => {
        state.onConfirm();
        close();
    }

    return(
        ReactDOM.createPortal(
            <Confirm
                open={state.open}
                onCancel={cancelAndClose}
                onConfirm={confirmAndClose}
                content={state.content}
                cancelButton={state.cancelButton}
                confirmButton={state.confirmButton}
            />
            ,document.querySelector('#confirm')
        )
    );
}

