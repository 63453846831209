import React, { useEffect, useState, useContext } from 'react';
import { Header, Tab, Breadcrumb, Loader } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';

import { Context } from '../context/frentesContext';

import FrenteDados from '../componentes/FrenteDados';
import FrenteMateriais from '../componentes/FrenteMateriais';

const FrenteDetalhes = () => {
    
    let { E_PPI, NumeroFrente } = useParams();
    E_PPI = decodeURIComponent(E_PPI);
    NumeroFrente = Number(NumeroFrente);
    
    const { state, buscar, atualizar } = useContext(Context);

    const [ frente, setFrente ] = useState({});
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        const update = async () => {
            await buscar(E_PPI, NumeroFrente);
            setLoading(false);
        }
        update();
    },[]);
    
    useEffect( () => {
        console.log(state);
        setFrente(state.find( (item) => item.E_PPI === E_PPI && item['NúmeroFrente'] === NumeroFrente) );

    },[state, E_PPI, NumeroFrente]);
    
    if(loading){
        return (
            <div className='route-content'>
                <Loader active={true} content="Aguarde..." />
            </div>
        );

    }else if(frente === undefined){
        return(
            <div className='route-content'>
                <Header textAlign="center" content="Frente não encontrada" />
            </div>
        );

    }else{

        const panes = [
            {
                menuItem : 'Dados',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><FrenteDados dados={frente} atualizar={atualizar} /></Tab.Pane>,
            },
            {
                menuItem : 'Materiais',
                render : () => <Tab.Pane className='tab-pane' style={{top:43}}><FrenteMateriais /></Tab.Pane>,
            }
        ];

        return (
            <div className='route-content'>
                <Header size='large' color='orange'> 
                    Frente {NumeroFrente}
                    <Header.Subheader>
                        <Breadcrumb>
                            <Breadcrumb.Section link><Link to={`/contrato/${encodeURIComponent(E_PPI)}`}>Contrato {E_PPI}</Link></Breadcrumb.Section>
                            {/*<Breadcrumb.Section link><Link to="/contrato">Contrato {E_PPI}</Link></Breadcrumb.Section>
                            <Breadcrumb.Divider icon="right chevron" />
                            <Breadcrumb.Section link><Link to="/medicao">Medicao 2</Link></Breadcrumb.Section>*/}
                        </Breadcrumb>
                    </Header.Subheader>    
                </Header>
                <Tab className='extend' panes={panes} />
            </div>
        );
    }
}

export default FrenteDetalhes;