import React, { useState } from 'react';
import { Label, Form, Segment, Header, Divider } from 'semantic-ui-react';
import { useFormik } from 'formik';

import CustomDropdown from './UI/CustomDropdown';

import validationSchema from '../validationSchema';

import { utcToDate, convertToCurrency } from '../helpers/stringConvert';
import {handleChange, handleBlur, handleSelectChange} from '../helpers/handleForm';
import {
    TIPO_AVALIACAO
} from '../helpers/dropdownNames';
import useOptions from '../hooks/useOptions';

const MedicaoDados = ({ dados, atualizar }) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.frente);

    const options = useOptions([
        TIPO_AVALIACAO
    ]);
    
    const formik = useFormik({

        initialValues : dados,

        onSubmit : values => {
            atualizar(values, setValidationMessage);
        }

    });    

    return(
        
        <div className='scroll-list' style={{top : 0}}>
            <Form widths='equal'>
                <Segment basic>
                    <Label attached='top' size="big" color="green">Gerais</Label>
                    <Divider />
                    <Form.Group>
                        <Form.Input label="Entrega" name="DataEntrega" value={utcToDate(formik.values['DataEntrega'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} type="date" />
                        <Form.Input label="Início" name="PeríodoInicial" value={utcToDate(formik.values['PeríodoInicial'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} type="date" />
                        <Form.Input label="Fim" name="PeríodoFinal" value={utcToDate(formik.values['PeríodoFinal'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} type="date" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label="Valor Reajuste" name="Reajuste" value={convertToCurrency(formik.values['Reajuste'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                        <Form.Input label="Data de Liberação" name="SaídaUnidPagadora" value={utcToDate(formik.values['SaídaUnidPagadora'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} type="date" />
                        
                    </Form.Group>                    
                    <Form.TextArea label="Observações" name="OBS" value={formik.values['OBS']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                </Segment>
                
                <Segment basic>
                    <Label attached='top' size="big" color="green">Formulário de Avaliação da Contratada (FAC)</Label>
                    <Divider basic></Divider>
                    <Form.Group>
                    <CustomDropdown
                        initialOptions={options[TIPO_AVALIACAO]}
                        name="FacAvaliação"
                        label="Avaliação"
                        value={formik.values.FacAvaliação}
                        onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                        search={true}
                    />
                        <Form.Input label="IC" name="Med_IC" value={formik.values['Med_IC']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                    </Form.Group>

                    <Header as="h4" color="green" dividing>Dados da Carta</Header>

                    <Form.Group>
                        <Form.Input label="Nº da Carta" name="FacCartaNumero" value={formik.values['FacCartaNumero']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                        <Form.Input label="Data" name="FacCartaData" value={utcToDate(formik.values['FacCartaData'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} type="date" />
                        <Form.Input label="Unidade" name="FacCartaUnidade" value={formik.values['FacCartaUnidade']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} />
                    </Form.Group>

                    {/*<Header as="h4" color="green" dividing>Dados da Reunião</Header>

                    <Form.Group>
                        <Form.Select label="Engenheiro" />
                        <Form.Select label="Unidade" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Input label="Endereço" />
                        <Form.Input label="Data" type="date" />
                        <Form.Input label="Hora" />
                    </Form.Group>

                    <Form.TextArea label="Observações" />

                    <Header as="h3" dividing>Itens</Header>

                    <Form.TextArea label="Itens" />
                    */}

                </Segment>
            </Form>
            
        </div>
    );
}

export default MedicaoDados;