import React, { useState } from 'react';
import { Form, Header, Segment, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';

import CustomDropdown from '../componentes/UI/CustomDropdown';

import { 
    DATAS_FECHAMENTOS
} from '../helpers/dropdownNames';

import useOptions from '../hooks/useOptions';

export default () => {
     
    const options = useOptions([
        DATAS_FECHAMENTOS
    ]);

    const now = new Date();

    const formik = useFormik({
        initialValues : {
            'Fechamento' : `${now.getFullYear()}-${String(now.getMonth() + 1).length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-15`
        },
        
        onSubmit : async (values, { resetForm }) => {
            window.open(`/relatorio/rao?Fechamento=${values['Fechamento']}`,'blank');
        }
    
    });

    const handleChange = (e, {name, value}) => {
        formik.setFieldValue(name, value);        
    }
   
    return(
        <Segment color="blue">
            <Header size="medium">
                RAO - Relatório de Acompanhamento de Obras
            </Header>
            <Form widths="equal">
                <Form.Group>
                    <CustomDropdown
                        initialOptions={options[DATAS_FECHAMENTOS]}
                        name="Fechamento"
                        label="Fechamento"
                        onChange={handleChange}
                        value={formik.values.Fechamento}
                    />
                </Form.Group>
            </Form>
            <Button onClick={formik.submitForm} primary>Gerar</Button>
        </Segment>
    )
}