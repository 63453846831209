import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { base, rao } from './styles/styles';

import { convertToCurrency } from '../helpers/stringConvert';

const tableColsWidth = {
    col1 : { width : '20px'},
    col2 : { width : '115px'},
    col3 : { width : '40px'},
    col4 : { width : '40px'},
    col5 : { width : '50px'},
    col6 : { width : '70px'},
    col5_6 : { width : '120px'},
    col1_2_3 : { width : '175px'}

}

const styles = StyleSheet.create({
    ...base,
    ...rao,
    ...tableColsWidth
});


export default ({ frentes, NumeroMedicao, cronograma, valorContrato }) => {

    let pesoPercentual = 0;
    let pesoTotal = 0;
    let totalFinanceiroMedicao = 0;
    let totalFinanceiroAcumulado = 0;
    let totalFinanceiroAtual = 0;

    const frentesContent = frentes.map( frente => {

        pesoTotal += frente['Peso'];

        const medicaoAtual = frente.medicoes.filter( medicao => medicao['NumeroMedição'] === NumeroMedicao);
        
        const medicaoAtualContent = <>
                    <View style={[styles.col5, styles.tableCellDupla]}>
                        <View style={styles.tableRowCellDuplo1}>
                            <Text>{convertToCurrency(medicaoAtual[0]['PercFísico'], '.')}%</Text>
                        </View>
                        <View style={styles.tableRowCellDuplo2}>
                            <Text>{convertToCurrency(medicaoAtual[0]['Quantidade'], '.')}</Text>
                        </View>
                    </View>
                    <View style={[styles.tableRowCell, styles.col6]}>
                        <Text>{convertToCurrency(medicaoAtual[0]['ValorP0'], '.')}</Text>
                    </View>
                </>;

        totalFinanceiroMedicao += medicaoAtual[0]['ValorP0'];

        const medicaoAnterior = frente.medicoes
            .filter( medicao => medicao['NumeroMedição'] < NumeroMedicao)
            .reduce( (acc, curr) => {
                /*console.log(acc);
                console.log(curr);*/
                return({
                    'PercFísico' : acc['PercFísico'] + curr['PercFísico'],
                    'Quantidade' : acc['Quantidade'] + curr['Quantidade'],
                    'ValorP0' : acc['ValorP0'] + curr['ValorP0']
                });
            }, {
                'PercFísico' : 0,
                'Quantidade' : 0,
                'ValorP0' : 0
        });

       const medicaoAnteriorContent = <>
                    <View style={[styles.col5, styles.tableCellDupla]}>
                        <View style={styles.tableRowCellDuplo1}>
                            <Text>{convertToCurrency(medicaoAnterior['PercFísico'], '.')}%</Text>
                        </View>
                        <View style={styles.tableRowCellDuplo2}>
                            <Text>{convertToCurrency(medicaoAnterior['Quantidade'], '.')}</Text>
                        </View>
                    </View>
                    <View style={[styles.tableRowCell, styles.col6]}>
                        <Text>{convertToCurrency(medicaoAnterior['ValorP0'], '.')}</Text>
                    </View>
                </>;

        totalFinanceiroAcumulado += medicaoAnterior['ValorP0'];


        const medicaoAcumulado = {
            'PercFísico' : medicaoAtual[0]['PercFísico'] + medicaoAnterior['PercFísico'],
            'Quantidade' : medicaoAtual[0]['Quantidade'] + medicaoAnterior['Quantidade'],
            'ValorP0' : medicaoAtual[0]['ValorP0'] + medicaoAnterior['ValorP0']
        }

        const medicaoAcumuladoContent = <>
                    <View style={[styles.col5, styles.tableCellDupla]}>
                        <View style={styles.tableRowCellDuplo1}>
                            <Text>{convertToCurrency(medicaoAcumulado['PercFísico'], '.')}%</Text>
                        </View>
                        <View style={styles.tableRowCellDuplo2}>
                            <Text>{convertToCurrency(medicaoAcumulado['Quantidade'], '.')}</Text>
                        </View>
                    </View>
                    <View style={[styles.tableRowCell, styles.col6]}>
                        <Text>{convertToCurrency(medicaoAcumulado['ValorP0'], '.')}</Text>
                    </View>
                </>;

            totalFinanceiroAtual += medicaoAcumulado['ValorP0'];

        return(
            <View style={styles.tableRow}>
                <View style={[styles.tableRowCell, styles.col1]}>
                    <Text>{frente['NúmeroFrente']}</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col2, {justifyContent : 'flex-start'}]}>
                    <Text>{frente['Frente']}</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col3]}>
                    <Text>{convertToCurrency(frente['Quantidade'], '.')}</Text>
                </View>
                <View style={[styles.col4, styles.tableRowDuplo]}>
                    <View style={styles.tableRowCellDuplo1}>
                        <Text>{frente['Peso']}</Text>
                    </View>
                    <View style={styles.tableRowCellDuplo2}>
                        <Text>{frente['UnidadeMedida']}</Text>
                    </View>
                </View>

                {medicaoAtualContent}
                {medicaoAnteriorContent}
                {medicaoAcumuladoContent}
                
            </View>
        );
    });


    //TOTAIS

    pesoPercentual = pesoTotal / 100;

    const totaisContent = 
        <>
            <View style={styles.tableRow}>
                <View style={[styles.tableHeaderCell, styles.col1_2_3, {flexDirection : 'column'}]}>
                    <Text>Totais</Text>
                    <Text>Físicos / Financeiros</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col4 ]}>
                    <Text>{convertToCurrency(pesoPercentual, '.', 0)}%</Text>
                </View>
                <View style={[styles.tableCellDupla, styles.col5 ]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>{convertToCurrency(cronograma['PercFísicoMedição'], '.', 0)}%</Text>
                    </View>
                    <View style={styles.tableRowCellDuplo2}>

                    </View>
                </View>
                <View style={[styles.tableCellDupla, styles.col6 ]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>{convertToCurrency(totalFinanceiroMedicao, '.')}</Text>
                    </View>
                    <View style={styles.tableHeaderCellDuplo2}>
                        <Text>{convertToCurrency((totalFinanceiroMedicao / valorContrato) * 100, '.', 0)}%</Text>
                    </View>
                </View>
                <View style={[styles.tableCellDupla, styles.col5 ]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>{convertToCurrency(cronograma['FísicoRealizado'] - cronograma['PercFísicoMedição'], '.', 0)}%</Text>
                    </View>
                    <View style={styles.tableRowCellDuplo2}>

                    </View>
                </View>
                <View style={[styles.tableCellDupla, styles.col6 ]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>{convertToCurrency(totalFinanceiroAcumulado, '.')}</Text>
                    </View>
                    <View style={styles.tableHeaderCellDuplo2}>
                        <Text>{convertToCurrency((totalFinanceiroAcumulado / valorContrato) * 100, '.', 0)}%</Text>
                    </View>
                </View>
                <View style={[styles.tableCellDupla, styles.col5 ]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>{convertToCurrency(cronograma['FísicoRealizado'], '.', 0)}%</Text>
                    </View>
                    <View style={styles.tableRowCellDuplo2}>

                    </View>
                </View>
                <View style={[styles.tableCellDupla, styles.col6 ]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>{convertToCurrency(totalFinanceiroAtual, '.')}</Text>
                    </View>
                    <View style={styles.tableHeaderCellDuplo2}>
                        <Text>{convertToCurrency((totalFinanceiroAtual / valorContrato) * 100, '.', 0)}%</Text>
                    </View>
                </View>
            </View>
        </>


    return(
        <>
            <View style={styles.tableHeader}>                    
                <View style={[styles.tableHeaderCell, styles.col1]}>
                    <Text></Text>
                </View>   
                <View style={[styles.tableHeaderCell, styles.col2]}>
                    <Text>Frente de Serviço</Text>
                </View>   
                <View style={[styles.tableHeaderCell, styles.col3, {flexDirection : 'column'}]}>
                    <Text>Quant.</Text>
                    <Text>Prevista</Text>
                </View>   
                <View style={[styles.tableHeaderCell, styles.col4]}>
                    <Text>Peso UNID</Text>
                </View>   
                <View style={[styles.col5_6, { flexDirection : 'column'}]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>DESTA MEDIÇÃO</Text>
                    </View>
                    <View style={{ flexDirection : 'row'}}>
                        <View style={[styles.tableHeaderCell, styles.col5]}>
                            <Text>Físico</Text>
                        </View>
                        <View style={[styles.tableHeaderCell, styles.col6]}>
                            <Text>Financeiro</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.col5_6, { flexDirection : 'column'}]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>ACUMULADO ANTERIOR</Text>
                    </View>
                    <View style={{ flexDirection : 'row'}}>
                        <View style={[styles.tableHeaderCell, styles.col5]}>
                            <Text>Físico</Text>
                        </View>
                        <View style={[styles.tableHeaderCell, styles.col6]}>
                            <Text>Financeiro</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.col5_6, { flexDirection : 'column'}]}>
                    <View style={styles.tableHeaderCellDuplo1}>
                        <Text>ACUMULADO ATUAL</Text>
                    </View>
                    <View style={{ flexDirection : 'row'}}>
                        <View style={[styles.tableHeaderCell, styles.col5]}>
                            <Text>Físico</Text>
                        </View>
                        <View style={[styles.tableHeaderCell, styles.col6]}>
                            <Text>Financeiro</Text>
                        </View>
                    </View>
                </View>
            </View>

            {frentesContent}

            {totaisContent}
            
        </>
    );

}