import React, { useContext, useEffect, useState, useCallback } from 'react';
import history from '../history';
import { Segment, Header } from 'semantic-ui-react';

import MedicaoFrentesItem from './MedicaoFrentesItem';

import { Context } from '../context/frentesContext';
import { Context as contratoContext } from '../context/contratosContext';

import { getFrentesMedicao } from '../functions/frentesFunctions';

import Loading from './UI/Loading';
 
const MedicaoFrentes = ({ E_PPI, NumeroMedicao, Fechamento, buscarMedicao }) => {

    const { state, listarPorContrato, atualizarMedicoes, reset } = useContext(Context);
    const contrato = useContext(contratoContext);

    const [ frentes, setFrentes ] = useState([]); // SERÁ OS VALORES DAS FRENTES MAIS A RESPECTIVA MEDIÇÃO
    const [ loading, setLoading ] = useState(true);

    const contratoFrente = contrato.state.find( item => item.E_PPI === E_PPI);

    
    useEffect( () => {
        reset();
    }, []);

    useEffect( () => {
        setLoading(true);
        const getData = async () => {
            await contrato.buscar(E_PPI);
            await listarPorContrato(E_PPI, setLoading);
        }
        getData();
    },[E_PPI]);

    useEffect( () => {
        setFrentes(getFrentesMedicao(NumeroMedicao, Fechamento, contratoFrente, state));

    }, [state]);


    const frenteItem = useCallback( item => {
        return <MedicaoFrentesItem key={item['NúmeroFrente']} frenteMedicao={item} atualizarMedicoes={atualizarMedicoes} buscarMedicao={buscarMedicao} />;
    }, [frentes])
    
    let frentesList = null;
    if(frentes !== null){
        if(frentes.length > 0){
            frentesList = frentes.map( frenteItem );
            frentesList = <Segment.Group>{frentesList}</Segment.Group>

        }else if(loading === false){
            frentesList = <Header>Nenhuma Frente Cadastrada</Header>;

        }
    }


    let lista = null;

    if(frentes.length > 0){

        lista = frentesList;

    }else if(loading === false){
        lista = <Segment as="h2" basic textAlign="center" content="Nenhuma frente encontrada." />;
    }

    return(
        <div className="table-area">
            <Loading active={loading} />
            {lista}

        </div>

    );

}

export default MedicaoFrentes;