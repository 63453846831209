
export const calculaPesoFrente = (valorFrente, valorContrato) => {

    try{

        if(!valorFrente)  throw new Error('Informe o Valor da frente');
        if(!valorContrato) throw new Error('Informe o valor do contrato');

        valorFrente = Number(String(valorFrente).replace(',','.'));
        valorContrato = Number(String(valorContrato).replace(',','.'));

        if(isNaN(valorFrente)) throw new Error('Valor da frente inválido');
        if(isNaN(valorContrato))  throw new Error('Valor do contrato inválido');

        return (valorFrente / valorContrato) * 100;

    }catch(err){
        console.error("Erro ao calcular o peso da frente => " + err.message);
        return 0;
    }
}