import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import RenderDocument from './RenderDocument';
import App from './App';

import Login from './Login';

// PROVEDORES DE DADOS (CONTEXTO)
import { Provider as ContratosProvider } from './context/contratosContext';
import { Provider as MedicoesProvider } from './context/medicoesContext';
import { Provider as FrentesProvider } from './context/frentesContext';
import { Provider as FrentesMedicaoProvider } from './context/frentesMedicaoContext';
import { Provider as CartasProvider } from './context/cartasContext';
import { Provider as CartasSolicitacoesProvider } from './context/cartasSolicitacoesContext';
import { Provider as CartasCIProvider } from './context/cartasCIContext';
import { Provider as ResponsaveisTecnicosProvider } from './context/responsaveisTecnicosContext';
import { Provider as UnidadeOrigemProvider } from './context/unidadeOrigemContext';
import { Provider as CronogramaProvider } from './context/cronogramasContext';
import { Provider as OptionsProvider } from './context/optionsContext';
import { Provider as AuthProvider, Context as authContext} from './context/authContext';

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


const MainApp = () => {

    const auth = useContext(authContext);

    let content = null;
    console.log(auth.state);
    if(auth.state || getCookie('logged') === 'true'){

        content = 
        <OptionsProvider>
        
        <UnidadeOrigemProvider>
        <ContratosProvider>
        <MedicoesProvider>
        <FrentesProvider>
        <FrentesMedicaoProvider>
        <CartasProvider>
        <CartasSolicitacoesProvider>
        <CartasCIProvider>
        <CronogramaProvider>
        <ResponsaveisTecnicosProvider>
        
        <Router history={history}>
            <Switch>
                <Route path="/relatorio/:tituloRelatorio"><RenderDocument /></Route>
                <Route path="/"><App /></Route>
            </Switch>
        </Router>
        
        </ResponsaveisTecnicosProvider>
        </CronogramaProvider>
        </CartasCIProvider>
        </CartasSolicitacoesProvider>
        </CartasProvider>
        </FrentesMedicaoProvider>
        </FrentesProvider>
        </MedicoesProvider>
        </ContratosProvider>        
        </UnidadeOrigemProvider>
        
        </OptionsProvider>    


    }else{

        content = <Login />;

    }

    return content;

}


ReactDOM.render(

    <AuthProvider>
        <MainApp />
    </AuthProvider>
    
, document.getElementById('root'));