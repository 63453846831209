import React, { useContext, useEffect } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';

import CartaCIItem from './CartaCIItem';
import FormCartaCI from './ModalForms/FormCartaCI';

import { Context as cartasCIContext } from '../context/cartasCIContext';
import { Context as modalFormContext } from '../context/modalFormContext';
import { Context as confirmContext } from '../context/confirmContext';

const CartaCI = ({ E_PPI, NumeroCartaContratada }) => {

    const { state, novo, atualizar, listarPorCarta, remover } = useContext(cartasCIContext);

    const modalForm = useContext(modalFormContext);
    const modalConfirm = useContext(confirmContext);

    let ciList = null;
    
    useEffect( () => {
        listarPorCarta(E_PPI, NumeroCartaContratada);
    },[]);

    // PEDE CONFIRMAÇÃO ANTES DE REMOVER
    const confirmDelete = (E_PPI, NumeroCartaContratada, TipoSolicitacao) => {
        
        modalConfirm.setProp({
            onConfirm : () => remover(E_PPI, NumeroCartaContratada, TipoSolicitacao),
            content : `Tem certeza que deseja remover a CI?`
        });
        modalConfirm.open();

    }

    // MODAL COM FORMUÁRIO DE NOVO REGISTRO
    const modalAdicionar = () => {
        modalForm.open();
        modalForm.setHeader('Adicionar CI');
        modalForm.setForm(<FormCartaCI onCancel={modalForm.close} onSave={novo} E_PPI={E_PPI} NumeroCartaContratada={NumeroCartaContratada} />);
    }

    if(state.length > 0){
        ciList = state
            .filter( item => item.E_PPI === E_PPI && item['NúmeroCartaContratada'] === NumeroCartaContratada)
            .map( (item) => {
                return(
                    <CartaCIItem
                        key={item['NúmeroCI']}
                        item={item}
                        remover={confirmDelete}
                        atualizar={atualizar}
                    />
            );
        });
        ciList = <Segment.Group>{ciList}</Segment.Group> ;
    }else{
        ciList = <Header>Nenhuma CI para essa carta</Header>
    }


    return( 
    <Segment basic>
        <Segment padded basic>
            <Header floated="left" size="medium" color="green">
                CIs
            </Header>
            <Button floated="right" onClick={modalAdicionar} color="green" size="small">Adicionar CI</Button>
        </Segment>
        {ciList}      

    </Segment>
    );

}

export default CartaCI;