import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import deleteState from '../helpers/deleteState';
import validate from '../helpers/validate';


const defaultState = [];


const reducer = (state, action) => {

    let newState = [];

    switch(action.type){

        case 'listar':
            return action.payload;

        case 'buscar':            
            newState = updateState(state, action.payload);
            return newState;

        case 'atualizar':
            newState = updateState(state, action.payload);
            return newState;
        
        case 'novo':
            return [action.payload, ...state];

        default:
            return state;

    }
}


const actions = {

    listar : dispatch => async (query = null) => {

        const resposta = await sisobrasServer.get('/contratos', {params : query});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'listar',
            payload : resposta.data.dados
        });
    },

    buscar : dispatch => async (id = null) => {

        if(!id){
            console.error("Buscar Contrato - ID do contrato não informado");
            return false;
        } 

        const resposta = await sisobrasServer.get('/contratos/'+encodeURIComponent(id));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    },


    atualizar : dispatch => async (E_PPI, dados = null, setValidationMessage) => {

        if(!E_PPI){
            console.error("Atualizar Contrato - ID do contrato não informado");
            return false;
        }

        const resposta = await sisobrasServer.put('/contratos/'+encodeURIComponent(E_PPI), dados);

        validate('contrato', resposta.data.errosValidacao, setValidationMessage);

        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizar',
            payload : resposta.data.dados[0]
        });
    },


    novo : dispatch => async ( dados = null, setValidationMessage = null, afterUpdate = null) => {

        if(!dados){
            console.error("Erro ao cadastrar contrato -> Dados incompletos");
            return false;
        } 

        const resposta = await sisobrasServer.post('/contratos', dados);
        
        validate('contrato',resposta.data.errosValidacao,setValidationMessage);

        if(!errorHandler(resposta.data)) return false;

        // FECHA A MODAL DO FORMULÁRIO CASO EXISTA E NÃO TENHA OCORRIDO ERROS DE VALIDAÇÃO
        if(afterUpdate && resposta.data.errosValidacao === undefined)
            afterUpdate();
        
        dispatch({
            type : 'novo',
            payload : resposta.data.dados[0]
        });

    },


    aditamentos : dispatch => async (E_PPI = null) => {

        const resposta = await sisobrasServer.get('/contratos/aditamentos/' + encodeURIComponent(E_PPI));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'aditamentos',
            payload : resposta.data.dados[0]
        });
    },

}


export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)