import React, { useContext} from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'semantic-ui-react';

import { Context } from '../../context/modalFormContext';

const QuickFormModal = () => {
    
    const { state, close } = useContext(Context);

    return(
        ReactDOM.createPortal(
            <Modal
                open={state.open}      
                onClose={close}
                header={state.header}
                content={state.form}
                closeOnDimmerClick={false}
            />
            ,document.querySelector('#quick-form-modal')
        )
    );
}

export default QuickFormModal;