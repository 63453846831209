import createDataContext from './createDataContext';


const ConfirmReducer = (state, action) => {

    switch(action.type){

        case 'open':
            return({
                ...state,
                open : true
            });
        
        case 'close':
            return({
                ...state,
                open : false
            });

        case 'setProp':
            return({
                ...state,
                ...action.payload
            });
    
        default:
            return state;
    }
}

const actions = {

    open : dispatch => () => {
        dispatch({
            type : 'open'
        });
    },

    close : dispatch => () => {
        dispatch({
            type : 'close'
        });
    },

    setProp : dispatch => (prop) => {
        dispatch({
            type : 'setProp',
            payload : prop
        });
    }
};

const defaultValues = {
    open : false,
    content : 'Tem certeza?',
    onCancel : () => {},
    onConfirm : () => {},
    cancelButton : 'Não',
    confirmButton : 'Sim'
}

export const { Provider, Context } = createDataContext(
    ConfirmReducer,
    actions,
    defaultValues
);  