import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import deleteState from '../helpers/deleteState';
import validate from '../helpers/validate';
 
const defaultState = [];

const reducer = (state, action) => {

    let newState = [];

    switch(action.type){
        
        case 'listar':
            return action.payload;

        case 'listarPorContrato':
            newState = [ ...state];
            action.payload.forEach( item => {
                newState = updateState(newState, item, ['E_PPI','NúmeroFrente']);
            });
            return newState;
            //return action.payload;

        case 'buscar':
            return updateState(state, action.payload, ['E_PPI', 'NúmeroFrente']);

        case 'atualizar':
            return updateState(state, action.payload, ['E_PPI', 'NúmeroFrente']);
                    
        case 'novo':
            return [action.payload, ...state];

        case 'remover':            
            return deleteState(state, action.payload, ['E_PPI', 'NúmeroFrente']);

        case 'atualizarMedicoes':
            newState = [...state];
            
            // RETORNA O REGISTRO DA FRENTE
            const record = state.find( item => item['E_PPI'] === action.payload['E_PPI'] && item['NúmeroFrente'] === action.payload['NúmeroFrente']);

            // RETORNA O INDICE DA FRENTE
            const index = newState.findIndex( item => item['E_PPI'] === action.payload['E_PPI'] && item['NúmeroFrente'] === action.payload['NúmeroFrente']);

            // SUBSTITUI AS MEDIÇÕES DA FRENTE PELAS MEDIÇÕES RETORNADAS NA ATUALIZAÇÃO
            newState[index].medicoes = updateState(record.medicoes, action.payload, ['E_PPI', 'NúmeroFrente', 'NumeroMedição']);
            
            return newState;
            
        case 'reset':
            return defaultState;

        default:
            return state;

    }
}

const actions = {
    
    listar : dispatch => async (setLoading = null, query = null) => {

        const resposta = await sisobrasServer.get('/frentes/', {params : query});
        
        if(!errorHandler(resposta.data)) return false;

        if(setLoading)
            setLoading(false);
        
        dispatch({
            type : 'listar',
            payload : resposta.data.dados
        });
    },

    listarPorContrato : dispatch => async (E_PPI, setLoading = null, query = null) => {

        const resposta = await sisobrasServer.get('/frentes/contrato/' + encodeURIComponent(E_PPI), {params : query});
        
        if(!errorHandler(resposta.data)) return false;

        if(setLoading)
            setLoading(false);
        
        dispatch({
            type : 'listarPorContrato',
            payload : resposta.data.dados
        });
    },

    buscar : dispatch => async (E_PPI, NumeroFrente) => {

        if(!E_PPI){
            console.error("Buscar Frente - Código do contrato não informado");
            return false;
        } 
        if(!NumeroFrente){
            console.error("Buscar Frente - Número da frente não informado");
            return false;
        }

        const resposta = await sisobrasServer.get('/frentes/' + encodeURIComponent(E_PPI) + '/' + encodeURIComponent(NumeroFrente));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    },

    atualizar : dispatch => async (dados = null, setValidationMessage) => {
        
        if(!dados.E_PPI){
            console.error("Atualizar Frente - Contrato da frente não informado");
            return false;
        } 

        if(!dados['NúmeroFrente']){
            console.error("Atualizar Frente - Número da frente não informado");
            return false;
        } 
        
        const resposta = await sisobrasServer.put(`/frentes/${encodeURIComponent(dados.E_PPI)}/${dados['NúmeroFrente']}`, dados);

        validate('frente',resposta.data.errosValidacao,setValidationMessage);
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizar',
            payload : resposta.data.dados[0]
        });
    },


    novo : dispatch => async ( dados = null, setValidationMessage = null, afterUpdate = null) => {

        if(!dados){
            console.error("Erro ao cadastrar frente -> Dados incompletos");
            return false;
        } 

        const resposta = await sisobrasServer.post('/frentes', dados);
        
        validate('frente',resposta.data.errosValidacao,setValidationMessage);

        if(!errorHandler(resposta.data)) return false;

        // FECHA A MODAL DO FORMULÁRIO CASO EXISTA E NÃO TENHA OCORRIDO ERROS DE VALIDAÇÃO
        if(afterUpdate && resposta.data.errosValidacao === undefined)
            afterUpdate();
        
        dispatch({
            type : 'novo',
            payload : resposta.data.dados[0]
        });

    },


    remover : dispatch => async (E_PPI, NumeroFrente) => {
         
        if(!E_PPI){
            console.error("Erro ao remover frente - Contrato da frente não informado");
            return false;
        } 

        if(!NumeroFrente){
            console.error("Erro ao remover frente - Número da frente não informado");
            return false;
        } 

        const data = {
            E_PPI,
            NumeroFrente
        };

        const resposta = await sisobrasServer.delete('/frentes/', { data });
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'remover',
            payload : { E_PPI, 'NúmeroFrente' : NumeroFrente}
        });
    },

    atualizarMedicoes : dispatch => async (dados = null, setValidationMessage) => {
        
        if(!dados.E_PPI){
            console.error("Atualizar Medição da Frente - Contrato da frente não informado");
            return false;
        }

        if(!dados['NumeroMedição']){
            console.error("Atualizar Medição da Frente - Número da Medição não informado");
            return false;
        }

        if(!dados['NúmeroFrente']){
            console.error("Atualizar Medição da Frente - Número da frente não informado");
            return false;
        }
        
        const resposta = await sisobrasServer.put(`/frentes/medicao/${encodeURIComponent(dados.E_PPI)}/${dados['NúmeroFrente']}/${dados['NumeroMedição']}`, dados);
        
        validate('frente_medicao',resposta.data.errosValidacao,setValidationMessage);
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizarMedicoes',
            payload : resposta.data.dados[0]
        });
    },

    reset : dispatch => async() => {

        dispatch({
            type : 'reset'
        })

    }

}

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)