export const handleBlur = (formik) => {
    //setValidationMessage({ ...validationMessage, [e.target.name] : null});
    formik.handleSubmit();
}

export const handleChange = (name, value, formik, validationMessage = null, setValidationMessage = null) => {
    if(validationMessage && setValidationMessage) // REMOVE MENSAGEM DE ERRO DE VALIDAÇÃO
        setValidationMessage({ ...validationMessage, [name] : null});
        
    formik.setFieldValue(name, value);
}

export const handleSelectChange = (name, value, formik, validationMessage = null, setValidationMessage = null) => {
    if(validationMessage && setValidationMessage) // REMOVE MENSAGEM DE ERRO DE VALIDAÇÃO
        setValidationMessage({ ...validationMessage, [name] : null});

    formik.setFieldValue(name, value);
    formik.handleSubmit();
}

export const handleCheckedChange = (name, checked, formik, validationMessage = null, setValidationMessage = null) => {

    if(validationMessage && setValidationMessage) // REMOVE MENSAGEM DE ERRO DE VALIDAÇÃO
        setValidationMessage({ ...validationMessage, [name] : null});

    formik.setFieldValue(name, checked);
    formik.handleSubmit();
}