import React, { useState, useEffect, useContext } from 'react';
import { Loader, Header } from 'semantic-ui-react';
import { PDFViewer, Page, Document, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { useQuery } from '../helpers/useQuery';

import { Context as unidadeOrigemContext } from '../context/unidadeOrigemContext';
import { Context as contratosContext } from '../context/contratosContext';
import { Context as medicoesContext } from '../context/medicoesContext';
import { Context as cronogramasContext } from '../context/cronogramasContext';
import { Context as frentesContext } from '../context/frentesContext';

import RaoIndividual from './RaoIndividual';

import { utcToDate } from '../helpers/stringConvert';

import { base, rao } from './styles/styles';

import '../stylesRelatorio.css';
import logo from '../images/logo_preto_pequeno.png';

import {
    MUNICIPIOS,
    SITUACAO_CONTRATO,
    TIPO_FAC
} from '../helpers/dropdownNames';

import getOptionLabel from '../helpers/getOptionLabel';
import useOptions from '../hooks/useOptions';

const styles = StyleSheet.create({
    ...base,
    ...rao
});

export default () => {

    const options = useOptions([
        MUNICIPIOS,
        SITUACAO_CONTRATO,
        TIPO_FAC
    ]);
    
    const unidadeOrigem = useContext(unidadeOrigemContext);
    const contratos = useContext(contratosContext);
    const medicoes = useContext(medicoesContext);
    const cronogramas = useContext(cronogramasContext);
    const frentes = useContext(frentesContext);

    const [ medicoesFiltro, setMedicoesFiltro ] = useState([]);
    const [ status, setStatus ] = useState('carregando');
    const [ mensagem, setMensagem ] = useState('');
    const [ raoList, setRaoList ] = useState(null);
    const [ carregamentoFrentes, setCarregamentoFrentes] = useState(false);
    
    const query = useQuery();
    const filter = {};

    const E_PPI = decodeURIComponent(query.get('E_PPI'));
    const NumeroMedicao = decodeURIComponent(query.get('NumeroMedicao'));
    const Fechamento = query.get('Fechamento');
    
    // INICIA O CARREGAMENTO DOS DADOS DO DOCUMENTO
    useEffect( () => {
        const loadData = async () => {
            await unidadeOrigem.buscar();
            await contratos.listar();
            await medicoes.listar();
            await cronogramas.listar();
        }

        loadData();

    }, []);


    useEffect( () => {

        // FILTRA AS MEDIÇOES DE ACORDO COM AS OPÇÕES ENVIADAS NA QUERYSTRING
        if(medicoes.state.length > 0){
            let medicoesFiltro = [ ...medicoes.state ];
            
            if(Fechamento) {
                medicoesFiltro = medicoesFiltro.filter( item => Fechamento === utcToDate(item['Fechamento']));
            }else{
                if(E_PPI)
                    medicoesFiltro = medicoesFiltro.filter( item => String(E_PPI) === item['E_PPI']);
                if(NumeroMedicao) 
                    medicoesFiltro = medicoesFiltro.filter( item => Number(NumeroMedicao) === item['NumeroMedição']);
            }
            
            if(medicoesFiltro.length === 0){
                setMensagem('Nenhuma medição encontrada');
                setStatus('erro');
            }else{
                setMedicoesFiltro(medicoesFiltro);
            }
        }
        
    }, [medicoes.state]);


    useEffect( () => {
        
        const carregar = async () => {
            if(contratos.state.length > 0 && medicoesFiltro.length > 0){

                // CRIAR UM ARRAY COM TODOS OS CÓDIGOS DOS CONTRATOS DAS MEDICOES 
                const E_PPIs = [...new Set(medicoesFiltro.map( medicao => medicao['E_PPI']))];

                let count = 0;
                console.log(E_PPIs.length);
                const frentesPromises = E_PPIs.map( async E_PPI => {
                    console.log(E_PPIs.length);
                    await frentes.listarPorContrato(E_PPI);
                    return E_PPI;
                });
                
                await Promise.all(frentesPromises).then( result => {
                    setCarregamentoFrentes(true);
                });

            }
        }

        carregar();
        

    },[medicoesFiltro]);

    
    useEffect( () => {

        console.log(contratos.state.length+ " > 0 && "+ medicoesFiltro.length + " > 0 && "+ cronogramas.state.length+ " > 0 && "+ frentes.state.length + " > 0 && "+carregamentoFrentes);

        if(
            contratos.state.length > 0 && 
            medicoesFiltro.length > 0 &&
            cronogramas.state.length > 0 &&
            frentes.state.length > 0 &&
            carregamentoFrentes
        ){
            
            /*const medicoesFiltro = medicoes.state.filter( item => {
                return Object.entries(filter).reduce( (acc, curr) => {
                    if(filter[curr[0]] !== ''){
                        if(curr[0] === 'Fechamento')
                            item[curr[0]] = utcToDate(item[curr[0]]);

                        if(item[curr[0]] === filter[curr[0]])
                            return true;
                        else    
                            return false;
                    }else{
                        return acc;
                    }
                },false) 
            });*/
            
            setRaoList( medicoesFiltro.map( (medicaoFiltro, index) => {
    
                    let pageBreak = true;
        
                    if(index === 0)
                        pageBreak = false;
        
                    const contrato = contratos.state.find( contrato => contrato['E_PPI'] === medicaoFiltro['E_PPI']);
        
                    const medicao = medicoes.state.find( medicao => medicao['E_PPI'] === medicaoFiltro['E_PPI'] && medicao['NumeroMedição'] === medicaoFiltro['NumeroMedição']);
        
                    const cronograma = cronogramas.state.find( cronograma => cronograma['E_PPI'] === medicaoFiltro['E_PPI'] && cronograma['NumeroMedição'] === medicaoFiltro['NumeroMedição']);
        
                    const medicoesContrato = medicoes.state.filter( medicao => medicao['E_PPI'] === medicaoFiltro['E_PPI']);
        
                    const cronogramaContrato = cronogramas.state.filter( cronograma => cronograma['E_PPI'] === medicaoFiltro['E_PPI']);
        
                    const frentesContrato = frentes.state.filter( frente => frente['E_PPI'] === medicaoFiltro['E_PPI']);
                    
                    /*console.log(contrato);
                    console.log(medicao);
                    console.log(cronograma);
                    console.log(medicoesContrato);
                    console.log(cronogramaContrato);
                    console.log(frentesContrato);*/
                    console.log("GERANDO");
                    // VERIFICA SE TODOS OS DADOS FORAM CARREGADOS PARA GERAR O DOCUMENTO
                    if(contrato !== undefined &&
                        medicao !== undefined &&
                        cronograma !== undefined &&
                        frentesContrato.length > 0 &&
                        Object.keys(unidadeOrigem.state).length > 0)
                    {   
        
                        setStatus('finalizado');
                        
                        return <RaoIndividual key={contrato['E_PPI'] + '_' + medicao['NumeroMedição']} contrato={contrato} medicao={medicao} cronograma={cronograma} medicoes={medicoesContrato} cronogramas={cronogramaContrato} frentes={frentesContrato} pageBreak={pageBreak} options={options} />                
        
                    }else{
                        
                        setMensagem('Ocorreu um problema ao carregar o documento');
                        setStatus('erro');
        
                        return null;
        
                    }
                })
            );
        }


    }, [contratos.state, 
        medicoes.state,
        cronogramas.state,
        carregamentoFrentes]);
    
        
    if(status === 'carregando'){
        return(
            <Loader active={true}>Gerando Documento...</Loader>
        )


    }else if(status === 'erro'){
        return(
            <div className="mensagem-erro">
                <Header>{mensagem}</Header>
            </div>
        )


    }else{

        const now = new Date();
        const data = utcToDate(now.toISOString(),'br');

        return (
            <PDFViewer>
                <Document>
                    <Page size="A4" style={styles.page}>

                        {/* CABECALHO */}
                        <View style={styles.cabecalho} fixed>
                            <View>
                                <Image style={styles.logo} src={logo} />
                            </View>
                            <View style={styles.textoTitulo}>
                                <Text style={styles.titulo}>{unidadeOrigem.state['Descrição']} - {unidadeOrigem.state['UnidadeOrigem']}</Text>
                                <Text style={styles.titulo}>RELATÓRIO DE ACOMPANHAMENTO DE OBRAS</Text>
                            </View>
                        </View>

                        {raoList}

                        {/* RODAPÉ */}
                        <View style={styles.rodape} fixed>
                            <Text fixed>{data}</Text>
                            <Text style={styles.rodapePaginas} render={({ pageNumber, totalPages }) => (
                                `Página ${pageNumber} de ${totalPages}`
                            )} fixed></Text>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        );


    }
    

}