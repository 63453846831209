import React, { useContext, useEffect, useState } from 'react';
import history from '../history';
import { Icon, Button, Segment } from 'semantic-ui-react';
import SemanticGrid from './UI/SemanticGrid';

import '../styles.css';

import FormCarta from './ModalForms/FormCarta';

import { Context } from '../context/cartasContext';
import { Context as modalFormContext } from '../context/modalFormContext';
import { Context as confirmContext } from '../context/confirmContext';

import { utcToDate }  from '../helpers/stringConvert';

import Loading from './UI/Loading';

const ContratoMedicoes = ({ E_PPI }) => {

    const { state, listarPorContrato, novo, remover, reset } = useContext(Context);
    const modalForm = useContext(modalFormContext);
    const modalConfirm = useContext(confirmContext);
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        reset();
    }, []);

    useEffect( () => {
        listarPorContrato(E_PPI, setLoading);
    },[E_PPI]);


    const rowClick = cellInfo => history.push(`/carta/${encodeURIComponent(E_PPI)}/${encodeURIComponent(cellInfo.row.values['NúmeroCartaContratada'])}`);

    const columns = [
        {
            Header: 'Nº',
            accessor: 'NúmeroCartaContratada',
            width: '3'
        },
        {
            Header: 'Data',
            accessor: 'DataCarta',
            Cell : ({ row }) => <span>{utcToDate(row.values.DataCarta, 'br')}</span>
        },
        {
            Header: 'Informações',
            accessor: 'Informações',
            width: '4'
        },
        {
            Header: 'Data Encaminhamento',
            accessor: 'DataEncaminhamentoCarta',
            Cell : ({ row }) => <span>{utcToDate(row.values.DataEncaminhamentoCarta, 'br')}</span>
        },
        {
            Header: 'Destino',
            accessor: 'DestinoCarta'
        },
        {
            Header: '',
            accessor: 'remove',
            Cell : ({row}) => <Button basic icon onClick={() => confirmDelete(row.original.E_PPI, row.values['NúmeroCartaContratada'])}><Icon name="trash" /></Button>,
            width: '1'
        }
    ];
     
 
    /*const filter = {
        name: 'users_filter',
        items: [
            {
                name: 'first_name',
                type: 'text',
                label: 'First Name'
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Last Name'
            },
            {
                name: 'email',
                type: 'text',
                label: 'Email'
            },
            {
                name: 'country',
                type: 'text',
                label: 'Country'
            }
        ]
    };*/


    // MODAL COM FORMUÁRIO DE NOVO REGISTRO
    const modalAdicionar = () => {

        modalForm.open();
        modalForm.setHeader('Adicionar Carta');
        modalForm.setForm(<FormCarta onCancel={modalForm.close} onSave={novo} E_PPI={E_PPI} />);

    }


    // CONFIRMAÇAO PARA REMOVER CARTA
    const confirmDelete = (E_PPI, NumeroCartaContratada) => {
        
        modalConfirm.setProp({
            onConfirm : () => remover(E_PPI, NumeroCartaContratada),
            content : `Tem certeza que deseja remover a carta ${NumeroCartaContratada}?`
        });
        modalConfirm.open();

    }

    let lista = null;

    if(state.length > 0){

        lista = <SemanticGrid
                    data={state}
                    columns={columns}
                    getCellProps={cellInfo => ({
                        onClick : () => cellInfo.column.Header !== '' ? rowClick(cellInfo) : () => {}
                    })}
                />

    }else if(loading === false){
        lista = <Segment as="h2" basic textAlign="center" content="Nenhuma carta encontrada." />;
    }

    return(
        <div className="table-area">
            <Loading active={loading} />
            <div className="table-options-area">
                <Button onClick={() => modalAdicionar()} icon><Icon name="plus" /> Adicionar Cartas</Button>
            </div>

            {lista}

        </div>

    );

}

export default ContratoMedicoes;