import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';

const reducer = (state, action) => {

    switch(action.type){

        case 'buscar':
            return action.payload;

        default:
            return state;

    }
}

const actions = {

    buscar : dispatch => async () => {
        
        const resposta = await sisobrasServer.get('/unidade-origem');
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    }

}

const defaultState = {};

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)