import createDataContext from './createDataContext';


const setCookie = (cname, cvalue, exdays = 1) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


const reducer = (state, action) => {

    switch(action.type){
        
        case 'login':
            return true;    

        case 'logout':
            return false
        
        default:
            return state;

    }
}

const actions = {

    login : dispatch => async (pass) => {

       if(pass === 'S1sT3ste123'){
            setCookie('logged', 'true', 1);
            dispatch({
                type : 'login'
            });
        }
        
    },

    logout : dispatch => async () => {
        
        setCookie('logged', false);
        
        dispatch({
            type : 'logout'
        });
         
         
     },
 

}

const defaultState = false;

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)