import React, { useState, useContext, useEffect } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import history from '../../history';

import validationSchema from '../../validationSchema';

import CustomDropdown from '../UI/CustomDropdown';

import useOptions from '../../hooks/useOptions';

import { Context as unidadeOrigemContext } from '../../context/unidadeOrigemContext';

import { handleChange } from '../../helpers/handleForm';
import {
    DT_MEDICAO,
    TIPO_CLASSIFICACAO
} from '../../helpers/dropdownNames';

const FormCarta = ({ onSave, onCancel}) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.contrato);

    const unidadeOrigem = useContext(unidadeOrigemContext);

    const options = useOptions([
        DT_MEDICAO,
        TIPO_CLASSIFICACAO
    ]);

    const formik = useFormik({
        initialValues : {
            'E_PPI' : '',
            'E_OB_AS' : '',
            'OBJETO_OF' : '',
            'DT_MEDICAO' : '',
            'Classificação' : '',
            'E_OB_PRAZO' : '',
            'E_OB_VALOR' : '',
            'UnidadeOrigemExportar' : unidadeOrigem['Unidade']
        },

        onSubmit : (values, { resetForm }) => {
            onSave(values, setValidationMessage, () => {
                onCancel();
                history.push(`/contrato/${encodeURIComponent(values.E_PPI)}`);
            });
        }

    });

    return(
        <Segment basic>
            <Form widths='equal'>
                <Form.Input name="E_PPI" value={formik.values['E_PPI']} error={validationMessage['E_PPI']} onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} label="Código do Contrato" />
                <Form.TextArea name="OBJETO_OF" value={formik.values['OBJETO_OF']} error={validationMessage['OBJETO_OF']} label="Objeto"  onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} />                          
                <Form.Group>
                    <Form.Input name="E_OB_AS" value={formik.values.E_OB_AS} error={validationMessage['E_OB_AS']} onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} label="Data da AS" type="date" />
                    <Form.Input name="E_OB_PRAZO" value={formik.values.E_OB_PRAZO} error={validationMessage['E_OB_PRAZO']} onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} label="Prazo" />
                    <CustomDropdown
                        initialOptions={options[DT_MEDICAO]}
                        name="DT_MEDICAO"
                        label="Dia de Medição"
                        value={formik.values.DT_MEDICAO}
                        onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)}
                        search={true}
                        canAdd={true}
                        error={validationMessage['DT_MEDICAO']}
                    />
                </Form.Group>
                <Form.Group>
                    <CustomDropdown
                        initialOptions={options[TIPO_CLASSIFICACAO]}
                        name="Classificação"
                        label="Classificação"
                        value={formik.values['Classificação']}
                        onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)}
                        search={true}
                        error={validationMessage['Classificação']}
                    />
                    <Form.Input name="E_OB_VALOR" value={formik.values.E_OB_VALOR} error={validationMessage['E_OB_VALOR']} onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} label="Valor Inicial" />
                </Form.Group>
                <Button primary onClick={ formik.handleSubmit }>Salvar</Button>
                <Button secondary onClick={ onCancel }>Cancelar</Button>
            </Form>
        </Segment>
    )
}

export default FormCarta;