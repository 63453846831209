import React from 'react';
import { useParams } from 'react-router-dom';

import RaoIndividual from './relatorios/RaoIndividual';
import Rao from './relatorios/Rao';

const RenderDocument = () => {

    let myDocument = null;    
    const { tituloRelatorio } = useParams();

    switch(tituloRelatorio){

        case 'raoIndividual':
            myDocument = <RaoIndividual />;
        break;

        case 'rao':
            myDocument = <Rao />;
        break;

        default:
            
        break;
    }

    if(myDocument){

        return myDocument;

    }else{
        return(
            <div style={{
                width : '100vw',
                height : '100vh',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center'
            }}><strong>Nenhum Documento Para Exibição</strong></div>
        );
    }
}

export default RenderDocument;