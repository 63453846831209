import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import validate from '../helpers/validate';
import { convertToCurrency } from '../helpers/stringConvert';
import validationSchema from '../validationSchema';

const defaultState = [];

const reducer = (state, action) => {

    let newState = [];

    //CONVERTE VALORES MONETÁRIOS
    console.log(action.payload);
    action.payload = convertToCurrency(action.payload);
    console.log(action.payload);

    switch(action.type){
        
        case 'listarPorContrato':
            return action.payload;

        case 'listarPorMedicao':
            return action.payload;

        case 'buscar':
            newState = updateState(state, action.payload, 'id');
            return newState;

        case 'atualizar':
            newState = updateState(state, action.payload, 'id');
            return newState;
        
        case 'novo':
            return [action.payload, ...state];

        case 'remover':            
            return state.filter( item => item.id !== Number(action.payload));

        case 'reset':
            return defaultState;

        default:
            return state;

    }
}

const actions = {
    
    listarPorContrato : dispatch => async (E_PPI, query = null) => {

        const resposta = await sisobrasServer.get('/frentes-medicao/contrato/' + encodeURIComponent(E_PPI), {params : query});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'listarPorContrato',
            payload : resposta.data.dados
        });
    },

    listarPorMedicao : dispatch => async (E_PPI, NumeroMedicao, query = null) => {

        const resposta = await sisobrasServer.get('/frentes-medicao/medicao/' + encodeURIComponent(E_PPI) + '/' + encodeURIComponent(NumeroMedicao) , {params : query});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'listarPorMedicao',
            payload : resposta.data.dados
        });
    },

    atualizar : dispatch => async (dados = null, setValidationMessage) => {

        const id = dados.id;
        
        if(!id){
            console.error("Atualizar Cronograma - ID da medição não informado");
            return false;
        } 

        const resposta = await sisobrasServer.put('/cronogramas/'+id, dados);

        if(resposta.data.errosValidacao){

            const validationMessage = {};
            const errosValidacao = resposta.data.errosValidacao;

            Object.keys(validationSchema.previsto_acumulado).forEach( field => {
                validationMessage[field+id] = errosValidacao.reduce( (acc, curr) => field === curr.param ? curr.msg : acc, null);
            });
            
            setValidationMessage(validationMessage);

        }
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizar',
            payload : resposta.data.dados[0]
        });
    },


    novo : dispatch => async ( dados = null, setValidationMessage = null, closeModal = null) => {

        if(!dados){
            console.error("Erro ao cadastrar medição -> Dados incompletos");
            return false;
        } 

        const resposta = await sisobrasServer.post('/cronogramas', dados);
        
        validate('cronograma',resposta.data.errosValidacao,setValidationMessage);

        // FECHA A MODAL DO FORMULÁRIO CASO EXISTA E NÃO TENHA OCORRIDO ERROS DE VALIDAÇÃO
        if(closeModal && resposta.data.errosValidacao === undefined)
            closeModal();

        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'novo',
            payload : resposta.data.dados[0]
        });

    },


    remover : dispatch => async (id) => {

        if(!id){
            console.error("Erro ao remover medição");
            return false;
        } 

        const data = {id};

        const resposta = await sisobrasServer.delete('/cronogramas/', { data });
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'remover',
            payload : resposta.data.dados.id
        });
    },

    reset : dispatch => async() => {

        dispatch({
            type : 'reset'
        })

    }

}

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)