import React, { useContext } from 'react';
import _ from 'lodash';
import { Icon, Button } from 'semantic-ui-react';

import SemanticGrid from './UI/SemanticGrid';
import { Context as modalFormContext } from "../context/modalFormContext";
import FormMaterial from './ModalForms/FormMaterial';
 
let data = [];

_.times(15, () => data = [...data,
    {
        material : 'asd asdas das asds asdasdds odsk woke owkewoekwoek wpelrpe',
        diametro : "122",
        unidade : 'M',
        quant_prevista : '294',
        quant_executada : '124',
    }
]);


const rowClick = cellInfo => {};

const columns = [
    {
        Header: 'Material',
        accessor: 'material'
    },
    {
        Header: 'Diâmetro',
        accessor: 'diametro'
    },
    {
        Header: 'Unidade',
        accessor: 'unidade'
    },
    {
        Header: 'Quant. Prevista',
        accessor: 'quant_prevista'
    },
    {
        Header: 'Quant. Executada',
        accessor: 'quant_executada'
    },
    {
        Header: '',
        accessor: 'remove',
        Cell : ({row}) => <Button basic icon onClick={() => alert('delete')}><Icon name="trash" size /></Button>,
    }
];
 
const ContratoDados = () => {

    const modalForm = useContext(modalFormContext);

    /*const filter = {
        name: 'users_filter',
        items: [
            {
                name: 'first_name',
                type: 'text',
                label: 'First Name'
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Last Name'
            },
            {
                name: 'email',
                type: 'text',
                label: 'Email'
            },
            {
                name: 'country',
                type: 'text',
                label: 'Country'
            }
        ]
    };*/

    const modalAdicionarMaterial = () => {
        modalForm.open();
        modalForm.setHeader('Adicionar Material');
        modalForm.setForm(<FormMaterial onCancel={modalForm.close} />);
    }


    return(
        <div className="table-area">

            <div className="table-options-area">
                <Button onClick={modalAdicionarMaterial} icon><Icon name="plus" /> Adicionar Material</Button>
            </div>

            <SemanticGrid
                data={data}
                columns={columns}
                getCellProps={cellInfo => ({
                    onClick : () => cellInfo.column.Header !== '' ? rowClick(cellInfo) : () => {}
                })}
            />
            
        </div>

    );

}

export default ContratoDados;