import React from 'react';
import { Segment, Loader, Dimmer, Header } from 'semantic-ui-react';
import ContratosItem from './ContratosItem';

const ContratosLista = ({ obras, loading, quantidade }) => {


    const renderizarListaContratos = (dados) => dados.map((item, index) => {
        if(index + 1 >= quantidade)
            return false;
        else
            return <ContratosItem dados={item} key={item.E_PPI} />
    });
    
    const listaContratos = obras.length > 0 ? renderizarListaContratos(obras) : <Segment as="h3" textAlign="center" content="Nenhum resultado encontrado." />;

    return (
        <>
        <Dimmer inverted active={loading} style={{position:'sticky'}}>
            <Loader />
        </Dimmer>
        <Segment.Group>
            {listaContratos}
        </Segment.Group>
        </>
        
        
    );
}

export default ContratosLista;