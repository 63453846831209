import React, { useState, useContext, useEffect } from 'react';
import { Segment, Form, Button, Header} from 'semantic-ui-react';

import { Context } from './context/authContext';

import logo from './images/logo_cor_pequeno.png';

import './styles.css';

export default () => {

    const [ password, setPassword ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ clicked, setClicked ] = useState(false);
    //const [ color, setColor ] = useState(null);

    const { state, login } = useContext( Context );

    const checkPassword = () => {
        login(password);
        setClicked(true);
    }

    useEffect( () => {
        
        if(!state && clicked)
            setError('Senha Inválida');
        else
            setError(null);

    },[state, clicked])

    return(
        <div className="login-main">
            <Segment className="login-box">
                <img alt="logo" src={logo} style={{width : '50px'}} />
                <Header textAlign="center" color="blue">
                    Sistema de Gestão de Obras - SISOBRAS
                    <Header.Subheader>VERSÃO DE TESTES</Header.Subheader>
                </Header>            
                <Form style={{width : '100%'}}>
                    <Form.Input name="password" value={password} error={error} onChange={(e, {name, value}) => setPassword(value)} label="Digite a Senha:" type="password" />
                </Form>                
                <Segment compact basic textAlign='center'>
                    <Button as='button' onClick={checkPassword} compact primary>Entrar</Button>
                </Segment>
            </Segment>
        </div>
    );

}