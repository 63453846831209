import currencyFields from './currencyFields';

// CONVERTE FORMATO UTC PARA DATA
export const utcToDate = (utc, formato = 'eu') => {

    if(!utc) return '';

    const regexDate = new RegExp(/^\d{4}-(\d{2}|\d{1})-(\d{2}|\d{1})$/);
    if(regexDate.test(utc))
        return utc;

    const regexUtc = new RegExp(/^\d{4}-\d{2}-\d{2}T/);
    if(!regexUtc.test(utc)){
        console.error('UTC inválido para conversão => ' + utc);
        return null;
    }
    
    let data = utc.split('T');
    
    if(formato === 'eu'){
        return data[0];
    }else{
        const elementos = data[0].split('-');
        return `${elementos[2]}/${elementos[1]}/${elementos[0]}`;
    }

}

// SUBSTITUI CARACTERES ACENTUADOS, REMOVE CARACTERES ESPECIAIS E ESPAÇOS PARA USO COMO CHAVE
export const convertToKey = (string) => {
    return string.normalize("NFD").replace(/[^a-zA-Z0-9s]/g, "");
}

export const convertToCurrency = (value, separator = false, decimals = 2) => {
    
    if(isNaN(value) || value === null || value === undefined) return value;

    const setSeparator = (value) => {
        if(separator)
            return value;
        else
            return value.replace(/\./g,'');
    }
    
    if(Array.isArray(value)){ //SE FOR UM ARRAY
        return value.map( item => convertToCurrency(item) );
    }
    
    else if(typeof(value) === 'object'){ //SE FOR UM OBJETO
        const newObj = {};
        Object.entries(value).forEach( item => {
            if(!item[1] || currencyFields.indexOf(item[0]) === -1){
                newObj[item[0]] = setSeparator(Number(item[1]));
            }else{
                newObj[item[0]] = setSeparator(Number(item[1]).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits : decimals, minimumFractionDigits : decimals }));
            }
        });
        return newObj;
    }

    else{
        //return setSeparator(Number(value).toLocaleString('pt-BR', { minimumFractionDigits : decimals}));
        return setSeparator(Number(value).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits : decimals, minimumFractionDigits : decimals }));

    }

}


export const convertToMesAno = ( Fechamento ) => {

    Fechamento = utcToDate(Fechamento);
    
    if(Fechamento.indexOf("/") < 0){
        const elements = Fechamento.split('-');
        return elements[1] + "/" + elements[0];
    }else{
        const elements = Fechamento.split('/');
        return elements[1] + "/" + elements[2];
    }
    
}