import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { base, rao } from './styles/styles';

import RaoResumoObra from './RaoResumoObra';
import RaoCronogramaFisico from './RaoCronogramaFisico';
import RaoFrentes from './RaoFrentes';
import RaoCronogramaEconomico from './RaoCronogramaEconomico';

import {
    MUNICIPIOS,
    SITUACAO_CONTRATO,
    TIPO_FAC
} from '../helpers/dropdownNames';

import getOptionLabel from '../helpers/getOptionLabel';
// import useOptions from '../hooks/useOptions'; // NÃO FUNCIONA E NÃO SEI PORQUE. ACABEI IMPORTANDO COMPONENTE DO OBJETO PAI

import { utcToDate, convertToCurrency } from '../helpers/stringConvert';

const styles = StyleSheet.create({
    ...base,
    ...rao
});

export default ({contrato, medicao, cronograma, medicoes, cronogramas, frentes, pageBreak, options}) => {
    
    /*
    NÃO FUNCIONA E NÃO SEI PORQUE. ACABEI IMPORTANDO OPTIONS DO COMPONENTE PAI
    const options = useOptions([
        MUNICIPIOS,
        SITUACAO_CONTRATO,
        TIPO_FAC
    ]);*/ 
    
    const mesAno = cronograma['MesAno'].split('/');
    const anos = ([mesAno[1]]);
    
    return(
        <>

            {/* SEÇÃO 01*/}
            <View style={[styles.bloco, { flexDirection : 'row'}]} break={pageBreak}>
                <View style={{flexDirection : 'column'}}>
                    <View style={{flexDirection : 'row'}}>
                        <View style={[styles.dados, { width : '170px'}]}>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Municipio: </Text>
                                <Text style={styles.value}>{getOptionLabel(options, MUNICIPIOS, contrato['E_MUNIC'])}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Contrato</Text>
                                <Text style={[styles.value, { fontSize : '14px'}]}>{contrato['E_PPI']}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Unidade</Text>
                                <Text style={styles.value}>{contrato['E_OB_RESP']}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Situação</Text>
                                <Text style={styles.value}>{getOptionLabel(options, SITUACAO_CONTRATO, contrato['E_OB_SIT'])}</Text>
                            </View>
                        </View>
                        <View style={styles.dados}>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Início</Text>
                                <Text style={styles.value}>{utcToDate(contrato['E_OB_AS'],'br')}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Prazo Inicial</Text>
                                <Text style={styles.value}>{contrato['E_OB_PRAZO']} dia(s)</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Prazo Atual</Text>
                                <Text style={styles.value}>{contrato['PRAZO_AT']} dia(s)</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Término</Text>
                                <Text style={styles.value}>{utcToDate(contrato['E_OB_TERM'],'BR')}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Término em Vigor</Text>
                                <Text style={styles.value}>{/*utcToDate(contrato['TERMINO_AT'],'BR')*/}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={[styles.label, styles.labelWidth01]}>Término Previsto</Text>
                                <Text style={styles.value}>{utcToDate(contrato['TERMINO_AT'],'BR')}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.dados}>
                        <View style={styles.dado}>
                            <Text>{contrato['E_OB_SIT_Obs']}</Text>
                        </View>
                        <View style={styles.dado}>
                            <Text style={[styles.label, styles.labelWidth01]}>Empresa</Text>
                            <Text style={{textTransform : 'uppercase'}}>{contrato['E_OB_EMPR']}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ borderLeft : '1px solid left', flexDirection : 'column', justifyContent : 'space-between'}}>
                    <View style={[styles.dados, { flexGrow : 1}]}>
                        <View style={styles.dado}>
                            <Text style={[styles.label, styles.labelWidth02]}>Valor Contratado: </Text>
                            <Text>{convertToCurrency(contrato['E_OB_VALOR'],'.')}</Text>
                        </View>
                        <View style={styles.dado}>
                            <Text style={[styles.label, styles.labelWidth02]}>Valor Atual:</Text>
                            <Text>{convertToCurrency(contrato['VALOR_AT'],'.')}</Text>
                        </View>
                        <View style={styles.dado}>
                            <Text style={[styles.label, styles.labelWidth02]}>Saldo</Text>
                            <Text></Text>
                        </View>
                    </View>
                    <View>
                        <View style={styles.dado}>
                            <View style={[styles.dado, { width:'80px'}]}>
                                <Text style={styles.label}>I0: </Text>
                                <Text style={{fontWeight:'light'}}>{contrato['E_OB_IO']}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={styles.label}>Dia de Medição: </Text>
                                <Text>{contrato['DT_MEDICAO']}</Text>
                            </View>
                        </View>
                        <View style={styles.dado}>
                            <View style={[styles.dado, { width:'110px'}]}>
                                <Text style={styles.label}>Nº da Medição: </Text>
                                <Text>{medicao['NumeroMedição']}</Text>
                            </View>
                            <View style={styles.dado}>
                                <Text style={{fontSize : '14px', paddingBottom : '5px'}}>{cronograma['MesAno']}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>


            {/* INDICE REAL CONTR */}
            <View style={[styles.blocoMenor, { flexDirection : 'row'}]}>
                <View style={{ flexDirection : 'column', padding : '2px'}}>
                    <Text>Ind. Real Contr.</Text>
                    <Text>Fac: {contrato['ClausulaContratualFac'] ? 'SIM' : 'NÃO'} </Text>
                    <Text>{getOptionLabel(options, TIPO_FAC, contrato['E_OB_tipFac'])}</Text>
                </View>
                <View style={{flexDirection: 'row', flexGrow : 1, flexWrap : 'wrap'}}>
                    <Text></Text>
                    {/*<View style={styles.dado}>
                        <Text style={styles.label}>0,01200000 </Text>
                        <Text> Fator 01</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>0,01200000 </Text>
                        <Text> Fator 01</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>0,01200000 </Text>
                        <Text> Fator 01</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>0,01200000 </Text>
                        <Text> Fator 01</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>0,01200000 </Text>
                        <Text> Fator 01</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>0,01200000 </Text>
                        <Text> Fator 01</Text>
</View>*/}
                </View>
            </View>


            {/* ALTERACOES CONTRATUAIS */}
            <View style={styles.blocoMenor}>
                <Text style={styles.titulo2}>Alteraçoes Contratuais:</Text>
                <Text></Text>
            </View>


            {/* OBJETO */}
            <View style={styles.blocoMenor}>
                <Text style={styles.titulo2}>Objeto:</Text>
                <Text>{contrato['OBJETO_OF']}</Text>
            </View>

            {/* beneficios */}
            <View style={styles.blocoMenor}>
                <Text style={styles.titulo2}>Benefícios:</Text>
                <Text>{contrato['Benefícios']}</Text>
            </View>

            {/* OBSERVAÇÕES */}
            <View style={styles.blocoMenor}>
                <Text style={styles.titulo2}>Observações:</Text>
                <Text></Text>
            </View>


            {/* EQUIPE E FINANCIAMENTO */}
            <View style={[styles.bloco, { flexDirection : 'row'}]}>
                <View style={{width : '300px'}}>
                    <View style={styles.titulo3}>
                        <Text>Equipe Técnica</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={[styles.label, styles.labelWidth02]}>Adm. do Contrato:</Text>
                        <Text style={{textTransform : 'uppercase'}}>{contrato['ADM']}</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={[styles.label, styles.labelWidth02]}>Setor/Gerência/Gestão:</Text>
                        <Text style={{textTransform : 'uppercase'}}></Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={[styles.label, styles.labelWidth02]}>Eng. Fiscal:</Text>
                        <Text style={{textTransform : 'uppercase'}}>{contrato['FISCAL_ADM']}</Text>
                    </View>
                </View>
                <View style={{flexGrow : 1}}>
                    <View style={styles.titulo3}>
                        <Text>Financiamento</Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>Programa:</Text>
                        <Text style={{textTransform : 'uppercase'}}></Text>
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>Fonte de Recurso:</Text>
                        <Text style={{textTransform : 'uppercase'}}>{contrato['Recurso']}{/* verificar ambiguidade com campo E_OB_FonteRecurso */}</Text> 
                    </View>
                    <View style={styles.dado}>
                        <Text style={styles.label}>Gerenc.:</Text>
                        <Text style={{textTransform : 'uppercase'}}></Text>
                    </View>
                </View>
            </View>



            {/* EMPREENDIMENTO */}
            <View style={[styles.blocoMenor, { flexDirection : 'column', height : '180px'}]}>
                <View style={[styles.dado, {justifyContent : 'flex-start'}]}>
                    <Text style={styles.label}>Nome do Empreendimento: </Text>
                    <Text style={{textTransform : 'uppercase'}}> {contrato['ObjetoRAO']}</Text>
                </View>
                <View style={{display : 'flex', flexDirection : 'row', justifyContent : 'space-evenly'}}>
                    <View style={{flexDirection : 'column'}}>
                        <View style={[styles.dado, {paddingVertical : '10px', justifyContent : 'flex-start'}]}>
                            <Text style={styles.label}>Projetista: </Text>
                            <Text style={{textTransform : 'uppercase'}}> {contrato['Projetista']}</Text>
                        </View>
                        <View>
                            <RaoResumoObra />
                        </View>
                        
                    </View>
                    <View style={{flexDirection : 'column'}}>
                        <View style={[styles.dado, {paddingVertical : '10px', justifyContent : 'flex-start'}]}>
                            <Text style={styles.label}>Responsável: </Text>
                            <Text style={{textTransform : 'uppercase'}}> {contrato['UnidProjeto']}</Text>
                        </View>
                        <View>
                            <View>
                                <Text>Dados do Sistema: (Descritivo, mencionando contratos remanescentes e/ou complementares</Text>
                                <Text>{contrato['ResumoObra']}</Text>
                            </View>
                        </View>
                        
                    </View>
                </View>
            </View>

            {/* CRONOGRAMA FÍSICO */}
            <View style={styles.blocoMenor}>
                <RaoCronogramaFisico cronograma={cronogramas} anos={anos} />
            </View>



            {/* FRENTES */}
            <View style={styles.blocoMenor} break>
                <RaoFrentes 
                    frentes={frentes} 
                    NumeroMedicao={medicao['NumeroMedição']} 
                    cronograma={cronograma}
                    valorContrato={contrato['E_OB_VALOR']} 
                />
            </View>


            {/* CRONOGRAMA ECONÔMICO */}
            <View style={styles.blocoMenor}>
                <RaoCronogramaEconomico cronograma={cronogramas} medicoes={medicoes} anos={anos} />
            </View>


        </>
    );


}

