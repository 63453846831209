export default (utc, formato = 'eu') => {

    if(!utc) return '';

    const regex = new RegExp(/^\d{4}-\d{2}-\d{2}T/);
    if(!regex.test(utc))
        console.error('UTC inválido para conversão');
    
    let data = utc.split('T');
    
    if(formato === 'eu'){
        return data[0];
    }else{
        const elementos = data[0].split('-');
        return `${elementos[2]}/${elementos[1]}/${elementos[0]}`;
    }

}