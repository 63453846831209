import React, { useContext, useEffect, useState } from 'react';
import history from '../history';
import { Icon, Button, Segment } from 'semantic-ui-react';
import SemanticGrid from './UI/SemanticGrid';

import '../styles.css';

import FormFrente from './ModalForms/FormFrente';

import { convertToCurrency } from '../helpers/stringConvert';

import { Context } from '../context/frentesContext';
import { Context as modalFormContext } from '../context/modalFormContext';
import { Context as confirmContext } from '../context/confirmContext';

import Loading from './UI/Loading';


const ContratoFrentes = ({ E_PPI }) => {

    const { state, listarPorContrato, novo, remover, reset } = useContext(Context);
    const modalForm = useContext(modalFormContext);
    const modalConfirm = useContext(confirmContext);
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        reset();
    }, []);

    useEffect( () => {
        listarPorContrato(E_PPI, setLoading);
    },[E_PPI]);


    const rowClick = cellInfo => history.push(`/frente/${encodeURIComponent(E_PPI)}/${encodeURIComponent(cellInfo.row.values['NúmeroFrente'])}`);

    // MODAL COM FORMUÁRIO DE NOVO REGISTRO
    const modalAdicionar = () => {

        modalForm.open();
        modalForm.setHeader('Adicionar Frente');
        modalForm.setForm(<FormFrente onCancel={modalForm.close} onSave={novo} E_PPI={E_PPI} />);

    }


    // CONFIRMAÇAO PARA REMOVER CARTA
    const confirmDelete = (E_PPI, NumeroFrente) => {
        
        modalConfirm.setProp({
            onConfirm : () => remover(E_PPI, NumeroFrente),
            content : `Tem certeza que deseja remover a frente ${NumeroFrente}?`
        });
        modalConfirm.open();

    }

    const columns = [
        {
            Header: 'Nº',
            accessor: 'NúmeroFrente',
        },
        {
            Header: 'Frente',
            accessor: 'Frente',
        },
        {
            Header: 'Un.',
            accessor: 'UnidadeMedida',
        },
        {
            Header: 'Qnt.',
            accessor: 'Quantidade',
            Cell : ({row}) => <span>{convertToCurrency(row.values['Quantidade'], '.', 1)}</span>
        },
        {
            Header: 'Valor',
            accessor: 'Valor',
            Cell : ({row}) => <span>{convertToCurrency(row.values['Valor'], '.', 2)}</span>
        },
        {
            Header: 'Peso',
            accessor: 'Peso',
            Cell : ({row}) => <span>{convertToCurrency(row.values['Peso'], '.', 2)}</span>
        },
        {
            Header: 'Peso s/ Arred.',
            accessor: 'PesoVerifica',
            Cell : ({row}) => <span>{convertToCurrency(row.values['PesoVerifica'], '.', 5)}</span>
        },
        {
            Header: '',
            accessor: 'remove',
            Cell : ({row}) => <Button basic icon onClick={() => confirmDelete(row.original.E_PPI, row.values['NúmeroFrente'])}><Icon name="trash" /></Button>,
            width: '1'
        }
    ];

    let lista = null;

    if(state.length > 0){

        lista = <SemanticGrid
                    data={state}
                    columns={columns}
                    getCellProps={cellInfo => ({
                        onClick : () => cellInfo.column.Header !== '' ? rowClick(cellInfo) : () => {}
                    })}
                />

    }else if(loading === false){
        lista = <Segment as="h2" basic textAlign="center" content="Nenhuma frente encontrada." />;
    }

    return(
        <div className="table-area">
            <Loading active={loading} />
            <div className="table-options-area">
                <Button onClick={() => modalAdicionar()} icon><Icon name="plus" /> Adicionar Frente</Button>
            </div>

            {lista}

        </div>

    );

}

export default ContratoFrentes;