import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { base } from './styles/styles';

const tableColsWidth = {
    col1 : { width : '50px'},
    col2 : { width : '50px'},
    col3 : { width : '50px'},
}

const styles = StyleSheet.create({
    ...base,
    ...tableColsWidth
});



export default () => {

    return(
        <View>
            {/*<View style={styles.tableHeader}>
                <View style={[styles.tableHeaderCell, styles.col1]}>
                    <Text>Diâmetro</Text>
                </View>
                <View style={[styles.tableHeaderCell, styles.col2]}>
                    <Text>LR</Text>
                </View>
                <View style={[styles.tableHeaderCell, styles.col3]}>
                    <Text>RC</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={[styles.tableRowCell, styles.col1]}>
                    <Text>100</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col2]}>
                    <Text>430</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col3]}>
                    <Text>16424,50</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={[styles.tableRowCell, styles.col1]}>
                    <Text>100</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col2]}>
                    <Text>430</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col3]}>
                    <Text>16424,50</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={[styles.tableRowCell, styles.col1]}>
                    <Text>100</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col2]}>
                    <Text>430</Text>
                </View>
                <View style={[styles.tableRowCell, styles.col3]}>
                    <Text>16424,50</Text>
                </View>
    </View>*/}
        </View>
    );

}