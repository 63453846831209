import validationSchema from '../validationSchema';

export default (validationSchemaName, data, setValidationMessage) => {
    if(data){

        const validationMessage = {};
        const errosValidacao = data;

        Object.keys(validationSchema[validationSchemaName]).forEach( field => {
            validationMessage[field] = errosValidacao.reduce( (acc, curr) => field === curr.param ? curr.msg : acc, null);
        });
        
        setValidationMessage(validationMessage);

    }
}