import React, { useState, useContext } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import history from '../../history';

import {handleChange, handleSelectChange, handleCheckedChange} from '../../helpers/handleForm';
import { utcToDate } from '../../helpers/stringConvert';

import validationSchema from '../../validationSchema';

import CustomDropdown from '../UI/CustomDropdown';

import { Context as unidadeOrigemContext } from '../../context/unidadeOrigemContext';

import {
    UNIDADE
} from '../../helpers/dropdownNames';
import useOptions from '../../hooks/useOptions';

const FormCartaSolicitacao = ({ onSave, onCancel, E_PPI, NumeroCartaContratada }) => {

    const unidadeOrigem = useContext(unidadeOrigemContext);

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.carta_ci);

    const options = useOptions([
        UNIDADE     
    ]);
    
    const formik = useFormik({
        initialValues : {
            'NúmeroCI' : '',
            'DataEncaminhamentoCI' : null,
            'DestinoCI' : '',
            'Termo' : '',
            'DataAssinaturaTermo' : null,
            'RetornoDossiê' : null,
            'RecebidoTribunalContas' : false
            
        },
    
        onSubmit : (values, { resetForm }) => {
            values.UnidadeOrigemExportar = unidadeOrigem.state.UnidadeOrigemExportar;
            values.E_PPI = E_PPI;
            values['NúmeroCartaContratada'] = NumeroCartaContratada;
            onSave(values, setValidationMessage, onCancel);
        }
    
    });
    
    return(
        <Segment basic>
            <Form widths="equal">
                <Form.Group>
                    <Form.Input name="NúmeroCI" value={formik.values['NúmeroCI']} onChange={(e, {name,value}) => handleChange(name,value, formik, validationMessage, setValidationMessage)} label="Número da CI" error={validationMessage['NúmeroCI']} />
                    <Form.Input name="DataEncaminhamentoCI" value={utcToDate(formik.values.DataEncaminhamentoCI)} onChange={(e, {name,value}) => handleChange(name,value, formik, validationMessage, setValidationMessage, validationMessage, setValidationMessage)} label="Data Encaminhamento" type="date" error={validationMessage['DataEncaminhamentoCI']} />
                    <CustomDropdown
                            initialOptions={options[UNIDADE]}
                            name="DestinoCI"
                            label="Destino"
                            value={formik.values['DestinoCI']}
                            onChange={(e, {name,value}) => handleChange(name,value, formik, validationMessage, setValidationMessage)}
                            search={true}
                            error={validationMessage['DestinoCI']}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input name="Termo" value={formik.values['Termo']} onChange={(e, {name,value}) => handleChange(name,value, formik, validationMessage, setValidationMessage)} label="Termo" />
                    <Form.Input name="DataAssinaturaTermo" value={utcToDate(formik.values.DataAssinaturaTermo)} onChange={(e, {name,value}) => handleChange(name,value, formik, validationMessage, setValidationMessage)} label="Data Assinatura" type="date" error={validationMessage['DataAssinaturaTermo']} />
                    <Form.Input name="RetornoDossiê" value={utcToDate(formik.values['RetornoDossiê'])} onChange={(e, {name,value}) => handleChange(name,value, formik, validationMessage, setValidationMessage)} label="Retorno Dossiê" type="date" error={validationMessage['RetornoDossiê']} />
                </Form.Group>
                <Form.Checkbox name="RecebidoTribunalContas" checked={formik.values.RecebidoTribunalContas} onChange={(e, {name,checked}) => handleCheckedChange(name,checked, formik, validationMessage, setValidationMessage)} label="Recebido Tribunal Contas" />
                <Button primary onClick={ formik.handleSubmit }>Salvar</Button>
                <Button secondary onClick={ onCancel }>Cancelar</Button>
            </Form>
        </Segment>
    );

}

export default FormCartaSolicitacao;