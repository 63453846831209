import React from 'react';
import { Menu } from 'semantic-ui-react';
import history from '../../history';


const MenuLateral = () => {

    return (
        <Menu vertical fluid>
            <Menu.Item
                name="Informativos"
                onClick={() => {}}
            />
            <Menu.Item
                name="Contratos"
                onClick={() => {history.push('/contratos')}}
            />
            <Menu.Item
                name="Medições"
                onClick={() => {history.push('/medicoes')}}
            />
            <Menu.Item
                name="Relatórios"
                onClick={() => {history.push('/relatorios')}}
            />
        </Menu>
    );
}

export default MenuLateral;