import React, { useState, useEffect } from 'react';
import { Form, Button, Header, Divider, FormField } from 'semantic-ui-react';
import { useFormik } from 'formik';

import CustomDropdown from '../UI/CustomDropdown';

import { 
    DATAS_FECHAMENTOS_15,
    DATAS_FECHAMENTOS_30
} from '../../helpers/dropdownNames';

import useOptions from '../../hooks/useOptions';

const ContratosFilterForm = ({ listar, setPopupState, setLoading }) => {
    
    const options = useOptions([
        DATAS_FECHAMENTOS_15,
        DATAS_FECHAMENTOS_30
    ]);
    
    const [ DateList, setDateList ] = useState({});

    const now = new Date();
    
    const formik = useFormik({
        initialValues : {
            'dia' : '15',
            'TipoRecurso' : '',
            'Fechamento' : `${now.getFullYear()}-${String(now.getMonth() + 1).length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-15`
            
        },
        onSubmit : (values, { resetForm }) => {
            const submit = async () => {
                setLoading(true);
                setPopupState(false);
                values.dia = ''; // Limpa o valor de dia para que não seja usado pela API
                await listar(values);
                setLoading(false);
            }
            submit();
        }
    });

    useEffect( () => {
        
        if(formik.values.dia === '15')
            setDateList(options[DATAS_FECHAMENTOS_15]);
        else
            setDateList(options[DATAS_FECHAMENTOS_30]);
        
        const now = new Date();
        
        formik.setFieldValue('Fechamento', `${now.getFullYear()}-${String(now.getMonth() + 1).length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${formik.values.dia}`);
        
    },[formik.values.dia, options]);

    const limparFiltro = () => {
        const limpar = async () => {
            setPopupState(false);
            setLoading(true);
            await listar();
            setLoading(false)
        }
        limpar();
    }

    const cancelar = () => {
        setPopupState(false);
    }

    const RecursosOptions = {
        Financiado : 'Financiado',
        RPI : 'RPI'
    }

    const handleChange = (e, {name, value}) => {
        formik.setFieldValue(name, value);
    }

    
    return (
            <Form widths='equal'>
                <Header>
                    <Header.Subheader>Preencha apenas os campos que deseja filtrar e clique em "Filtrar"</Header.Subheader>
                </Header>
                <Divider />
                <Form.Group>
                    <CustomDropdown
                        initialOptions={{15:15, 30:30}}
                        name="dia"
                        label="Dia do Fechamento"
                        onChange={handleChange}
                        value={formik.values.dia}
                    />
                    <CustomDropdown
                        initialOptions={RecursosOptions}
                        name="TipoRecurso"
                        label="Recursos"
                        onChange={handleChange}
                        value={formik.values.TipoRecurso}
                    />
                    <CustomDropdown
                        initialOptions={DateList}
                        name="Fechamento"
                        label="Fechamento"
                        onChange={handleChange}
                        value={formik.values.Fechamento}
                    />
                </Form.Group>
                
                <Button onClick={formik.handleSubmit} primary>Filtrar</Button>
                <Button onClick={limparFiltro} negative>Limpar Filtro</Button>
                <Button onClick={cancelar} cancelar>Cancelar</Button>
            </Form>
        
    );
}

export default ContratosFilterForm;