import React, { useState, useContext, useEffect } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import history from '../../history';

import validationSchema from '../../validationSchema';

import { handleChange } from '../../helpers/handleForm';
import { calculaPesoFrente } from '../../helpers/calculatedFields';

import { Context as unidadeOrigemContext } from '../../context/unidadeOrigemContext';
import { Context as contratosContext } from '../../context/contratosContext';

const FormCarta = ({ onSave, onCancel, E_PPI }) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.frente);
    const [ contrato, setContrato ] = useState({});

    const contratos = useContext(contratosContext);
    const unidadeOrigem = useContext(unidadeOrigemContext);

    useEffect( () => {
        const buscarContrato = async () => {
            await contratos.buscar(E_PPI);
            setContrato(contratos.state.find( (item) => item.E_PPI === E_PPI));
        }
        buscarContrato();
    }, [E_PPI]);
            
    useEffect( () => {
            setContrato(contratos.state.find( (item) => item.E_PPI === E_PPI));        
    }, [E_PPI, contratos]);

    const formik = useFormik({
        initialValues : {
            'NúmeroFrente' : '',
            'Frente' : '',
            'UnidadeMedida' : '',
            'Quantidade' : '',
            'Valor' : '',
            'Peso' : '',
            'PesoVerifica' : ''
        },

        onSubmit : (values, { resetForm }) => {
            values.UnidadeOrigemExportar = unidadeOrigem.state.UnidadeOrigemExportar;
            values.E_PPI = E_PPI;
            onSave(values, setValidationMessage, () => {
                onCancel();
                history.push(`/frente/${encodeURIComponent(values.E_PPI)}/${encodeURIComponent(values['NúmeroFrente'])}`);
            });
        }

    });

    const calculaPeso = (e, {name,value}) => {
        formik.setFieldValue('Peso', String(calculaPesoFrente(value, contrato.E_OB_VALOR).toFixed(2)).replace(',','.'));
        formik.setFieldValue('PesoVerifica', String(calculaPesoFrente(value, contrato.E_OB_VALOR).toFixed(5)).replace(',','.'));
        handleChange(name,value,formik,validationMessage, setValidationMessage);
    }
    
    return(
        <Segment basic>
            <Form widths='equal'>
                <Form.TextArea name="Frente" value={formik.values['Frente']} label="Frente"  onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} />                          
                <Form.Group>
                    <Form.Input name="NúmeroFrente" value={formik.values['NúmeroFrente']} label="Número" onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} error={validationMessage['NúmeroFrente']} />
                    <Form.Input name="UnidadeMedida" value={formik.values['UnidadeMedida']} label="Unidade de Medida" onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} error={validationMessage['UnidadeMedida']} />
                    <Form.Input name="Quantidade" value={formik.values['Quantidade']} label="Quantidade" onChange={(e, {name,value}) => handleChange(name,value,formik,validationMessage, setValidationMessage)} error={validationMessage['Quantidade']} />
                </Form.Group>    
                <Form.Group>
                    <Form.Input name="Valor" value={formik.values['Valor']} label="Valor" onChange={calculaPeso} error={validationMessage['Valor']} />
                    <Form.Input name="Peso" value={formik.values['Peso']} label="Peso" readOnly />
                    <Form.Input name="PesoVerifica" value={formik.values['PesoVerifica']} label="Peso s/ arred." readOnly />
                </Form.Group>
                <Button primary onClick={ formik.handleSubmit }>Salvar</Button>
                <Button secondary onClick={ onCancel }>Cancelar</Button>
            </Form>
        </Segment>
    )
}

export default FormCarta;