export default (state, record, idField = 'E_PPI') => {

    let newState = [...state];
    let index = -1;

    
    if(Array.isArray(idField)){
        // PARA BUSCAR REGISTROS QUE USAM MAIS DE UMA CHAVE PRIMARIA
        index = state.findIndex( item => {        
            let check = true;
            idField.forEach( idField => {
                if(item[idField] !== record[idField])
                    check = false;
            });
            return check;
        });

    } else {
        // PARA BUSCAR REGISTROS COM 1 CHAVE PRIMARIA
        index = state.findIndex( item => item[idField] === record[idField]);

    }

    if(index >= 0){        
        newState.splice(index, 1); // Remove o elemento do state
    }

    return newState;

}