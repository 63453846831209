import React, { useContext, useEffect, useState } from 'react';
import history from '../history';
import { Icon, Button, Segment } from 'semantic-ui-react';
import SemanticGrid from './UI/SemanticGrid';

import '../styles.css';

import { Context } from '../context/medicoesContext';

import { utcToDate, convertToCurrency }  from '../helpers/stringConvert';

import Loading from './UI/Loading';

const columns = [
    {
        Header: 'Fechamento',
        accessor: 'Fechamento',
        width: '2',
        Cell : ({ row }) => <span>{utcToDate(row.values.Fechamento, 'br')}</span>
    },
    {
        Header: 'Entrega',
        accessor: 'DataEntrega',
        width: '2',
        Cell : ({ row }) => <span>{utcToDate(row.values.DataEntrega, 'br')}</span>
    },
    {
        Header: 'Número',
        accessor: 'NumeroMedição',
        width: '2'
    },
    {
        Header: 'Inicio',
        accessor: 'PeríodoInicial',
        width: '3',
        Cell : ({ row }) => <span>{utcToDate(row.values['PeríodoInicial'], 'br')}</span>
    },
    {
        Header: 'Final',
        accessor: 'PeríodoFinal',
        width: '3',
        Cell : ({ row }) => <span>{utcToDate(row.values['PeríodoFinal'], 'br')}</span>
    },
    {
        Header: 'ValorP0',
        accessor: 'ValorP0',
        width: '3',
        Cell : ({ row }) => convertToCurrency(row.values['ValorP0'],'.')
    },
    {
        Header: '',
        accessor: 'remove',
        Cell : ({row}) => <Button basic icon onClick={() => console.log(row)}><Icon name="trash" /></Button>,
        width: '1'
    }
];
 
const ContratoMedicoes = ({ E_PPI }) => {

    const { state, listarPorContrato, reset } = useContext(Context);
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        reset();
    }, []);

    useEffect( () => {
        listarPorContrato(E_PPI, setLoading);
    },[E_PPI]);
    
    const rowClick = cellInfo => history.push(`/medicoes/${encodeURIComponent(E_PPI)}/${cellInfo.row.values['NumeroMedição']}`);
 
    /*const filter = {
        name: 'users_filter',
        items: [
            {
                name: 'first_name',
                type: 'text',
                label: 'First Name'
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Last Name'
            },
            {
                name: 'email',
                type: 'text',
                label: 'Email'
            },
            {
                name: 'country',
                type: 'text',
                label: 'Country'
            }
        ]
    };*/
    
    let lista = null;

    if(state.length > 0){

        lista = <SemanticGrid
                    data={state}
                    columns={columns}
                    getCellProps={cellInfo => ({
                        onClick : () => cellInfo.column.Header !== '' ? rowClick(cellInfo) : () => {}
                    })}
                />

    }else if(loading === false){
        lista = <Segment as="h2" basic textAlign="center" content="Nenhuma medição encontrada." />;
    }

    return(
        <div className="table-area">
            <Loading active={loading} />
            {/*<div className="table-options-area">
                <Button onClick={null} icon><Icon name="plus" /> Adicionar Medição</Button>
            </div>*/}

            {lista}

        </div>

    );

}

export default ContratoMedicoes;