import React, { useState, useContext } from 'react';
import { Header, Button, Icon, Popup } from 'semantic-ui-react';

import ContratosLista from '../componentes/ContratosLista';
import ContratosFilterForm from '../componentes/FilterForms/ContratosFilterForm';

import FormContrato from '../componentes/ModalForms/FormContrato';

import useObras from '../hooks/useObras';

import { Context } from '../context/contratosContext';
import { Context as modalFormContext } from '../context/modalFormContext';

//import OrderSelector from '../componentes/UI/OrderSelector';

//import clearFieldName from '../helpers/clearFieldName';

const Contratos = () => {

    const [ loading, setLoading ] = useState(true);
    const obras = useObras(setLoading);

    const { novo } = useContext(Context);

    const modalForm = useContext(modalFormContext);

    const [ quantidade, setQuantidade ] = useState(50);
    //const [ ordem, setOrdem ] = useState();
    const [ filterPopupState, setFilterPopupState ] = useState(false);
    //const [ orderPopupState, setOrderPopupState ] = useState(false);
    /*const [ orderOptions, setOrderOptions ] = useState([
        {
            name : 'Data Prevista',
            id : 'data_prevista',
            action : () => {}
        },
        {
            name : 'Código',
            id : 'codigo',
            action : () => {}
        },
        {
            name : 'Empreiteira',
            id : 'empreiteira',
            action : () => {}
        },
        {
            name : 'Situação',
            id : 'situacao',
            action : () => {}
        },
    ]);    
    const [ queryState, setQueryState ] = ({        
            'E_PPI|like' : '',
            'E_OB_EMPR|like' : '',
            E_OB_SIT : '',
            'OBJETO_OF|like': '',
            'E_OB_NUMAS|like': '',
            'E_OB_AS_inicial|maiorigual': '',
            'E_OB_AS_final|menorigual': '',
            'E_OB_TERM_inicial|maiorigual': '',
            'E_OB_TERM_final|menorigual': '',
            'TERMINO_AT_inicial|maiorigual': '',
            'TERMINO_AT_final|menorigual': '',
            ordempor : 'E_OB_SIT',
            ordemdirecao : 'asc'
    });*/

    const onScroll = (event) => {
        const add = 50;
        const loadPosition = ((event.target.scrollHeight - event.target.offsetHeight) / 6 ) * 5;
        const scrollPosition = event.target.scrollTop;
        if(scrollPosition >= loadPosition && quantidade < obras.state.length){
            setQuantidade(quantidade + add);
        }
        
    }

    // MODAL COM FORMUÁRIO DE NOVO REGISTRO
    const modalAdicionar = () => {

        modalForm.open();
        modalForm.setHeader('Novo Contrato');
        modalForm.setForm(<FormContrato onCancel={modalForm.close} onSave={novo} />);

    }
 
    return (
        <div className='contratos'>
            <div style={{width:'100%'}}>
                <Header size='large' color='teal' floated='left'> Contratos </Header>
                <Button onClick={modalAdicionar} icon><Icon name="plus" /> Novo Contrato</Button>
                <Popup
                    trigger={ <Button floated="right" content='Filtros' icon='filter' size='small' onClick={() => setFilterPopupState(!filterPopupState)} /> }
                    on="click"
                    position="bottom right"
                    content={<ContratosFilterForm setPopupState={setFilterPopupState} listar={obras.listar} />}
                    open={filterPopupState}
                />

                {/*<Popup
                    trigger={ <Button floated="right" content='Ordem' icon='sort amount down' size='small' onClick={() => setOrderPopupState(!orderPopupState)} /> }
                    on="click"
                    position="bottom right"
                    content={<OrderSelector options={orderOptions} selected={ordem} setOrder={setOrdem} setPopupState={setOrderPopupState} />}
                    open={orderPopupState}
                />*/}
                
               
                
            </div>
            <div className='scroll-list' onScroll={onScroll} style={{top : 50}}>
                <ContratosLista 
                    quantidade={quantidade}
                    loading={loading}
                    obras={obras.state}
                />
            </div>
        </div>
    );
}

export default Contratos;