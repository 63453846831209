import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';
import errorHandler from './errorHandler';
import updateState from '../helpers/updateState';
import deleteState from '../helpers/deleteState';
import validate from '../helpers/validate';

const defaultState = [];

const reducer = (state, action) => {

    switch(action.type){
        
        case 'listarPorCarta':
            return action.payload;

        case 'buscar':
            return updateState(state, action.payload, ['E_PPI', 'NúmeroCartaContratada', 'NúmeroCI']);

        case 'atualizar':
            return updateState(state, action.payload, ['E_PPI', 'NúmeroCartaContratada', 'NúmeroCI']);
                    
        case 'novo':
            return [action.payload, ...state];

        case 'remover':            
            return deleteState(state, action.payload, ['E_PPI', 'NúmeroCartaContratada', 'NúmeroCI']);

        case 'reset':
            return defaultState;

        default:
            return state;

    }
}

const actions = {
    
    listarPorCarta : dispatch => async (E_PPI, NumeroCartaContratada, query = null) => {

        const resposta = await sisobrasServer.get('/cartas/ci/' + encodeURIComponent(E_PPI) + '/' + encodeURIComponent(NumeroCartaContratada), {params : query});
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'listarPorCarta',
            payload : resposta.data.dados
        });
    },

    buscar : dispatch => async (E_PPI, NumeroCartaContratada, NumeroCI) => {

        if(!E_PPI){
            console.error("Buscar CI - Código do contrato não informado");
            return false;
        } 
        if(!NumeroCartaContratada){
            console.error("Buscar CI - Número da carta não informado");
            return false;
        }
        if(!NumeroCI){
            console.error("Buscar CI - Número da CI não informado");
            return false;
        }

        const resposta = await sisobrasServer.get('/cartas/ci/' + encodeURIComponent(E_PPI) + '/' + encodeURIComponent(NumeroCartaContratada) + '/' + encodeURIComponent(NumeroCI));
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'buscar',
            payload : resposta.data.dados[0]
        });
    },


    atualizar : dispatch => async (dados = null, setValidationMessage) => {
        
        if(!dados.E_PPI){
            console.error("Atualizar Carta - Contrato da carta não informado");
            return false;
        } 
        if(!dados['NúmeroCartaContratada']){
            console.error("Atualizar Carta - Código da carta não informado");
            return false;
        } 
        if(!dados['NúmeroCI']){
            console.error("Atualizar CI - Tipo de CI não informado");
            return false;
        }

        const resposta = await sisobrasServer.put(`/cartas/ci/${encodeURIComponent(dados.E_PPI)}/${encodeURIComponent(dados['NúmeroCartaContratada'])}/${encodeURIComponent(dados['NúmeroCI'])}`, dados);

        validate('carta_ci',resposta.data.errosValidacao,setValidationMessage);
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'atualizar',
            payload : resposta.data.dados[0]
        });
    },


    novo : dispatch => async ( dados = null, setValidationMessage = null, afterUpdate = null) => {

        if(!dados){
            console.error("Erro ao cadastrar CI -> Dados incompletos");
            return false;
        } 

        const resposta = await sisobrasServer.post('/cartas/ci/', dados);
        
        validate('carta_ci',resposta.data.errosValidacao,setValidationMessage);

        if(!errorHandler(resposta.data)) return false;

        // FECHA A MODAL DO FORMULÁRIO CASO EXISTA E NÃO TENHA OCORRIDO ERROS DE VALIDAÇÃO
        if(afterUpdate && resposta.data.errosValidacao === undefined)
            afterUpdate();
        
        dispatch({
            type : 'novo',
            payload : resposta.data.dados[0]
        });

    },


    remover : dispatch => async (E_PPI, NumeroCartaContratada, NumeroCI) => {
         
        if(!E_PPI){
            console.error("Erro ao remover CI - Contrato da carta não informado");
            return false;
        } 

        if(!NumeroCartaContratada){
            console.error("Erro ao remover CI - Número da carta não informado");
            return false;
        } 

        if(!NumeroCI){
            console.error("Erro ao remover CI - Tipo de CI não informado");
            return false;
        } 

        const data = {
            E_PPI,
            NumeroCartaContratada,
            NumeroCI
        };

        const resposta = await sisobrasServer.delete('/cartas/ci', { data });
        
        if(!errorHandler(resposta.data)) return false;
        
        dispatch({
            type : 'remover',
            payload : { E_PPI, 'NúmeroCartaContratada' : NumeroCartaContratada, 'NúmeroCI' : NumeroCI}
        });
    },

    reset : dispatch => async() => {

        dispatch({
            type : 'reset'
        })

    }

}

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)