export const SITUACAO_CONTRATO = 'situacao_contrato';
export const AGUA_ESGOTO = 'agua_esgoto';
export const MUNICIPIOS = 'municipios';
export const TIPO_CONTRATO = 'tipo_contrato';
export const MAT_OBRAS_SERVICO = 'mat_obras_servico';
export const FUNC = 'func'; // Funcionarios
export const FISCAL_ADM = 'fiscal_adm'; // Funcionarios
export const RAZ = 'raz'; // Empreiteira
export const DT_MEDICAO = 'dt_medicao';
export const TIPO_FAC = 'tipo_fac';
export const RESPONSAVEL_TITULO = 'responsavel_titulo';
export const RECURSO = 'recurso';
export const TIPO_RESCISAO = 'tipo_rescisao';
export const TIPO_MEDICAO = 'tipo_medicao';
export const DESTINO_CARTA = 'destino_carta';
export const TIPO_SOLICITACAO = 'tipo_solicitacao';
export const SOLICITACAO_DEFINICAO = 'solicitacao_definicao';
export const NUMERO_CI = 'numero_ci';
export const UNIDADE = 'unidade';
export const DATAS_FECHAMENTOS = 'datas_fechamentos';
export const DATAS_FECHAMENTOS_15 = 'datas_fechamentos_15';
export const DATAS_FECHAMENTOS_30 = 'datas_fechamentos_30';
export const TIPO_AVALIACAO = 'tipo_avaliacao';
export const TIPO_CLASSIFICACAO = 'tipo_classificacao';