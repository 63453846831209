import React, { useState } from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import { useFormik } from 'formik';

import {handleChange, handleBlur, handleSelectChange, handleCheckedChange} from '../helpers/handleForm';
import { utcToDate } from '../helpers/stringConvert';

import validationSchema from '../validationSchema';

import IconButton from './UI/IconButton';
import CustomDropdown from './UI/CustomDropdown';

import {
    UNIDADE
} from '../helpers/dropdownNames';

import useOptions from '../hooks/useOptions';


const CartaCIItem = ({ item, remover, atualizar }) => {

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.carta_ci);

    const options = useOptions([
        UNIDADE,      
    ]);
    
    const formik = useFormik({
        initialValues : item,
    
        onSubmit : (values, { resetForm }) => {
            atualizar(values, setValidationMessage);
        }
    
    });
    
    return(
        <div key={item['NúmeroCartaContratada'] + item['NúmeroCI']}>
        <Segment color="green">
            <Header size="medium">
                <IconButton size="tiny" name="trash" onClick={() => remover(item['E_PPI'], item['NúmeroCartaContratada'], item['NúmeroCI'])} />
                {item['NúmeroCI']}
            </Header>
            <Form widths="equal">
                <Form.Group>
                    <Form.Input name="DataEncaminhamentoCI" value={utcToDate(formik.values.DataEncaminhamentoCI)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Data Encaminhamento" type="date" />
                    <CustomDropdown
                            initialOptions={options[UNIDADE]}
                            name="DestinoCI"
                            label="Destino"
                            value={formik.values['DestinoCI']}
                            onChange={(e, {name,value}) => handleSelectChange(name,value,formik)}
                            search={true}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input name="Termo" value={formik.values['Termo']} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Termo" />
                    <Form.Input name="DataAssinaturaTermo" value={utcToDate(formik.values.DataAssinaturaTermo)} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Data Assinatura" type="date"/>
                    <Form.Input name="RetornoDossiê" value={utcToDate(formik.values['RetornoDossiê'])} onChange={(e, {name,value}) => handleChange(name,value,formik)} onBlur={(e) => handleBlur(formik)} label="Retorno Dossiê" type="date" />
                </Form.Group>
                <Form.Checkbox name="RecebidoTribunalContas" checked={formik.values.RecebidoTribunalContas} onChange={(e, {name,checked}) => handleCheckedChange(name,checked,formik)} label="Recebido Tribunal Contas" />
            </Form>
        </Segment>
        </div>
    )
}

export default CartaCIItem