import createDataContext from './createDataContext';
import sisobrasServer from '../API/sisobrasServer';

const reducer = (state, action) => {

    switch(action.type){
        
        case 'setOptions':
            
            if(!action.payload.id)
                return state;
            else
                return ({ 
                    ...state,
                    [ action.payload.id ] : action.payload.options
                });

        default:
            return state;

    }
}

const actions = {

    setOptions : dispatch => async (list, conditionValue = null) => {
        
        const resposta = await sisobrasServer.get(`/options/${list}`, { params : conditionValue });
        
        if(resposta.data.erro){
            return false;
        }else{
            dispatch({
                type : 'setOptions',
                payload : resposta.data
            });
        }
    }

}

const defaultState = {};

export const { Provider, Context } = createDataContext(
    reducer,
    actions,
    defaultState
)