import React, { useContext, useEffect, useState } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Context } from '../context/cartasContext';

import CartaDados from '../componentes/CartaDados';
import CartaSolicitacoes from '../componentes/CartaSolicitacoes';
import CartaCI from '../componentes/CartaCI';

const Contratos = () => {

    let { E_PPI, NumeroCartaContratada } = useParams();
    E_PPI = decodeURIComponent(E_PPI);
    NumeroCartaContratada = decodeURIComponent(NumeroCartaContratada);
    
    const { state, buscar, atualizar } = useContext(Context);

    const [ carta, setCarta ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {
        const update = async () => {
            await buscar(E_PPI, NumeroCartaContratada);
            setLoading(false);
        }
        update();
    },[]);

    useEffect( () => {        
        setCarta( state.find((item) => item.E_PPI === E_PPI && item['NúmeroCartaContratada'] === NumeroCartaContratada) );

    },[state, E_PPI, NumeroCartaContratada]);

    
    if(loading){
        return (
            <div className='route-content'>
                <Loader active={true} content="Aguarde..." />
            </div>
        );

    }else if(carta === undefined){
        return(
            <div className='route-content'>
                <Header textAlign="center" content="Carta não encontrada" />
            </div>
        );

    }else{
        
        return(
            <div className='route-content'>
                <Header size='large' color='green'> 
                    Carta {NumeroCartaContratada}
                    <Header.Subheader><Link to={`/contrato/${encodeURIComponent(E_PPI)}`}>Contrato {E_PPI}</Link></Header.Subheader>
                </Header>
                <div className='scroll-list' style={{top : 60}}>
                    <CartaDados dados={carta} atualizar={(values, setValidationMessage) => atualizar(encodeURIComponent(E_PPI), encodeURIComponent(NumeroCartaContratada), values, setValidationMessage)} />
                    <CartaCI E_PPI={E_PPI} NumeroCartaContratada={NumeroCartaContratada} />
                    <CartaSolicitacoes E_PPI={E_PPI} NumeroCartaContratada={NumeroCartaContratada} />
                </div>
            </div>
        )
    }
}

export default Contratos;