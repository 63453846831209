import React, { useState, useContext } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import history from '../../history';

import validationSchema from '../../validationSchema';

import { utcToDate } from '../../helpers/stringConvert';

import CustomDropdown from '../UI/CustomDropdown';

import { Context as unidadeOrigemContext } from '../../context/unidadeOrigemContext';

import{
    DESTINO_CARTA
} from '../../helpers/dropdownNames';
import useOptions from '../../hooks/useOptions';

const FormCarta = ({ onSave, onCancel, E_PPI }) => {

    const options = useOptions([
        DESTINO_CARTA
    ]);

    const [ validationMessage, setValidationMessage ] = useState(validationSchema.carta_novo);

    const unidadeOrigem = useContext(unidadeOrigemContext);

    const formik = useFormik({
        initialValues : {
            'NúmeroCartaContratada' : '',
            'DataCarta' : null,
            'Informações' : '',
            'DataEncaminhamentoCarta' : null,
            'DestinoCarta' : '',
            
        },

        onSubmit : (values, { resetForm }) => {
            values.UnidadeOrigemExportar = unidadeOrigem.state.UnidadeOrigemExportar;
            values.E_PPI = E_PPI;
            onSave(values, setValidationMessage, () => {
                onCancel();
                history.push(`/carta/${encodeURIComponent(values.E_PPI)}/${encodeURIComponent(values['NúmeroCartaContratada'])}`);
            });
        }

    });

    const handleBlur = (e) => {
        
    }

    const handleChange = (e, { name, value }) => {
        setValidationMessage({ ...validationMessage, [name] : null});
        formik.setFieldValue(name, value);
    }
    
    return(
        <Segment basic>
            <Form widths='equal'>
                <Form.Group>
                    <Form.Input name="NúmeroCartaContratada" value={formik.values['NúmeroCartaContratada']} onChange={handleChange} label="Número da Carta" error={validationMessage['NúmeroCartaContratada']} />
                    <Form.Input name="DataCarta" value={utcToDate(formik.values.DataCarta)} onChange={handleChange} onBlur={handleBlur} label="Data" type="date" error={validationMessage['DataCarta']} />
                </Form.Group>
                <Form.Input name="Informações" value={formik.values['Informações']} onChange={handleChange} label="Informações" />
                <Form.Group>
                    <Form.Input name="DataEncaminhamentoCarta" value={utcToDate(formik.values.DataEncaminhamentoCarta)} onChange={handleChange} onBlur={handleBlur} label="Data Encaminhamento" type="date" />
                    <CustomDropdown
                            initialOptions={options[DESTINO_CARTA]}
                            name="DestinoCarta"
                            label="Destino"
                            value={formik.values['DestinoCarta']}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            search={true}
                    />
                </Form.Group>
                <Button primary onClick={ formik.handleSubmit }>Salvar</Button>
                <Button secondary onClick={ onCancel }>Cancelar</Button>
            </Form>
        </Segment>
    )
}

export default FormCarta;