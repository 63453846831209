import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading = ( { active }) => {

    return(
        <Dimmer active={active} inverted>
            <Loader active={active} />
        </Dimmer>
    );
}

export default Loading;